import React, {useEffect, useState} from 'react';
import additional_agreements_sever_requests from "../api/additional_agreements_sever_requests";
import useStore from "../Store";
import cl from "./additional_agreements.module.css"
import Loading from "../UI/Loading/loading";
import global_functions from "../global_functions";

const AdditionalAgreements = () => {

    const [additional_agreements, setAdditional_agreements]=useState([]);
    const [show_aa, setShow_aa]=useState([]);
    const [loading, setLoading]=useState(false);
    const [count, setCount]=useState(0);
    const [Count2Wave, setCount2Wave]=useState(0);
    const [count_unknown, setCount_unknown]=useState(0);
    const [count_refused, setCount_refused]=useState(0);
    const [CountRefused2Wave, setCountRefused2Wave]=useState(0);
    const [fio_filter, setFio_filter]=useState("");
    const [tel_filter, setTel_filter]=useState("");
    const [house_filter, setHouse_filter]=useState("");
    const [flat_filter, setFlat_filter]=useState("");
    const [square_filter, setSquare_filter]=useState("");
    const UserId=useStore((state)=>state.User).Id;
    const [basalaev_control_count, setBasalaev_control_count]=useState(0);

    async function get_additional_agreements(){
        let a=0;
        let b=0;
        let c=0;
        let Signet2Wave=0;
        let refused=0;
        let Refused2Wave=0;
        const res=await additional_agreements_sever_requests.get_additional_agreements(UserId);
        setAdditional_agreements(res.data);
        res.data.map(((el, index)=>{
            if(el.status==1) a+=1;
            if(el.status==3) refused+=1;
            if(el.abc==null) b+=1;
            if(el.basalaev_control==1) c+=1;
            if(el.status2==1) Signet2Wave+=1;
            if(el.status2==3) Refused2Wave+=1;
        }));
        setCount(a);
        setCount2Wave(Signet2Wave);
        setCount_unknown(b)
        setCount_refused(refused);
        setCountRefused2Wave(Refused2Wave);
        setBasalaev_control_count(c);
        setShow_aa(res.data);
        setLoading(true);
    }
    function filter(fio, tel, house, flat, square){
        let temp=additional_agreements;

        if(fio) temp=temp.filter(el=> el.client_name.toLowerCase().includes(fio.toLowerCase()));
        if(tel) temp=temp.filter(el=> el.client_tel_number.toLowerCase().includes(tel.toLowerCase()));
        if(house) temp=temp.filter(el=> el.house_number.toLowerCase().includes(house.toLowerCase()));
        if(flat) temp=temp.filter(el=> el.flat_number.toLowerCase().includes(flat.toLowerCase()));
        if(square) temp=temp.filter(el=> el.square.toLowerCase().includes(square.toLowerCase()));

      return temp;
    }
    function fio_change(e){
 //       setShow_aa(AdditionalAgreements.filter(el=> el.client_name.toLowerCase().includes(e.target.value.toLowerCase())));
        setShow_aa(filter(e.target.value,tel_filter, house_filter, flat_filter, square_filter));
        setFio_filter(e.target.value);
    }
    function tel_change(e){
       // setShow_aa(AdditionalAgreements.filter(el=> el.client_tel_number.toLowerCase().includes(e.target.value.toLowerCase())));
        setShow_aa(filter(fio_filter,e.target.value, house_filter, flat_filter, square_filter));
        setTel_filter(e.target.value);
    }
    function house_change(e){
      //  setShow_aa(AdditionalAgreements.filter(el=> el.house_number.toLowerCase().includes(e.target.value.toLowerCase())));
        setShow_aa(filter(fio_filter,tel_filter, e.target.value, flat_filter, square_filter));
        setHouse_filter(e.target.value);
    }
    function flat_change(e){
        //setShow_aa(AdditionalAgreements.filter(el=> el.flat_number.toLowerCase().includes(e.target.value.toLowerCase())));
        setShow_aa(filter(fio_filter,tel_filter, house_filter, e.target.value, square_filter));
        setFlat_filter(e.target.value);
    }
    function square_change(e){
        //setShow_aa(AdditionalAgreements.filter(el=> el.square.toLowerCase().includes(e.target.value.toLowerCase())));
        setShow_aa(filter(fio_filter,tel_filter, house_filter, flat_filter, e.target.value));
        setSquare_filter(e.target.value);
    }
    function history_change(e){
        if(e.target.value=="")
            setShow_aa(additional_agreements)
        else
            setShow_aa(additional_agreements.filter(el=> el.abc&&el.abc.toLowerCase().includes(e.target.value.toLowerCase())));
    }
    function basalaev_control_change(e){
        if(e.target.checked)
            setShow_aa(additional_agreements.filter(el=> el.basalaev_control==1));
        else
            setShow_aa(additional_agreements);
    }
    async function add_additional_agreement_notes(e){
        if(e.target.value!="") {
            const res = await additional_agreements_sever_requests.add_additional_agreements_notes(UserId, e.target.id, e.target.value);
            setLoading(false);
            get_additional_agreements();
        }
    }
    async function change_status1(e){
        const res = await additional_agreements_sever_requests.additional_agreements_change_status1(UserId, e.target.id, e.target.checked);
        setLoading(false);
        get_additional_agreements();
    }
    async function change_status_refuse1(e){
        const res = await additional_agreements_sever_requests.additional_agreements_change_status_refuse1(UserId, e.target.id, e.target.checked);
        setLoading(false);
        get_additional_agreements();
    }
    async function change_status2(e){
        const res = await additional_agreements_sever_requests.additional_agreements_change_status2(UserId, e.target.id, e.target.checked);
        setLoading(false);
        get_additional_agreements();
    }
    async function change_status_refuse2(e){
        const res = await additional_agreements_sever_requests.additional_agreements_change_status_refuse2(UserId, e.target.id, e.target.checked);
        setLoading(false);
        get_additional_agreements();
    }
    async function change_basalaev_status(e){
        const res = await additional_agreements_sever_requests.additional_agreements_additional_agreements_basalaev_control(UserId, e.target.id, e.target.checked);
        setLoading(false);
        get_additional_agreements();
    }
    useEffect(()=>{
        get_additional_agreements();
    },[])
    useEffect(()=>{
        setShow_aa(additional_agreements.filter(el=> el.client_name.toLowerCase().includes(fio_filter.toLowerCase())));
    },[additional_agreements])

    console.log(show_aa)

    if(loading)
    return (
        <div className={cl.container}> <br/>
            <h3>Всего: {additional_agreements.length} подписано: {count} отказ:{count_refused} неизвестно:{count_unknown}</h3>
            <br/>
            <h3>Второй перенос. подписано: {Count2Wave} отказ:{CountRefused2Wave} на
                контроле у Басалаева: {basalaev_control_count}</h3>
            <table className={cl.table}>
                <thead>
                <tr>
                    <th rowSpan={2}>№</th>
                    <th rowSpan={2}>ФИО клиента <br/><input onChange={fio_change} value={fio_filter}/></th>
                    <th colSpan={2}>Первый перенос</th>
                    <th colSpan={2}>Второй перенос</th>
                    <th rowSpan={2}>Телефон <br/><input onChange={tel_change} value={tel_filter}/></th>
                    <th rowSpan={2}>Дом <br/><input className={cl.width} onChange={house_change} value={house_filter}/>
                    </th>
                    <th rowSpan={2}>Квартира <br/><input className={cl.width} onChange={flat_change}
                                                         value={flat_filter}/></th>
                    <th rowSpan={2}>Площадь <br/><input className={cl.width} onChange={square_change}
                                                        value={square_filter}/></th>
                    <th rowSpan={2}>Кол-во комнат</th>
                    <th rowSpan={2}>Этаж</th>
                    <th rowSpan={2}>Подъезд</th>
                    <th rowSpan={2}>Сумма компенсации</th>
                    <th rowSpan={2} className={cl.history}>История Выполнения<br/><input onChange={history_change}/>
                    </th>
                    <th rowSpan={2}>Добавить комментарий</th>
                    <th rowSpan={2}>Контроль Басалаева <br/><input type="checkbox" onChange={basalaev_control_change}/>
                    </th>
                    <th rowSpan={2}>Оплата</th>
                </tr>
                <tr>
                    <th>ДС подписано</th>
                    <th>Отказ от ДС</th>
                    <th>ДС подписано</th>
                    <th>Отказ от ДС</th>
                </tr>
                </thead>
                <tbody>
                {
                    show_aa.map((el, index) => {
                        let temp;
                        if (el.abc) temp = el.abc && el.abc.split(";"); else temp = [];
                        return (
                            <tr className={cl.tr}>
                                <td>{index + 1}</td>
                                <td>{el.client_name}</td>
                                <td className={cl.td}> {
                                    el.status == 1
                                        ?
                                        <input id={el.additional_agreement_id} onChange={change_status1} type="checkbox"
                                               checked/>
                                        :
                                        <input id={el.additional_agreement_id} onChange={change_status1} type="checkbox"
                                               checked=""/>
                                }
                                </td>
                                <td className={cl.td}> {
                                    el.status == 3
                                        ?
                                        <input id={el.additional_agreement_id} onChange={change_status_refuse1}
                                               type="checkbox"
                                               checked/>
                                        :
                                        <input id={el.additional_agreement_id} onChange={change_status_refuse1}
                                               type="checkbox"
                                               checked=""/>
                                }
                                </td>
                                <td className={cl.td}> {
                                    el.status2 == 1
                                        ?
                                        <input id={el.additional_agreement_id} onChange={change_status2} type="checkbox"
                                               checked/>
                                        :
                                        <input id={el.additional_agreement_id} onChange={change_status2} type="checkbox"
                                               checked=""/>
                                }
                                </td>
                                <td className={cl.td}> {
                                    el.status2 == 3
                                        ?
                                        <input id={el.additional_agreement_id} onChange={change_status_refuse2}
                                               type="checkbox"
                                               checked/>
                                        :
                                        <input id={el.additional_agreement_id} onChange={change_status_refuse2}
                                               type="checkbox"
                                               checked=""/>
                                }
                                </td>
                                <td>{el.client_tel_number}</td>
                                <td className={cl.house}>{el.house_number}</td>
                                <td>{el.flat_number}</td>
                                <td>{el.square}</td>
                                <td>{el.room_count}</td>
                                <td>{el.floor}</td>
                                <td>{el.entrance}</td>
                                <td>{el.compensation_amount}</td>
                                <td>
                                    {
                                        temp.map(el1 => {
                                            return (<>
                                                    <p>{el1}</p>
                                                    <br/>
                                                </>
                                            )
                                        })
                                    }
                                </td>
                                <td><textarea className={cl.text} onBlur={add_additional_agreement_notes}
                                              id={el.additional_agreement_id}></textarea></td>
                                <td> {
                                    el.basalaev_control == "1"
                                        ?
                                        <input id={el.additional_agreement_id} onChange={change_basalaev_status}
                                               type="checkbox" checked/>
                                        :
                                        <input id={el.additional_agreement_id} onChange={change_basalaev_status}
                                               type="checkbox" checked=""/>
                                }
                                </td>
                                <td className={cl.td}> {
                                    el.payment_status == 1
                                        ?
                                        "Оплачено " + global_functions.convert_data(el.payment_date)
                                        : ""
                                }
                                </td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>
        </div>
    );
    else return <Loading/>
};

export default AdditionalAgreements;

