import React from 'react';
import {useState} from "react";
import useStore from "../../Store";
import AddPrescription from "../AddPrescription/add_prescription";
import Show_prescription_building_control from "./show_prescription_building_control";

const PrescriptionBuildingControl = () => {
    const [loading, setLoading]=useState(true);
    const manager=useStore((state)=>state.manager);
    return (
        <>
            <Show_prescription_building_control/>
        </>
    );
};

export default PrescriptionBuildingControl;