import React from 'react';
import {useEffect, useState} from "react";
import Show_post from "./show_post";
import Loading from "../UI/Loading/loading";
import LettersServerRequests from "../api/LettersServerRequests";
import ChangeYear from "./ChangeYear";
import useStore from "../Store";

const Output_letters = () => {
    const [output_letters, setOutput_letters]=useState([]);
    const [isLoading, setIsLoading]=useState(false);
    const [reload, setReload]=useState(1);
    const UserId=useStore((state)=>state.User).Id
    let YearOptions=[
        {value:'1', label:'2023'},
        {value:'2', label:'2024'}
    ]
    const [year, setYear]=useState({value:'2', label:'2024'});


    async function get_letters(year) {
        const res = await LettersServerRequests.get_letters(2, year.label, UserId);
        setOutput_letters(res.data)
        setIsLoading(true)
    }
    useEffect(()=>{get_letters(year)},[reload, year])
    if(isLoading)
        return  (
            <>
                <ChangeYear
                    ChangeYear={setYear}
                /><Show_post setReload={setReload} post={output_letters} direction={"Исходящая"}/>
            </>
        )
    else return <Loading/>;
};

export default Output_letters;