import React from 'react';
import cl from "../../../SubPagesPanel/SubPagesPanel.module.css"
const OutputLettersIcon = () => {
    return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"
             strokeLinejoin="round" strokeWidth="2" viewBox="0 0 140 120">
            <g className="layer">
                <title>Layer 1</title>
                <path
                    d="m123.98,37.83a3,3 0 0 0 -4.2,-0.61l-48.39,36.12a1,1 0 0 1 -1.2,0l-47.44,-36.28a3,3 0 1 0 -3.64,4.77l37.3,28.53l-36.89,27.8a3,3 0 1 0 3.61,4.84l38.23,-28.86l5.18,4a7,7 0 0 0 8.43,0.06l5.44,-4.06l38.68,28.86a3,3 0 1 0 3.59,-4.81l-37.26,-27.84l37.95,-28.35a3,3 0 0 0 0.61,-4.17z"
                    id="svg_2"/>
                <path
                    d="m120,24.5l-98,0a15,15 0 0 0 -15,15l0,60a15,15 0 0 0 15,15l98,0a15,15 0 0 0 15,-15l0,-60a15,15 0 0 0 -15,-15zm9,75a9,9 0 0 1 -9,9l-98,0a9,9 0 0 1 -9,-9l0,-60a9,9 0 0 1 9,-9l98,0a9,9 0 0 1 9,9l0,60z"
                    id="svg_3"/>
                <rect id={cl["ToFill"]} fill="#ffffff" height="42.5" width="59" x="42.77" y="6.09"/>
                <path
                    d="m71.83,11.22l-0.09,26.03l-11.02,-8.88c-0.81,-0.66 -2.14,-0.66 -2.96,-0.01c-0.81,0.65 -0.82,1.71 -0.01,2.36l14.57,11.74c0.81,0.66 2.14,0.66 2.96,0.01l14.65,-11.64c0.41,-0.32 0.61,-0.75 0.61,-1.18c0,-0.43 -0.2,-0.86 -0.61,-1.18c-0.81,-0.66 -2.14,-0.66 -2.96,-0.01l-11.08,8.8l0.09,-26.03c0,-0.92 -0.93,-1.67 -2.08,-1.68s-2.09,0.74 -2.1,1.66l0.01,0z"
                    id="svg_1" transform="rotate(179.569 73.8587 26.2612)"/>
            </g>
        </svg>
    );
};

export default OutputLettersIcon;