import React, {useState, useEffect} from 'react';
import Select from "react-select";
import cl from "./AddContractor.module.css";
import MyButton from "../../UI/MyButton/MyButton";
import CreatableSelect from "react-select/creatable";
import global_functions from "../../global_functions";
import Loading from "../../UI/Loading/loading";
import AdministrationAPI from "../../api/AdministrationAPI";
import useStore from "../../Store";
import ContractorsAPI from "../../api/ContractorsAPI";
import MyInput from "../../UI/MyInput/MyInput";

const AddContractor = () => {
    const [Contractor,setContractor]=useState()
    const [ContractorPerson, setContractorPerson]=useState("");
    const [ContractorPhone, setContractorPhone]=useState("");
    const [Sections, setSections]=useState([]);
    const [SectionsOption, setSectionsOption]=useState([]);
    const [sectionsForServer, setSectionsForServer]=useState([]);
    const [HousesOption,setHousesOption]=useState([]);
    const [House,setHouse]=useState(null);
    const [ContractorsOption, setContractorsOption]=useState([])
    const [IsLoading, setIsLoading]=useState(false);
    const [sectionsLength, setSectionsLength]=useState(0);
    const [workNames, setWorkNames]=useState([]);
    const [workName, setWorkName]=useState(null);
    const [isDisabled, setIsDisabled]=useState(true)
    const UserId=useStore((state)=>state.User).Id;
    const AreaId=useStore((state)=>state.AreaId)

    function getContractorSections(ContractorId, HouseId, workId, UserId){
        ContractorsAPI.getContractorSections(ContractorId, HouseId, workId, UserId).then(
            ({data})=>{
                let temp=[];
                data[0]&&data.map(element=>{
                    temp=[...temp, {value:element.section_id, label:element.section_name}]
                })
                setSections(temp);
                setSectionsLength(temp.length);
                setSectionsForServer(temp);
              })
    }
    function getContractorWorks(UserId){
       ContractorsAPI.getContractorWorks(UserId).then(
            ({data})=>{
                let temp=[];
                console.log(data)
                data[0]&&data.map(element=>{
                    temp=[...temp, {value:element.ContractorWorkNameId, label:element.ContractorWorkName}]
                })
                setWorkNames(temp);
            })
    }

    function editContractorOption(value){
        setContractor(value)
        setHouse(null)
        setIsDisabled(false);
        setContractorPerson(value.ContractorPerson);
        setContractorPhone(value.ContractorPhone);
    }
    function editHousesOption(value){
        setHouse(value);
        setSections(null);
        if(workName)
            getContractorSections(Contractor.value, value.value, workName.value, UserId)
    }
    function editWorkName(value){
        setWorkName(value);
        if(House)
            getContractorSections(Contractor.value, House.value, value.value, UserId)
    }

    function addContractorName(value){
        setContractor({value:0, label:value})
        setIsDisabled(false)
    }

    function addWorkName(value){
        setWorkName({value:0, label:value})
    }

    function editSectionsOption(value){
        let temp=[];
        let isSectionExist=[];
       setSections(value);
       if(value.length>sectionsLength) {
           isSectionExist = sectionsForServer.filter(element => element.value == value[value.length - 1].value);
           if (isSectionExist.length > 0){
               if (isSectionExist[0].hasOwnProperty("deleted")) {
                   temp=sectionsForServer.map(element => {
                       if (element.value == isSectionExist[0].value) {
                           delete element.deleted;
                           return element;
                       }
                       return element;
                   })
               }
           }else{
              temp=[...sectionsForServer, {...value[value.length-1], new:1}]
           }
           setSectionsForServer(temp);
       }
       else{
           temp=sectionsForServer.map(element=>{
               if(value.filter(el=>el.value==element.value).length>0) return element;
               if(element.new==1) return;
               return {...element, deleted:1}
           })
           setSectionsForServer(temp.filter(el=>el!==undefined))
       }
       setSectionsLength(value.length)
    }
    function addNewContractor(){
        if(Contractor&&House&&workName&&Sections) {
            let contractorWork = workName.value;
            if (workName.value === 0) contractorWork = workName.label;

            let contractorName = Contractor.value;
            if (Contractor.value === 0) contractorName = Contractor.label;

            ContractorsAPI.addContractor(contractorName, ContractorPerson, ContractorPhone, sectionsForServer, contractorWork, UserId)
                .then(res => {
                    alert("Все прошлор удачно!");
                    setContractor(null);
                    setHouse(null);
                    setSections(null);
                    setContractorPerson("")
                    setContractorPhone("")
                });
        }else alert("Необходимо заполнить все поля!")

    }

    console.log(sectionsForServer)

    useEffect(()=>{
        global_functions.GetHouses(AreaId, "", UserId)
            .then(res=>{
                setHousesOption(res);
                setIsLoading(true)});
        },[AreaId]);
    useEffect(()=>{
        if(House)
            global_functions.GetSections(House.value, UserId)
                .then(res=>setSectionsOption(res))
    },[House]);
    useEffect(()=>{
        global_functions.getContractors(UserId)
            .then(res=>setContractorsOption(res))
    },[]);
    useEffect(()=>{
        getContractorWorks(UserId)
    },[]);

  //  console.log(sectionsForServer);

      if(IsLoading)
        return (
            <>
                <div className={cl.container}>
                    <CreatableSelect
                        onCreateOption={addContractorName}
                        value={Contractor}
                        className={cl.contractors}
                        placeholder="Наменование"
                        options={ContractorsOption}
                        onChange={editContractorOption}
                    />
                    <MyInput
                        className={cl.input}
                        value={ContractorPerson}
                        onChange={e => setContractorPerson(e.target.value)}
                        placeholder={'Контакное лицо'}
                    />
                    <MyInput
                        mask='+7-999-999-9999'
                        className={cl.input}
                        value={ContractorPhone}
                        onChange={e => setContractorPhone(e.target.value)}
                        placeholder={'Телефон'}
                    />
                    <Select
                        className={cl.house}
                        value={House}
                        options={HousesOption}
                        onChange={editHousesOption}
                        placeholder="Дом"
                        isDisabled={isDisabled}
                    />
                    <CreatableSelect
                        className={cl.works}
                        value={workName}
                        placeholder="Вид работ"
                        options={workNames}
                        onChange={editWorkName}
                        onCreateOption={addWorkName}
                        isDisabled={isDisabled}
                    />
                    <Select
                        className={cl.sections}
                        isMulti
                        value={Sections}
                        placeholder="Секции"
                        options={SectionsOption}
                        onChange={editSectionsOption}
                    />

                </div>
                <MyButton className={cl.button} onClick={addNewContractor}>Сохранить</MyButton>
            </>
        );
    else <Loading/>
};

export default AddContractor;