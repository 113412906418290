import React from 'react';
import PtoMaterials from "./PtoMaterials";
import AddMaterialSection from "./add_material_section";
import Add_material_name from "./add_material_name";
import Add_material_from_excel from "./add_material_from_excel";

const PtoPageMaterials = () => {
    return (
        <>
            <details>
                <summary>Добавить материал</summary>
                <Add_material_name/>
            </details>
            <details>
                <summary>Добавить метериал посекционно (изменить количество)</summary>
                <AddMaterialSection/>
            </details>
            <details>
                <summary>Добавить метериал Excel</summary>
                <Add_material_from_excel/>
            </details>
            <PtoMaterials/>
        </>
    );
};

export default PtoPageMaterials;