import React, {useEffect, useState} from 'react';
import MyInput from "../../UI/MyInput/MyInput";
import MyButton from "../../UI/MyButton/MyButton";
import cl from "./AddNewUser.module.css"
import AdministrationAPI from "../../api/AdministrationAPI";
import Select from "react-select";
import global_functions from "../../global_functions";
import useStore from "../../Store";


const AddNewUser = () => {
    const [login, setLogin]=useState("");
    const [password, setPassword]=useState("");
    const [password_confirmation, setPassword_confirmation]=useState("");
    const [phone, setPhone]=useState();
    const [position, setPosition]=useState();
    const [FamilyName, setFamilyName]=useState("");
    const [SurName, setSurName]=useState("");
    const [Name, setName]=useState("");
    const [departments, setDepartments]=useState([]);
    const [department, setDepartment]=useState([]);
    const UserId=useStore((state)=>state.User).Id;
    const AreaId=useStore((state)=>state.AreaId)

    function EditDepartmentOptions(value){
        setDepartment(value);
    }

    async function AddNewUser(){
        if(password!==password_confirmation) {
            alert("Пароли не совпадают");
            return
        }
        if (password.length>10) {
            alert("Пароль не должен превышать 10 символов");
            return
        }
        if (login.length>25) {
            alert("Логин не должен превышать 25 символов");
            return
        }
        const res=await AdministrationAPI.addUser(AreaId, FamilyName, Name, SurName, phone, position, login, password, department.value, UserId);

        if(res.data==="OK") {
            alert("Пользователь успешно добавлен.")

            setFamilyName("");
            setName("");
            setSurName("");
            setPhone("");
            setPosition("");
            setLogin("");
            setPassword("");
            setPassword_confirmation("");
            setDepartment(null);
        }
    }

    useEffect(()=>{
        global_functions.GetDepartments(UserId)
            .then(res=>
                setDepartments(res)
            )
    },[])

    return (
        <div className={cl.container}>
            <h3>Введите Фамилию:</h3>
            <MyInput
                value={FamilyName}
                className={cl.input}
                onChange={e => setFamilyName(e.target.value)}>
            </MyInput>
            <h3>Введите Имя:</h3>
            <MyInput
                value={Name}
                className={cl.input}
                onChange={e => setName(e.target.value)}>
            </MyInput>
            <h3>Введите Отчество:</h3>
            <MyInput
                value={SurName}
                className={cl.input}
                onChange={e => setSurName(e.target.value)}>
            </MyInput>
            <h3>Введите логин:</h3>
            <MyInput
                value={login}
                className={cl.input}
                onChange={e => setLogin(e.target.value)}>

            </MyInput>
            <div>
                <h3>Введите пароль:</h3>
                <MyInput
                    value={password}
                    className={cl.input}
                    onChange={e => setPassword(e.target.value)}>

                </MyInput>
                <h3>Подтверждение пароля:</h3>
                <MyInput
                    value={password_confirmation}
                    className={cl.input}
                    onChange={e => setPassword_confirmation(e.target.value)}>

                </MyInput>
            </div>
            <br/>
            <h3>Телефон:</h3>
            <MyInput
                mask='+7-999-999-9999'
                className={cl.input}
                value={phone}
                onChange={e => setPhone(e.target.value)}
            />
            <MyInput
                className={cl.input}
                value={position}
                onChange={e => setPosition(e.target.value)}
            />
            <Select
                value={department}
                className={cl.department}
                placeholder="Отдел"
                options={departments}
                onChange={EditDepartmentOptions}
            />
            <br/>
            <MyButton className={cl.button} onClick={AddNewUser}>Добавить позьзователя</MyButton>
        </div>
    );
};

export default AddNewUser;