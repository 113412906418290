import React, {useEffect, useState} from 'react';
import cl from "./SubPagesPanel.module.css"
import Add_letter from "../Letters/add_letter";
import Input from "../Letters/Input";
import Output from "../Letters/Output";
import AddDocuments from "../Documents/AddDocument/AddDocument";
import ShowGPRHouse from "../gpr/showGPRHouse/showGPRHouse";
import AddProject from "../Projects/AddProject/addProject";
import Show_projects from "../Projects/show_projects";
import ReadProjectQR from "../Projects/ReadProjectQR/ReadProjectQR";
import MatchingProjects from "../Projects/MatchingProjects/MatchingProjects";
import ExecutiveDocumetntationAdd
    from "../executive documentation/executiveDocumentationAdd/executive_documetntation_add";
import Executive_documentation_show
    from "../executive documentation/executiveDocumentationShow/executive_documentation_show";
import AddPrescription from "../prescriptions/AddPrescription/add_prescription";
import PrescriptionBuildingControl from "../prescriptions/building_control/prescription_building_control";
import AddNewUser from "../Admin/AddNewUser/AddNewUser";
import ChangeUserRights from "../Admin/ChangeUserRights/ChangeUserRights";
import AddContractor from "../Contractors/AddContractor/AddContractor";
import AddArea from "../Admin/AddArea/AddArea";
import AddQueue from "../Admin/AddQueue/AddQueue";
import AddHouse from "../Admin/AddHouse/AddHouse";
import EditSections from "../Admin/EditSections/EditSections";
import Map from "../map/map";
import AddGPRHouseWorks from "../gpr/addGPRHouseWorks/addGPRHouseWorks";
import MapInfo from "../map/mapInfo/mapInfo";
import AddLetterIcon from "../Icons/SubPages/Letters/AddLetterIcon";
import InputLettersIcon from "../Icons/SubPages/Letters/InputLettersIcon";
import OutputLettersIcon from "../Icons/SubPages/Letters/OutputLettersIcon";
import AddDocumentIcon from "../Icons/SubPages/Documents/AddDocumentIcon";
import Additional_agreements from "../AdditionalAgreements/additional_agreements";
import Pto_documents from "../Documents/ShowDocuments/ShowDocuments";
import Add_material_name from "../Materials/add_material_name";
import PtoMaterials from "../Materials/PtoMaterials";
import Requests from "../requests/requests";
import Windows_change_status from "../windows/windows_change_status";
import EditWindows from "../windows/EditWindows/EditWindows";
import WindowsShowIcon from "../Icons/SubPages/Windows/WindowsShowIcon";
import WindowsEditIcon from "../Icons/SubPages/Windows/WindowsEditIcon";
import AddBudget from "../Economics/Budgets/editBudget/editBudget";
import Payments from "../Economics/Payments/Payments";
import Receivings from "../Economics/Receivings/Receivings";

const ShowSubPages = ({SubPages, setSubPage}) => {

    const [SelectedSubPage, setSelectedSubPage]=useState(0)


    const SubPagesActions={
        //Letters
        "Добавить письмо":{Action:<Add_letter setSelectedSubPage={setSelectedSubPage}/>, Icon:<AddLetterIcon/>},
        "Входящие":{Action:<Input direction={1}/>, Icon:<InputLettersIcon/>},
        "Исходящие":{Action:<Output direction={2}/>, Icon:<OutputLettersIcon/>},

        //Documents
        "Добавить документ":{Action:<AddDocuments/>, Icon:<AddDocumentIcon/>},
        "Документы ПТО":{Action:<Pto_documents/>, Icon:""},

        //Materials
        "Добавить нименование материала":{Action:<Add_material_name/>, Icon:""},
        // // Редактировать материалы
        // // Добавить материал из Excel
        "Показать материалы":{Action:<PtoMaterials/>, Icon:""},

        //GPR
        "Показать ГПР":{Action:<ShowGPRHouse/>, Icon:""},

        //Requests
        // // Добавить заявку
        "Показать заявки":{Action:<Requests/>, Icon:""},

        //Projects
        "Добавить проект":{Action:<AddProject/>, Icon:""},
        "Показать проекты":{Action:<Show_projects/>, Icon:""},
        "Сканировать QR":{Action:<ReadProjectQR/>, Icon:""},
        "Сравнение проектов":{Action:<MatchingProjects/>, Icon:""},

        // Executive Documentation
        "Добавить исполнительную":{Action:<ExecutiveDocumetntationAdd/>, Icon:""},
        "Показать исполнительную":{Action:<Executive_documentation_show/>, Icon:""},
        // // Сканировать QR

        //Prescriptions
        "Добавить предписание":{Action:<AddPrescription/>, Icon:""},
        "Показать предписания":{Action:<PrescriptionBuildingControl/>, Icon:""},

        //Windows
        "Редактирование окон":{Action:<EditWindows/>, Icon:<WindowsEditIcon/>},
        "Показать окна":{Action:<Windows_change_status/>, Icon:<WindowsShowIcon/>},

        //Special for 45 pages
        "Показать доп.соглашения":{Action:<Additional_agreements/>, Icon:""},
        // // Testing

        // Administration
        "Добавить пользователя":{Action:<AddNewUser/>, Icon:""},
        "Изменение прав доступа Заказчик":{Action:<ChangeUserRights/>, Icon:""},
        "Добавить подрядчика":{Action:<AddContractor/>, Icon:""},
        "Добавить район":{Action:<AddArea/>, Icon:""},
        "Добавить очередь":{Action:<AddQueue/>, Icon:""},
        "Добавить дом":{Action:<AddHouse/>, Icon:""},
        "Изменить секции":{Action:<EditSections/>, Icon:""},

        //Map
        "Показать карту":{Action:<Map/>, Icon:""},
        "Список работ ГПР":{Action:<AddGPRHouseWorks/>, Icon:""},
        "Графики":{Action:<MapInfo/>, Icon:""},

        //Economics
        "Редактировать бюджет":{Action:<AddBudget/>, Icon:""},
        "Расходы":{Action:<Payments/>, Icon:""},
        "Доходы":{Action:<Receivings/>, Icon:""}
    }

    function onSubPageChange(id){
        setSelectedSubPage(id.currentTarget.id)
    }

    useEffect(() => {
        setSubPage(SubPagesActions[SelectedSubPage]?SubPagesActions[SelectedSubPage].Action:"")
    }, [SelectedSubPage]);

    useEffect(() => {
        if(SubPages.length===1)
            setSubPage(SubPagesActions[SubPages[0].sub_page_name].Action)
        else
            setSelectedSubPage(0)
    }, [SubPages]);

    return (
        <>
            {
                SubPages.map((element, index, array)=>{
                    const rootClasses=[cl.row];
                    if (element.sub_page_name === SelectedSubPage) {rootClasses.push(cl.selected);}
                    return(
                        <div
                            className={rootClasses.join(' ')}
                            id={element.sub_page_name}
                            onClick={onSubPageChange}
                        >
                            <div className={cl.container}>
                                {SubPagesActions[element.sub_page_name]?SubPagesActions[element.sub_page_name].Icon:""}
                               <i> {element.sub_page_name} </i>
                            </div>
                        </div>
                    )
                })
            }
        </>
    );
};

export default ShowSubPages;