import React, {useEffect, useState} from 'react';
import EconomicsServerRequests from "../../../api/EconomicsServerRequests";
import CreatableSelect from "react-select/creatable";
import cl from "./editBudget.module.css";
import Select from "react-select";
import InputMask from "react-input-mask";
import global_functions from "../../../global_functions";
import useStore from "../../../Store";
import BudgetRows from "./BudgetRows";

const EditBudget = () => {
    const [BudgetRowsData, setBudgetRowsData]=useState([])

    const [BudgetsOption, setBudgetsOption]=useState([])

    const [Budget, setBudget]=useState(null)

    const [BudgetStartDate, setBudgetStartDate]=useState()
    const [BudgetStopDate, setBudgetStopDate]=useState()

    const AreaId=useStore((state)=>state.AreaId)
    const UserId=useStore((state)=>state.User).Id;
    const [onClickSaveButton, setOnClickSaveButton]=useState(false)

    const [QueuesOption,setQueuesOption]=useState([]);
    const [Queue, setQueue]=useState();
    const [IsLoading, setIsLoading]=useState(false);

    const [HousesOption,setHousesOption]=useState([]);
    const [House,setHouse]=useState(null);

    const [QueueSelectDisabled, setQueueSelectDisabled]=useState(false)
    const [HouseSelectDisabled, setHouseSelectDisabled]=useState(false)

    function addBudgetName(value){
        setBudget({value:0, label: value})
    }
    function editBudgetNameOption(value){
        setBudget(value)
        if(value) {
            if (value.data.queue_id != null) setQueue(QueuesOption.filter((element) => element.value == value.data.queue_id))
            if (value.data.house_id != null) setQueue(HousesOption.filter((element) => element.value == value.data.house_id))
            setBudgetStartDate(value.data.BudgetStartDate)
            setBudgetStopDate(value.data.BudgetStopDate)
           EconomicsServerRequests.getBudgetRows(value.value).then(({data})=>setBudgetRowsData(data));
        }
    }
    function editQueuesOption(value){
        setQueue(value);
        setHouseSelectDisabled(true);
        if(value===null) setHouseSelectDisabled(false);
    }
    function editHousesOption(value){
        setHouse(value);
        setQueueSelectDisabled(true);
        if(value===null) setQueueSelectDisabled(false);
    }

    useEffect(() => {
        if(Budget)
            if(onClickSaveButton){
                EconomicsServerRequests.addBudget(AreaId, Queue, House, Budget.value, Budget.label, BudgetStartDate, BudgetStopDate, BudgetRowsData, UserId)
                    .then(res=>console.log(res))
                setOnClickSaveButton(false);
            }
        else alert("Поле: Наименование бюджета обязательно для заполнения");
    }, [onClickSaveButton]);

    useEffect(()=>{
        global_functions.GetQueues(AreaId)
            .then(res=>{
                setQueuesOption(res)
                setIsLoading(true)
            })
    },[AreaId])
    useEffect(()=>{
        global_functions.GetHouses(AreaId, "")
            .then(
                res=>setHousesOption(res)
            )
    },[]);
    useEffect(() => {
        EconomicsServerRequests.getBudgetsNames(AreaId)
            .then(
                (res)=> setBudgetsOption(res)
            )
    }, []);
    // useEffect(() => {
    //     if(Budget)
    //         EconomicsServerRequests.getBudget(Budget.value)
    //             .then(
    //                 ({data})=> console.log(data)
    //             )
    // }, [Budget]);

    console.log(BudgetRowsData)

    return (
        <div>
            <div className={cl.SelectDiv}>
            <CreatableSelect
                onCreateOption={addBudgetName}
                value={Budget}
                className={cl.BudgetSelect}
                placeholder="Наименование бюджета"
                options={BudgetsOption}
                onChange={editBudgetNameOption}
                isClearable
            />
            </div>
            <div className={cl.BudgetsProperties}>
                <div>
                    <p>Временные рамки</p>
                    <div className={cl.BudgetTime}>
                        <p>c </p> &nbsp;&nbsp;
                        <InputMask
                            mask='99.99.9999'
                            placeholder='дд.мм.гггг'
                            value={BudgetStartDate}
                            onChange={e=>setBudgetStartDate(e.target.value)}
                            name='BudgetStartDate'
                            className={cl.date}
                        />
                        &nbsp;&nbsp; <p>по</p> &nbsp;&nbsp;
                        <InputMask
                            mask='99.99.9999'
                            placeholder='дд.мм.гггг'
                            value={BudgetStopDate}
                            onChange={e=>setBudgetStopDate(e.target.value)}
                            name='BudgetStartDate'
                            className={cl.date}
                        />
                    </div>
                </div>
                <Select
                    value={Queue}
                    className={cl.Select}
                    placeholder="Очередь"
                    options={QueuesOption}
                    onChange={editQueuesOption}
                    isClearable
                    isDisabled={QueueSelectDisabled}
                />
                <Select
                    value={House}
                    className={cl.Select}
                    placeholder="Дом"
                    options={HousesOption}
                    onChange={editHousesOption}
                    isClearable
                    isDisabled={HouseSelectDisabled}
                />
            </div>
                <div>
                    <BudgetRows
                        BudgetRowsData={BudgetRowsData}
                        setBudgetRowsData={setBudgetRowsData}
                        setOnClickSaveButton={setOnClickSaveButton}
                    />
                </div>

        </div>
    );
};

export default EditBudget;