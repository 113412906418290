import axios from "axios";

export default class server_constants{
    static global_url="https://crm.yourprivelege.ru/server/";
    static login = 'QTWma%1N{1hWs%rG}|GK@8g35';
    static psw = '14ygCCEu7m4v~k~}xHKKKkXg4';
    static read_only=684;

    static url=this.checkUrl();


    static checkUrl(){
        let LocalUrl="http://127.0.0.1:8000/common_api.php"
        let Url = this.global_url+'common_api.php';
        if(window.location.href.indexOf('localhost')!==-1) return LocalUrl;
        return Url;
    }

    static async getArea(UserId){
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_area',
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async get_houses(AreaId, QueueId, UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_houses',
                AreaId: AreaId,
                QueueId: QueueId,
                UserId:UserId,
                login: this.login,
                password: this.psw
            }
        });
        return responce;
    }
    static async get_queues(AreaId, UserId) {
        if(!!AreaId) {
            const responce = await axios.get(this.url, {
                params: {
                    action: 'get_queues',
                    AreaId: AreaId,
                    UserId:UserId,
                    login: this.login,
                    password: this.psw
                }
            });
            return responce;
        }
    }
    static get_sub_pages(sub_pages, UserId){
        return axios.get(this.url, {
            params: {
                action: 'get_sub_pages',
                sub_pages: sub_pages,
                UserId:UserId,
                login: this.login,
                password: this.psw
            }
        }).then(result=>result);
    }
    static async get_sections(house_id, UserId){
        if(!!house_id) {
            const responce = await axios.get(this.url, {
                params: {
                    action: 'get_sections',
                    house_id: house_id,
                    UserId:UserId,
                    login: this.login,
                    password: this.psw
                }
            });
            return responce;
        }
    }
    static async get_contractors(UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_contractors',
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async login_to_server(manager_login, pass) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'login_to_server',
                manager_login:manager_login,
                pwd:pass,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async getUserSubPages(UserId, AreaId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getUserSubPages',
                UserId:UserId,
                AreaId:AreaId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
}