import React, {useEffect, useState} from 'react';
import cl from "./house1.3.module.css";

const House13 = (props) => {
    const [sectionsBackgroundStyle, setSectionsBackgroundStyle]=useState({});
    const [sections, setSections]=useState([]);
    const [isLoading, setIsLoading]=useState(false);

    function matchSections(inputSections){
        let temp=[];
        inputSections[0]&&inputSections.map(element=>{
            temp[element.section_name]={section_id:element.section_id, sectionName:element.section_name, percent:element.percent}
        });
        setSections(temp);
        setIsLoading(true)
    }
    
    function onSectionClick(event){
        props.setOnClickSectionId(event.currentTarget.id)
    }

    useEffect(() => {
        let temp={}
        props.sections.map(element=>{
            // if(Number(element.percent) >=0  && Number(element.percent) <= 10) temp[element.section_id]={background:element.c10}
            // if(Number(element.percent) > 10 && Number(element.percent) <= 20) temp[element.section_id]={background:element.c20}
            // if(Number(element.percent) > 20 && Number(element.percent) <= 30) temp[element.section_id]={background:element.c30}
            // if(Number(element.percent) > 30 && Number(element.percent) <= 40) temp[element.section_id]={background:element.c40}
            // if(Number(element.percent) > 40 && Number(element.percent) <= 50) temp[element.section_id]={background:element.c50}
            // if(Number(element.percent) > 50 && Number(element.percent) <= 60) temp[element.section_id]={background:element.c60}
            // if(Number(element.percent) > 60 && Number(element.percent) <= 70) temp[element.section_id]={background:element.c70}
            // if(Number(element.percent) > 70 && Number(element.percent) <= 80) temp[element.section_id]={background:element.c80}
            // if(Number(element.percent) > 80 && Number(element.percent) <= 90) temp[element.section_id]={background:element.c90}
            if(Number(element.percent) > 0 && Number(element.percent) <= 100) temp[element.section_id]={background:element.c100}
        })
        setSectionsBackgroundStyle(temp)
        matchSections(props.sections)
    }, [props]);
    if(isLoading)
    return (
        <div className={cl.mainDiv}>
            <div id={sections[1].section_id} className={cl.section1}
                 style={sectionsBackgroundStyle[sections[1].section_id]} onClick={onSectionClick}>
                {sections[1].percent ? sections[1].percent + "%" : ""}
            </div>
            <div id={sections[2].section_id} className={cl.section2}
                 style={sectionsBackgroundStyle[sections[2].section_id]} onClick={onSectionClick}>
                {sections[2].percent ? sections[2].percent + "%" : ""}
            </div>
            <div id={sections[3].section_id} className={cl.section3}
                 style={sectionsBackgroundStyle[sections[3].section_id]} onClick={onSectionClick}>
                {sections[3].percent ? sections[3].percent + "%" : ""}
            </div>
            <div id={sections[4].section_id} className={cl.section4}
                 style={sectionsBackgroundStyle[sections[4].section_id]} onClick={onSectionClick}>
                {sections[4].percent ? sections[4].percent + "%" : ""}
            </div>
            <div id={sections[5].section_id} className={cl.section5}
                 style={sectionsBackgroundStyle[sections[5].section_id]} onClick={onSectionClick}>
                {sections[5].percent ? sections[5].percent + "%" : ""}
            </div>
            <div id={sections[6].section_id} className={cl.section6}
                 style={sectionsBackgroundStyle[sections[6].section_id]} onClick={onSectionClick}>
                {sections[6].percent ? sections[6].percent + "%" : ""}
            </div>
            <div id={sections[7].section_id} className={cl.section7}
                 style={sectionsBackgroundStyle[sections[7].section_id]} onClick={onSectionClick}>
                {sections[7].percent ? sections[7].percent + "%" : ""}
            </div>
            <div id={sections[8].section_id} className={cl.section8}
                 style={sectionsBackgroundStyle[sections[8].section_id]} onClick={onSectionClick}>
                {sections[8].percent ? sections[8].percent + "%" : ""}
            </div>
            <div id={sections[9].section_id} className={cl.section9}
                 style={sectionsBackgroundStyle[sections[9].section_id]} onClick={onSectionClick}>
                {sections[9].percent ? sections[9].percent + "%" : ""}
            </div>
            <div id={sections[10].section_id} className={cl.section10}
                 style={sectionsBackgroundStyle[sections[10].section_id]} onClick={onSectionClick}>
                {sections[10].percent ? sections[10].percent + "%" : ""}
            </div>
            <div id={sections[11].section_id} className={cl.section11}
                 style={sectionsBackgroundStyle[sections[11].section_id]} onClick={onSectionClick}>
                {sections[11].percent ? sections[11].percent + "%" : ""}
            </div>
            <div id={sections[12].section_id} className={cl.section12}
                 style={sectionsBackgroundStyle[sections[12].section_id]} onClick={onSectionClick}>
                {sections[12].percent ? sections[12].percent + "%" : ""}
            </div>
            <div id={sections[13].section_id} className={cl.section13}
                 style={sectionsBackgroundStyle[sections[13].section_id]} onClick={onSectionClick}>
                {sections[13].percent ? sections[13].percent + "%" : ""}
            </div>
            <div id={sections[14].section_id} className={cl.section14}
                 style={sectionsBackgroundStyle[sections[14].section_id]} onClick={onSectionClick}>
                {sections[14].percent ? sections[14].percent + "%" : ""}
            </div>
            <div id={sections[15].section_id} className={cl.section15}
                 style={sectionsBackgroundStyle[sections[15].section_id]} onClick={onSectionClick}>
                {sections[15].percent ? sections[15].percent + "%" : ""}
            </div>
            <div id={sections[16].section_id} className={cl.section16}
                 style={sectionsBackgroundStyle[sections[16].section_id]} onClick={onSectionClick}>
                {sections[16].percent ? sections[16].percent + "%" : ""}
            </div>
            <div id={sections[17].section_id} className={cl.section17}
                 style={sectionsBackgroundStyle[sections[17].section_id]} onClick={onSectionClick}>
                {sections[17].percent ? sections[17].percent + "%" : ""}
            </div>
            <div id={sections[18].section_id} className={cl.section18}
                 style={sectionsBackgroundStyle[sections[18].section_id]} onClick={onSectionClick}>
                {sections[18].percent ? sections[18].percent + "%" : ""}
            </div>
            <div id={sections[19].section_id} className={cl.section19}
                 style={sectionsBackgroundStyle[sections[19].section_id]} onClick={onSectionClick}>
                {sections[19].percent ? sections[19].percent + "%" : ""}
            </div>
            <div id={sections[20].section_id} className={cl.section20}
                 style={sectionsBackgroundStyle[sections[20].section_id]} onClick={onSectionClick}>
                {sections[20].percent ? sections[20].percent + "%" : ""}
            </div>
            <div id={sections[21].section_id} className={cl.section21}
                 style={sectionsBackgroundStyle[sections[21].section_id]} onClick={onSectionClick}>
                {sections[21].percent ? sections[21].percent + "%" : ""}
            </div>
            <div className={cl.house_name}>{props.name}</div>
        </div>
    );
};

export default House13;