import React, {useEffect, useState} from 'react';
import cl from "./mapModal.module.css";
import mapServerRequests from "../../../api/mapServerRequests";
import global_functions from "../../../global_functions";
import moment from "moment/moment";
import useStore from "../../../Store";

const MapModal = ({visible, setVisible, sectionId, setReloading, setAllPageLoad, Date}) => {
    const rootClasses=[cl.mapModal];
    const [content, setContent]=useState();
    const [sectionPercent, setSectionPercent]=useState([]);
    const [works, setWorks]=useState(null);
    const [isLoading, setIsLoading]=useState(false)
    const [workName, setWorkName]=useState("");
    const [percent, setPercent]=useState();
    const UserId=useStore((state)=>state.User).Id;
    let date=Date.value;
    function nextWork(){

        let work={};
        let nextElement=false;
        works.map(element=>{
            if(Number(element.map_work_id)===Number(workName.workId)+1 && element.third_floor_house!="null")
                work={workId:element.map_work_id, workName:element.map_work_name, sectionData:workName.sectionData};
            if(nextElement) {
                work={workId:element.map_work_id, workName:element.map_work_name};
                nextElement=false;
            }
            if(Number(element.map_work_id)===Number(workName.workId)+1 && element.third_floor_house=="null")
                nextElement=true;
        });
        setWorkName(work);
    }
    async function savePercent(){
        mapServerRequests.addSectionPercent(sectionId,percent,workName.workId, date, UserId)
            .then((res)=>{
                setReloading(value=> value+Math.random())
            });
        setPercent("");
        setVisible(false);
    }
    async function nothingChanged(){
        mapServerRequests.addSectionPercent(sectionId,sectionPercent[0].percent, sectionPercent[0].map_work_id, date, UserId)
            .then((res)=>
                setReloading(value=>value+Math.random())
            );
        setPercent("");
        setVisible(false);
    }

    if(visible){
        rootClasses.push(cl.active);
    }

    useEffect(()=>{
        function OnKeyDown(e){
            if(e.key=="Enter") savePercent();
        }

        document.addEventListener('keydown', OnKeyDown);
        return _=>{document.removeEventListener("keydown", OnKeyDown)}

    },[percent])

    useEffect(() => {
        setContent(document.getElementById("mapModalContent"));
    }, []);

    useEffect(() => {
        if(sectionId)
            mapServerRequests.getSectionPercent(sectionId, UserId)
            .then(({data})=>{
                setSectionPercent(data);
                setWorkName({workId:data[0].map_work_id, workName:data[0].map_work_name, sectionData:data[0].house_num+" секция "+data[0].section_name});
            })
    }, [sectionId]);

    useEffect(() => {
        mapServerRequests.getMapWorks(UserId)
            .then(({data})=>{
                setWorks(data);
                setIsLoading(true);
                setAllPageLoad(true)});
    }, []);

    if(isLoading&&visible)
    return (
        <div className={rootClasses.join(' ')} onClick={()=>setVisible(false)}>
            <div id="mapModalContent" className={cl.mapModalContent} onClick={(e) => e.stopPropagation()}>
                <div>{workName.sectionData}</div>
                <br/>
                <button onClick={nothingChanged}>Ничего не поменялось</button>
                <br/> <br/>
                <div className={cl.inputContainer}>
                    <div>{workName.workName}</div>
                    <input
                        value={percent}
                        onChange={event => setPercent(event.target.value)}
                        className={cl.input}
                        autoFocus={true}
                    />
                    <button onClick={nextWork}>+</button>  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;
                    <button onClick={savePercent}>OK</button>
                </div>
                <table className={cl.table}>
                    <th>Наименование работ</th>
                    <th>Процент</th>
                    <th>Дата</th>
                    {
                        sectionPercent[0] && sectionPercent.map((element, index) => {
                            return (
                                <tr>
                                    <td>{element.map_work_name}</td>
                                    <td>{element.percent}</td>
                                    <td>{global_functions.convert_data(element.date)}</td>
                                </tr>
                            );
                        })
                    }
                </table>
            </div>
        </div>
    );
};
export default MapModal;