import React from 'react';
import cl from "./house1.4.module.css"
const House14 = () => {
    return (
        <div className={cl.container14}>
            <div id={"section 1"} className={cl.section1_14}>1</div>
            <div id={"section 2"} className={cl.section2_14}>2
                <div className={cl.section2_14_corner}></div>
            </div>
            <div id={"section 3"} className={cl.section3_14}>3</div>
            <div id={"section 4"} className={cl.section4_14}>4
                <div className={cl.section4_14_corner}></div>
            </div>
            <div id={"section 5"} className={cl.section5_14}>5</div>
            <div id={"section 6"} className={cl.section6_14}>6
            <div className={cl.section6_14_corner}></div>
            </div>
            <div id={"section 7"} className={cl.section7_14}>7</div>
            <div id={"section 8"} className={cl.section8_14}>8</div>
            <div id={"section 9"} className={cl.section9_14}>9
                <div className={cl.section9_14_corner}></div>
            </div>
            <div id={"section 10"} className={cl.section10_14}>10</div>
        </div>
    );
};

export default House14;