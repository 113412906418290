import React from 'react';
import cl from "./building_control/show_prescription_building_control.module.css";
import global_functions from "../global_functions";

const ShowPrescriptions = ({prescriptions}) => {
    function show_file(e){
        if(document.getElementById(e.currentTarget.id).getAttribute("data-file"))
            window.open(e.currentTarget.id)
        else alert("Файл не найден")
    }
    return (
        <div className={cl.mainDiv}>
            <table className={cl.table}>
                <th>Дата</th>
                <th>Подрядчик</th>
                <th>Кем выдано</th>
                <th>Дом</th>
                <th>Секция</th>
                <th>Описание</th>
                <th>Примечания</th>
                <th>Файл</th>
                <th>Срок устранения</th>
                <th>Дата устранения</th>
                {
                    prescriptions[0]&&prescriptions.map(el=>{
                        let notFixed=cl.fixed;
                        if(el.prescription_status==='1') notFixed=cl.notFixed;
                        return(
                            <tr className={notFixed}>
                                <td>{global_functions.convert_data(el.prescription_date)}</td>
                                <td>{el.contractor_name}</td>
                                <td>{el.fio}</td>
                                <td>{el.house_num}</td>
                                <td>{el.sections}</td>
                                <td>{el.prescription_description}</td>
                                <td>{el.prescription_notes}</td>
                                <td id={el.prescription_file_place + el.prescription_file} data-file={el.prescription_file} onClick={show_file} className={cl.file}>Файл</td>
                                <td>{global_functions.convert_data(el.fix_to_date)}</td>
                                <td>{el.fixation_date==0?"":global_functions.convert_data(el.fixation_date)}</td>
                            </tr>
                        );
                    })
                }
            </table>
        </div>
    );
};

export default ShowPrescriptions;