import axios from "axios";
import server_constants from "./server_constants";
import BudgetRows from "../Economics/Budgets/editBudget/BudgetRows";
import global_functions from "../global_functions";

export default class EconomicsServerRequests{
    static login = server_constants.login;
    static psw = server_constants.psw;
    static url=this.checkUrl();


   static checkUrl(){
        let LocalUrl="http://127.0.0.1:8000/EconomicsApi.php"
        let Url = server_constants.global_url+'Economics_api.php';
        if(window.location.href.indexOf('localhost')!==-1) return LocalUrl;
        return Url;
    }

    static async getBudgetsNames(AreaId) {
        const responce = await axios.get(this.url, {
            params: {
                Action: 'getBudgetsNames',
                AreaId: AreaId,
                login:this.login,
                password:this.psw
            }
        });
        if(responce.data !== "No data"){
            let temp=[];
            responce.data.map(element => {
                temp = [...temp, {value: element.BudgetId, label: element.BudgetName, data:{...element}}];
            });
            return temp;
        }
        return [];
    }
    static async getBudgetRows(BudgetId) {
        const responce = await axios.get(this.url, {
            params: {
                Action: 'getBudgetRows',
                BudgetId: BudgetId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async addBudget(AreaId, QueueId, HouseId, BudgetId, BudgetName, BudgetStartDate, BudgetStopDate, BudgetRowsData, UserId) {
        let StartDate=BudgetStartDate;
        let StopDate=BudgetStopDate;
        const formData=new FormData();
        formData.append('Action','addBudget');
        formData.append('AreaId', AreaId);
        formData.append('QueueId', QueueId==null?null:QueueId.value);
        formData.append('HouseId', HouseId==null?null:HouseId.value);
        formData.append('BudgetName', BudgetName);
        formData.append('BudgetId', BudgetId);
        if(StartDate != undefined) StartDate=global_functions.convertDateToBaseFormat(BudgetStartDate)
        formData.append('BudgetStartDate',StartDate);
        if(StopDate != undefined) StopDate=global_functions.convertDateToBaseFormat(BudgetStopDate)
        formData.append('BudgetStopDate', StopDate);
        formData.append('UserId',UserId)
        formData.append('BudgetRows',JSON.stringify(BudgetRowsData))
        formData.append('login', this.login);
        formData.append('password',this.psw);
        global_functions.showFormData(formData);
        return axios.post(this.url, formData);
    }



}