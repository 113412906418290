import React, {useEffect, useState} from 'react';
import InputMask from "react-input-mask";
import cl from "./edit_letter.module.css";
import CreatableSelect from "react-select/creatable";
import MyButton from "../UI/MyButton/MyButton";
import Select from "react-select";
import useStore from "../Store";
import moment from "moment";
import LettersServerRequests from "../api/LettersServerRequests";

const EditLetter = ({setReload, letter_id, setLetter_id}) => {

    const [letter_date, setLetter_date]=useState(moment().format('DD-MM-YYYY'));
    const [letter_notes, setLetter_notes]=useState('');
    const [letter_another_number, setLetter_another_number]=useState('');
    const [letter_from, setLetter_from]=useState(null);
    const [letter_type, setLetter_type]=useState({value:'1', label:'Входящее'});
    const [letter_to, setLetter_to]=useState(null);
    const [isLoading, setIsLoading]=useState(true);
    const [selectedFile,setSelectedFile]=useState();
    const [another_selectedFile,setAnother_SelectedFile]=useState();
    const [letter_user_id, setLetter_user_id]=useState();
    const UserId=useStore((state)=>state.User).Id;

    async function get_letter(){
       const res = await LettersServerRequests.get_letter(letter_id, UserId);
        setIsLoading(false)
        const d=res.data[0].letter_date.split('-');
        if(res.data[0].letter_type===2)setLetter_type({value:'2', label:'Исходящее'});
        setLetter_from({value:"0", label:res.data[0].letter_from_name});
        setLetter_to({value:"0", label:res.data[0].letter_to_name});
        setLetter_notes(res.data[0].letter_notes);
        setLetter_date(d[2]+"-"+d[1]+"-"+d[0]);
        setLetter_user_id(res.data[0].user_id);
        setLetter_another_number(res.data[0].letter_another_number);
        setIsLoading(true)
    }

    function fileSelect(e){
        setSelectedFile(e.target.files[0])
    }
    function another_fileSelect(e){
        setAnother_SelectedFile(e.target.files[0])
    }
    async function edit_letter(e){
        e.preventDefault();
       if(UserId==letter_user_id || UserId==1){
            const res=await LettersServerRequests.edit_letter(letter_id,letter_notes,letter_another_number,selectedFile, UserId);
            setReload(Math.random(100));
            setLetter_id(0);
       }else alert("You don't have permission for this action!")
    }
    useEffect(()=>{get_letter()},[letter_id])
    if (isLoading)
    return (
        <form onSubmit={edit_letter}>
            <div className={cl.main_div}>
            <InputMask
                mask='99-99-9999'
                placeholder='DD-MM-YYYY'
                value={letter_date}
                className={cl.date}
                disabled
            />
            <Select className={cl.select} value={letter_type} disabled/>
            <CreatableSelect  value={letter_from} className={cl.select} disabled/>
            <CreatableSelect value={letter_to} className={cl.select} disabled/>
            <InputMask
                value={letter_notes}
                onChange={e=>setLetter_notes(e.target.value)}
                placeholder='Примечание'
                name='notes'
                className={cl.notes}
            />
            <InputMask
                name="file"
                type="file"
                onChange={fileSelect}
                className={cl.file}
            />
            </div>

            <table>
                <th>вх/исх</th>
                <tr>
                    <dt>
                        <InputMask
                            value={letter_another_number}
                            onChange={e=>setLetter_another_number(e.target.value)}
                            placeholder='чужой номер'
                            name='another_number'
                            className={cl.another_number}
                        />
                    </dt>
                </tr>
            </table>
            <MyButton className={cl.but}>Edit</MyButton>
        </form>
    );
};

export default EditLetter;