import React from 'react';

const PrescriptionsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 540 520"
             stroke="currentColor" strokeWidth="2" strokeLinecap="round"
             strokeLinejoin="round" fill="#3EBBC4">
            <g>
                <path
                    d="M442.7,89.6v-49c0-12.4-10.1-22.5-22.5-22.5H91.8c-12.4,0-22.5,10.1-22.5,22.5v49c0,8.9,5.2,16.7,12.8,20.3v350.5   c0,18.5,15,33.5,33.5,33.5h280.8c18.5,0,33.5-15,33.5-33.5V244.1l4.7-4.7c5.2-5.2,8.1-12.1,8.1-19.4c0-7.3-2.9-14.3-8.1-19.4   l-4.7-4.7v-85.8C437.5,106.3,442.7,98.5,442.7,89.6z M84.3,40.6c0-4.1,3.4-7.5,7.5-7.5h328.4c4.1,0,7.5,3.4,7.5,7.5v49   c0,4.1-3.4,7.5-7.5,7.5H91.8c-4.1,0-7.5-3.4-7.5-7.5V40.6z M327.5,468.3V410c0-10.2,8.3-18.5,18.5-18.5h58.3L327.5,468.3z    M396.4,478.9h-58.3l76.8-76.8v58.3C414.9,470.6,406.6,478.9,396.4,478.9z M414.9,376.5H346c-18.5,0-33.5,15-33.5,33.5v68.9H115.6   c-10.2,0-18.5-8.3-18.5-18.5V112.1h317.8v73.5c-9.3-2.7-19.7-0.3-27.1,7l-49.5,49.5h-33.3c-4.7,0-9.1,1.8-12.4,5.1l-9.2,9.2   c-1,1-2.6,1-3.5,0l-5.1-5.1c-6.8-6.8-17.9-6.8-24.7,0l-17.9,17.9c-1,1-2.6,1-3.5,0l-37.1-37.1c-6.8-6.8-17.9-6.8-24.7,0l-44.1,44.1   c-2.9,2.9-2.9,7.7,0,10.6c1.5,1.5,3.4,2.2,5.3,2.2c1.9,0,3.8-0.7,5.3-2.2l44.1-44.1c1-1,2.6-1,3.5,0l37.1,37.1   c6.8,6.8,17.9,6.8,24.7,0l17.9-17.9c0.6-0.6,1.4-0.7,1.8-0.7c0.4,0,1.1,0.1,1.8,0.7l5.1,5.1c3.3,3.3,7.7,5.1,12.4,5.1   c4.7,0,9.1-1.8,12.4-5.1l9.2-9.2c0.5-0.5,1.1-0.7,1.8-0.7h18.3l-75.2,75.2l-6.5,6.5c-2.5,2.5-4.3,5.5-5.4,8.8l-4.8,14.5l-5.8,17.5   c-2.1,6.3-0.5,13.2,4.2,17.9c3.3,3.4,7.8,5.1,12.3,5.1c1.9,0,3.7-0.3,5.6-0.9l17.5-5.8l14.5-4.8c3.3-1.1,6.3-3,8.8-5.4l6.5-6.5   l117.7-117.7l2.3-2.3V376.5z M289.6,363.2L264,337.6l82.6-82.6c0,0,0.1-0.1,0.1-0.1l24.3-24.3l25.6,25.6L289.6,363.2z M259.6,381.9   c-3.4-6-8.4-10.9-14.3-14.3l0.2-0.7l4.8-14.5c0.4-1.1,1-2.1,1.8-2.9l1.2-1.2l25.6,25.6l-1.2,1.2c-0.8,0.8-1.8,1.4-2.9,1.8   L259.6,381.9z"/>
                <path
                    d="M128,221c1.9,0,3.8-0.7,5.3-2.2l44.1-44.1c1-1,2.6-1,3.5,0l37.1,37.1c6.8,6.8,17.9,6.8,24.7,0l17.9-17.9   c0.6-0.6,1.4-0.7,1.8-0.7c0.4,0,1.1,0.1,1.8,0.7l5.1,5.1c6.8,6.8,17.9,6.8,24.7,0l9.2-9.2c0.5-0.5,1.1-0.7,1.8-0.7h53.5   c4.1,0,7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5h-53.5c-4.7,0-9.1,1.8-12.4,5.1l-9.2,9.2c-1,1-2.6,1-3.5,0l-5.1-5.1   c-6.8-6.8-17.9-6.8-24.7,0l-17.9,17.9c-1,1-2.6,1-3.5,0L191.6,164c-3.3-3.3-7.7-5.1-12.4-5.1s-9.1,1.8-12.4,5.1l-44.1,44.1   c-2.9,2.9-2.9,7.7,0,10.6C124.2,220.2,126.1,221,128,221z"/>
            </g>
        </svg>
    );
};

export default PrescriptionsIcon;

