import React from 'react';
import cl from "./EditSections.module.css"
import Select from "react-select";
import {useEffect, useState} from "react";
import global_functions from "../../global_functions";
import Sections from "./Sections";
import Toggle from "react-toggle";
import MyButton from "../../UI/MyButton/MyButton";
import AdministrationAPI from "../../api/AdministrationAPI";
import server_constants from "../../api/server_constants";
import Loading from "../../UI/Loading/loading";
import useStore from "../../Store";

const EditSections = () => {
    const [HousesOption,setHousesOption]=useState([]);
    const [House,setHouse]=useState(null);
    const [QueuesOption,setQueuesOption]=useState([]);
    const [Queue, setQueue]=useState();
    const [SectionsData, setSectionsData]=useState([]);
    const [ApplyFloorsCountToAllSections, setApplyFloorsCountToAllSections]=useState(false);
    const [isLoading, setIsLoading]=useState(true)
    const UserId=useStore((state)=>state.User).Id;
    const AreaId=useStore((state)=>state.AreaId)

    function EditQueuesOption(value){
        setQueue(value);
        setHouse(null);
    }
    function EditHousesOption(value){
        setApplyFloorsCountToAllSections(false)
        setHouse(value);
        server_constants.get_sections(value.value, UserId).then(
            ({data})=>{
                let temp=[];
                data.map(element=>{
                    temp=[...temp,{SectionNumber:Number(element.section_name), FloorsCount:Number(element.floors_count), New:0, SectionId:element.section_id}]
                })
                setSectionsData(temp)
            }
        )
    }

    function AddNewSection(){
        setSectionsData([...SectionsData,{SectionNumber:SectionsData.length+1, FloorsCount:0, New:1}])
    }
    function DeleteNewSection(){
        setSectionsData(SectionsData.slice(0, SectionsData.length-1))
    }
    function onChangeApplyFloorsCountToAllSections(){
        if(ApplyFloorsCountToAllSections===false) {
            let FloorsCountFirstSection=SectionsData[0].FloorsCount;
            setSectionsData(SectionsData.map(element=>{
                return {...element, FloorsCount:FloorsCountFirstSection};
            }));
        }
        setApplyFloorsCountToAllSections(!ApplyFloorsCountToAllSections)
    }

    function onClickSaveButton(event){
        if(House!==null && SectionsData.length>0){
            AdministrationAPI.editSections(House.value, SectionsData, UserId)
                .then(({data})=>{
                    if(data==="OK")alert("Все прошло успешно")
                    setHouse(null);
                    setSectionsData([]);
                    setApplyFloorsCountToAllSections(false);
                });
        }else alert("Необходимо выбрать номер дома!")
    }

    useEffect(()=>{
        global_functions.GetQueues(AreaId, UserId)
            .then(res=>
                setQueuesOption(res));
        },[AreaId])
    useEffect(_=>{
        if(Queue)
            global_functions.GetHouses(AreaId, Queue.value, UserId)
                .then(res=>
                    setHousesOption(res))
    },[AreaId, Queue]);
    if(isLoading)
        return (
            <>
                <div className={cl.container}>
                    <Select
                        value={Queue}
                        className={cl.queue}
                        placeholder="Очередь"
                        options={QueuesOption}
                        onChange={EditQueuesOption}
                    />
                    <Select
                        value={House}
                        className={cl.house}
                        placeholder="Дом"
                        options={HousesOption}
                        onChange={EditHousesOption}
                    />
                </div>
                <div className={cl.PlusMinusContainer}>
                    <div className={cl.Plus} onClick={AddNewSection}>+</div>
                    <div className={cl.Minus} onClick={DeleteNewSection}>-</div>
                </div>
                <div>
                    <h4>Все секции имеют одинаковую этажность</h4>
                    <Toggle
                        checked={ApplyFloorsCountToAllSections}
                        onChange={onChangeApplyFloorsCountToAllSections}
                    />
                </div>
                <Sections
                    SectionsData={SectionsData}
                    setSectionsData={setSectionsData}
                    ApplyFloorsCountToAllSections={ApplyFloorsCountToAllSections}
                />
                {SectionsData.length>0?<MyButton className={cl.button} onClick={onClickSaveButton}>Сохранить</MyButton>:""}
            </>
        );
    else <Loading/>
};

export default EditSections;