import React from 'react';
import projects_server_requests from "../../api/projects_server_requests";
import {useEffect, useState} from "react";
import Select from "react-select";
import cl from "./addProject.module.css";
import CreatableSelect from "react-select/creatable";
import InputMask from "react-input-mask";
import MyButton from "../../UI/MyButton/MyButton";
import Loading from "../../UI/Loading/loading";
import GenerateQR from "../../tests/generateQR";
import work_with_pdf from "../../QR/work_with_pdf";
import useStore from "../../Store";

const AddProject = ({reload, setReload}) => {
    const AreaId=useStore((state)=>state.AreaId)
    const UserId=useStore((state)=>state.User).Id;
    const [isLoading, setIsLoading]=useState(false);
    const [house_option,setHouse_option]=useState([]);
    const [house,setHouse]=useState(null);
    const [project_division,setProject_division]=useState(null);
    const [project_division_options,setProject_division_options]=useState([]);
    const [project_stage,setProject_stage]=useState(null);
    const [project_stage_options,setProject_stage_options]=useState([]);
    const [selectedFile, setSelectedFile]=useState();
    const [notes,setNotes]=useState('');
    const [QR_Image,setQR_Image]=useState(null);
    const [ProjectId, setProjectId]=useState(null)
    const [PDFbased64, setPDFbased64]=useState(null);
    const [FileName, setFileName]=useState(null);
    const [Progress, setProgress]=useState("");

    async function get_houses(AreaId, UserId) {
        let temp=[];
        if (AreaId!=undefined) {
            const res = await projects_server_requests.get_houses(AreaId, "", UserId);
            //          console.log(res);
            res.data.map(el => {
                temp = [...temp, {value: "h"+el.house_id, label: el.house_num}];
            });

            const res_queue =await projects_server_requests.get_queues(AreaId, UserId);
            res_queue.data.map(el => {
                temp = [...temp, {value: "q"+el.queue_id, label: el.queue_name}];
            });
            setHouse_option(temp);
            setIsLoading(true)
        }
    }
    async function get_project_divisions(name=null, UserId) {
        let temp=[];
        const res = await projects_server_requests.get_project_divisions(UserId);
        console.log(res)
        res.data.map(el => {
            temp = [...temp, {value: el.project_division_id, label: el.project_division_name}];
        });
        setProject_division_options(temp);
       if (name) setProject_division(temp.filter(el=> el.project_division_name == name))
    }
    async function get_project_stages(UserId) {
        let temp=[];
        const res = await projects_server_requests.get_project_stages(UserId);
        res.data.map(el => {
            temp = [...temp, {value: el.project_stage_id, label: el.project_stage_name}];
        });
        setProject_stage_options(temp);
    }
    function edit_house_options(value){
        setHouse(value);
    }
    function edit_project_division_option(value){
        setProject_division(value);
    }
    function edit_project_stage_option(value){
        setProject_stage(value);
    }
    async function add_project_stage(name,UserId){
        const res=await projects_server_requests.add_project_stage(name, UserId);
    }
    async function add_project_division(name, UserId){
        const res=await projects_server_requests.add_project_division(name, UserId);
        get_project_divisions(name, UserId);
    }
    function fileSelect(e){
        setSelectedFile(e.target.files[0])
    }
    async function add_project(){
        if(house!=null&&project_division!=null&&project_stage!=null&&typeof(selectedFile)!=undefined) {
            const res=await projects_server_requests.add_project(house.value, house.label, project_division.value, project_division.label, project_stage.value, project_stage.label, notes, selectedFile, UserId);
            setHouse(null);
            setProject_division(null);
            setProject_stage(null);
            setNotes('');
            const el=document.getElementById("file");
            el.value="";

            setProjectId(res.data.project_id);
            setFileName(res.data.file_name)
        }else alert('Необходимо заполнить форму');
    }
    async function AddQRtoPDF(QR_Image, file){
        if(selectedFile){
            setProgress("Добавлям QR-код")
            setPDFbased64(await work_with_pdf.add_qr_to_pdf(QR_Image, 50, file));
        }
    }
    async function send_pdf(Based64Pdf, FileName, ProjectId, UserId){
        const res = await projects_server_requests.add_project_base64(Based64Pdf, FileName, ProjectId, UserId);
        if(res.data==="OK") {alert("Проект успешно добавлен"); setProgress("");}
        setProjectId(null);
        setPDFbased64(null);
    }

    useEffect(()=>{
        get_project_stages(UserId);
    },[])
    useEffect(()=>{
        get_houses(AreaId, UserId)
    },[AreaId]);
    useEffect(()=>{
        get_project_divisions("", UserId);
    },[]);

    useEffect(()=>{
        if(QR_Image) {
            const canvas = document.getElementById('QR_canvas');
            canvas.remove();
            AddQRtoPDF(QR_Image, selectedFile);
            setQR_Image(null);
        }
    },[QR_Image])

    useEffect(()=>{
        if(PDFbased64) {
            setProgress("Отправляем файл на сервер");
            send_pdf(PDFbased64, FileName, ProjectId, UserId);}
        },[PDFbased64])

    if(isLoading)
    return (
        <div>
            <div className={cl.mainDiv}>
                <Select
                    className={cl.house}
                    value={house}
                    options={house_option}
                    onChange={edit_house_options}
                    placeholder="Дом"
                />
                <CreatableSelect
                    onCreateOption={add_project_stage}
                    value={project_stage}
                    className={cl.division}
                    placeholder="Стадия ПД"
                    options={project_stage_options}
                    onChange={edit_project_stage_option}
                />
                <CreatableSelect
                    onCreateOption={add_project_division}
                    value={project_division}
                    className={cl.division}
                    placeholder="Раздел"
                    options={project_division_options}
                    onChange={edit_project_division_option}
                />
                <InputMask
                    className={cl.file}
                    id="file"
                    type="file"
                    onChange={fileSelect}
                />
                <textarea
                    className={cl.notes}
                    value={notes}
                    onChange={e=>setNotes(e.target.value)}
                    placeholder="примечание"
                />
                <MyButton className={cl.button} onClick={add_project}>Отправить</MyButton>
                {
                    ProjectId?
                        <GenerateQR
                            value={"ProjectId-"+ProjectId}
                            setQR_Image={setQR_Image}
                            size={50}
                        />
                        :
                        ""
                }
            </div>
            <div>
                {Progress}
            </div>
        </div>
    );else return <Loading/>;
};

export default AddProject;