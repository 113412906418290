import React, {useState} from 'react';
import cl from "./Sections.module.css"
import InputMask from "react-input-mask";

const Sections = ({setSectionsData, SectionsData, ApplyFloorsCountToAllSections}) => {

    function onChangeSectionFloorsCount(event){
        if(ApplyFloorsCountToAllSections===true){
            setSectionsData(SectionsData.map(element=>{
                return {...element, FloorsCount:event.currentTarget.value.replace(/_/gi,""), Modified:1};
            }));
            return;
        }
        setSectionsData(SectionsData.map(element=>{
            if(element.SectionNumber==event.currentTarget.id) return {...element, FloorsCount:event.currentTarget.value.replace(/_/gi,""), Modified:1};
            return element;
        }));
    }

    return (
        <div>
            {
                SectionsData?.map((element, index)=>{
                    return(
                        <div className={cl.container}>
                            <div className={cl.section}>
                                <p>№ секции: {index+1}</p>
                                <p className={cl.FloorsCount}>Количество этажей:</p>
                                {index===0?
                                    <InputMask
                                        mask="99"
                                        key={index+1}
                                        id={index+1}
                                        onChange={onChangeSectionFloorsCount}
                                        className={cl.input}
                                        value={SectionsData[index]?.FloorsCount}
                                    />:
                                    <InputMask
                                        mask="99"
                                        key={index+1}
                                        disabled={ApplyFloorsCountToAllSections}
                                        id={index+1}
                                        onChange={onChangeSectionFloorsCount}
                                        className={cl.input}
                                        value={SectionsData[index]?.FloorsCount}
                                    />
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
};

export default Sections;