import React from 'react';

const GprIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 34" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" fill="#3EBBC4">
            <path d="M31,30H3v1H2v-1H1v-1h1V1h1v2h1v1H3v1h1v1H3v1h1v1H3v1h1v1H3v1h1v1H3v1h1v1H3v1h1v1H3v1h1v1  H3v1h1v1H3v1h1v1H3v1h1v1H3v1h1v1H3v1h1v1H3v1h5v-1h1v1h6v-1h1v1h6v-1h1v1h6v-1h1v1h1V30z M14.895,21.546l-0.581-0.813l-5.229,3.735  l0.581,0.813L14.895,21.546z M22.135,14.572l-0.707-0.707l-5.578,5.578l0.707,0.707L22.135,14.572z M29.372,4.554l-0.818-0.573  l-5.842,8.345l0.818,0.573L29.372,4.554z"/>
            <path d="M8.5,25C8.776,25,9,25.224,9,25.5S8.776,26,8.5,26S8,25.776,8,25.5S8.224,25,8.5,25z   M8.5,24C7.672,24,7,24.672,7,25.5S7.672,27,8.5,27s1.5-0.672,1.5-1.5S9.328,24,8.5,24L8.5,24z M15.5,20c0.276,0,0.5,0.224,0.5,0.5  S15.776,21,15.5,21S15,20.776,15,20.5S15.224,20,15.5,20z M15.5,19c-0.828,0-1.5,0.672-1.5,1.5s0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5  S16.328,19,15.5,19L15.5,19z M22.5,13c0.276,0,0.5,0.224,0.5,0.5S22.776,14,22.5,14S22,13.776,22,13.5S22.224,13,22.5,13z M22.5,12  c-0.828,0-1.5,0.672-1.5,1.5s0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5S23.328,12,22.5,12L22.5,12z M29.5,3C29.776,3,30,3.224,30,3.5  S29.776,4,29.5,4S29,3.776,29,3.5S29.224,3,29.5,3z M29.5,2C28.672,2,28,2.672,28,3.5S28.672,5,29.5,5S31,4.328,31,3.5  S30.328,2,29.5,2L29.5,2z"/>
        </svg>
    );
};

export default GprIcon;


