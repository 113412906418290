import React from 'react';
import Select from "react-select";
import cl from "./AddHouse.module.css";
import {useEffect, useState} from "react";
import CreatableSelect from "react-select/creatable";
import MyButton from "../../UI/MyButton/MyButton";
import AdministrationAPI from "../../api/AdministrationAPI";
import InputMask from "react-input-mask";
import global_functions from "../../global_functions";
import Loading from "../../UI/Loading/loading";
import useStore from "../../Store";


const AddHouse = () => {
    const [HousesOption,setHousesOption]=useState([]);
    const [House,setHouse]=useState(null);
    const [QueuesOption,setQueuesOption]=useState([]);
    const [Queue, setQueue]=useState();
    const [SectionsCount, setSectionsCount]=useState("")
    const [isLoading, setIsLoading]=useState(true);
    const AreaId=useStore((state)=>state.AreaId)
    const UserId=useStore((state)=>state.User).Id;

    function EditQueuesOption(value){
        setQueue(value);
        setHouse(null)
    }
    function EditHousesOption(value){
        setHouse(value);
    }
    function AddHouseName(value){
       setHouse({value:0, label:value});
    }
    async function AddNewHouse(){
        let SecCount=SectionsCount.replace(/_/gi,"")
        if(SecCount==='')SecCount=0;
        if(House.value===0){
            const res=await AdministrationAPI.AddHouse(AreaId,Queue.value, House.label, SecCount, UserId);
            if(res.data==="OK") alert("Дом успешно добавлен!")
            setQueue(null)
            setHouse(null)
            setSectionsCount("")
        }else{
            alert("Такой дом уже существует!")
        }
    }
    useEffect(()=>{
        global_functions.GetQueues(AreaId, UserId)
            .then(res=>
                setQueuesOption(res));
        },[AreaId])
    useEffect(()=>{
        if(Queue)global_functions.GetHouses(AreaId, Queue.value, UserId)
            .then(res=>
                setHousesOption(res))
    },[Queue]);

    if(isLoading)
        return (
            <div className={cl.container}>
                <Select
                    value={Queue}
                    className={cl.queue}
                    placeholder="Очередь"
                    options={QueuesOption}
                    onChange={EditQueuesOption}
                />
                <CreatableSelect
                    onCreateOption={AddHouseName}
                    value={House}
                    className={cl.house}
                    placeholder="Дом"
                    options={HousesOption}
                    onChange={EditHousesOption}
                />
                <InputMask
                    mask='999'
                    placeholder='Количество секций'
                    value={SectionsCount}
                    onChange={e=>setSectionsCount(e.target.value)}
                    className={cl.SectionsCount}
                />
                <MyButton className={cl.button} onClick={AddNewHouse}>Добавить дом</MyButton>
            </div>
        );
    else <Loading/>
};

export default AddHouse;