import React from 'react';
import InputMask from "react-input-mask";
import MyButton from "../../UI/MyButton/MyButton";
import {useEffect, useState} from "react";
import cl from './AddDocument.module.css'
import useStore from "../../Store";
import CreatableSelect from "react-select/creatable";
import DocumentsServerRequests from "../../api/DocumentsServerRequests";

const AddDocuments = () => {
    const [doc_type, setDoc_type]=useState(1);
    const [document_types, setDocument_types]=useState();
    const [loading,setLoading]=useState(false);
    const [doc_date,setDoc_date]=useState('');
    const [podr_name, setPodr_name]=useState();
    const [notes, setNotes]=useState('');
    const [amount, setAmount]=useState('');
    const [selectedFile,setSelectedFile]=useState();
    const [contractor_option, setContractor_option]=useState([]);
    const [contractor, setContractor]=useState(null);
    const UserId=useStore((state)=>state.User).Id;
    const setAdd_but=useStore((state)=>state.setAddDoc_button)
    const setDoc_loading=useStore((state)=>state.setDoc_loading)


    async function get_document_types(UserId) {
        const res = await DocumentsServerRequests.getDocumentsTypes(UserId);
 //       console.log(res);
        setDocument_types(res.data)
        setLoading(true);
    }
    async function get_contractors(UserId) {
        const temp = await DocumentsServerRequests.getContractors(UserId);
        setContractor_option(temp);
    }
    function edit_option(document_type_id){
        setDoc_type(document_type_id);
    }
    function change_amount(event){
        let str=event.currentTarget.value.replace(/_/gi,"").replace(',','.').replaceAll(" ","");
        let len;
        let str_formated="";

        if (str.indexOf(".") === -1)
            len=3
        else
            len=str.length-str.indexOf(".");

        while(str.length>len){
            let a=str.slice(str.length-len, str.length);

            if (str_formated!="")
                if(str_formated[0]==".")
                    str_formated=a+str_formated
                else
                    str_formated=a+" "+str_formated
            else
                str_formated=a;

            str=str.slice(0,str.length-len);
            len=3;
        }

        if(str_formated!="") str_formated=str+" "+str_formated; else str_formated=str;

        setAmount(str_formated);
    }

    async function add_document(e){
        e.preventDefault();

        if(doc_date=='' || podr_name=='' || amount=='') alert('Необходимо заполнить обязательные поля!!'); else {
            let am = amount.replaceAll(" ", "");
            let contractor_id = contractor.value;
            setDoc_loading(true);
            const d = doc_date.split('-');
            if (contractor.value == 0) contractor_id = contractor.label;
            const res = await DocumentsServerRequests.AddDocument(contractor_id, doc_type, d[2] + "-" + d[1] + "-" + d[0], am, notes, UserId, selectedFile, UserId);
            //      console.log(res);
            setAdd_but(true);
            setDoc_date('');
            setNotes('');
            setPodr_name('');
            setAmount('');
            setContractor(null);
            setDoc_loading(false);
            e.target.reset();
        }
    }
    function fileSelect(e){
        setSelectedFile(e.target.files[0])
    }
    function add_contractor(label){
        setContractor({value:0, label:label})
    }
    function edit_contractor_options(value){
        setContractor({value:value.value, label:value.label})
    }
    useEffect(()=>{
        get_document_types(UserId);
        get_contractors(UserId);
    },[]);
    if(loading)
    return (
            <form onSubmit={add_document} className={cl.div}>
                {/*<select className={cl.sel1} name='doc_type' value={doc_type} onChange={(e)=>edit_option(e.target.value)}>*/}
                {/*  /!*<option>Choose a document type</option>*!/*/}
                {/*    {document_types.map((h)=>{*/}
                {/*        return (<option key={h.document_type_id} value={h.document_type_id}>{h.name}</option>);*/}
                {/*    })*/}
                {/*    }*/}
                {/*</select>*/}
                <InputMask
                    mask='99-99-9999'
                    placeholder='DD-MM-YYYY'
                    value={doc_date}
                    onChange={e=>setDoc_date(e.target.value)}
                    name='doc_date'
                    className={cl.date}
                />
                <InputMask
                    value={amount}
                    onChange={change_amount}
                    placeholder='сумма'
                    name='mount'
                    className={cl.amount}
                />
                <CreatableSelect onCreateOption={add_contractor} value={contractor} className={cl.sel}
                                 placeholder="Подрядчик" options={contractor_option} onChange={edit_contractor_options} />
                {/*<InputMask*/}
                {/*    value={podr_name}*/}
                {/*    onChange={e=>setPodr_name(e.target.value)}*/}
                {/*    placeholder='подрядчик'*/}
                {/*    name='podr_name'*/}
                {/*    className={cl.podr}*/}
                {/*/>*/}
                <InputMask
                    value={notes}
                    onChange={e=>setNotes(e.target.value)}
                    placeholder='краткое описание'
                    name='notes'
                    className={cl.notes}
                />

                <InputMask
                    name="file"
                    type="file"
                    onChange={fileSelect}
                />
                <input type='hidden' name='user_id' value={UserId}/>
                <br/>
                <MyButton className={cl.but}>Add</MyButton>
            </form>
    );
};

export default AddDocuments;