import Add_letter from "./add_letter";
import AddLetterIcon from "../Icons/SubPages/Letters/AddLetterIcon";
import Input from "./Input";
import InputLettersIcon from "../Icons/SubPages/Letters/InputLettersIcon";
import Output from "./Output";
import OutputLettersIcon from "../Icons/SubPages/Letters/OutputLettersIcon";
import React from "react";

export default class Letters{
    static letter={

    }
    static get(){
        return "123";
    }
}