import React from 'react';
import Main from "./main";
import Materials from "./Materials/materials";
import Statistic from "./statistic/statistic";


export const web_routes=[
    {path:'/lp', component:<Main/>, exact: true},
    {path:'/materials', component:<Materials/>, exact: true},
    {path: '/stat', component: <Statistic/>, exact: true}
 //   {path:'/cinema/:show', component: <Cinema_id/>, exact: true},
//    {path: '/cinema', component: <Name_of_buildings/>, exact: true}
]
