import React, {useEffect, useState} from 'react';
import windows_server_requests from "../api/windows_server_requests";
import moment from "moment";
import useStore from "../Store";


const WindowsStatistic = ({house_id}) => {
    const [stat, setStat]=useState();
    const [loading, setLoading]=useState(false);
    const [installed_day, setInstalled_day]=useState(0);
    const UserId=useStore((state)=>state.User).Id;

    async function get_windows_stat(house_id, day_count=5, UserId)
    {
        const res=await windows_server_requests.get_windows_statistic(house_id, day_count, UserId);
        setStat(res.data)
        setLoading(true)
        setInstalled_day(Number(res.data.installed_period/day_count))
    }

    function count_date(day_count){

       let temp=moment().add(day_count, 'days');

       return temp.format("DD.MM.YY");
    }
    useEffect(()=>{
        setLoading(false);
        if(house_id)
            get_windows_stat(house_id, UserId)
    }, [house_id])
    if (loading)
    return (
        <>
            <h5>
                установлено: {stat.installed}
            </h5>
            <h5>
                всего: {stat.total_count}
            </h5>
            <h5>
                {
                    installed_day!=0?
                        "прогноз установки окон: " + count_date(Math.round(Number((stat.total_count-stat.installed)/installed_day)))
                    :"прогноз установки окон: Нужно срочно начинать установку!!!!"
                }
            </h5>
        </>
    );
};

export default WindowsStatistic;