import React, {useEffect, useState} from 'react';
import {Html5Qrcode} from "html5-qrcode";
import cl from "./readQR.module.css"
import {createGlobalStyle} from "styled-components";

const ReadQr = ({setQRMessage}) => {
    const [isEnabled, setIsEnabled]=useState(true)

    const GlobalStyle=createGlobalStyle`
    #qr-shaded-region{
    border-width: 50px !important;
}
    `;

    useEffect(()=>{
        const config={fps:10, qrbox:{width:450, height:450}};
        if(isEnabled)
        var QRCode=new Html5Qrcode("QRCodeContainer");

        const QRScanerStop=()=> {
            if (QRCode && QRCode.isScanning) {
                QRCode.stop();
            }
        }
        const QRCodeSuccess=(DecodedText)=>{
                setQRMessage(DecodedText);
                setIsEnabled(false);
                QRScanerStop();
        }
        if (isEnabled){
            QRCode.start({facingMode:"environment"}, config, QRCodeSuccess);
        }else {
            QRScanerStop()
        }
        return(()=>{
            QRScanerStop()
        });
    },[isEnabled])
    if(isEnabled)
    return (
        <div className={cl.scanner}>
            <GlobalStyle/>
            <div className={cl.QRCodeContainer} id="QRCodeContainer"></div>
        </div>
    );
};

export default ReadQr;