import React, {useEffect, useState} from 'react';
import InputMask from "react-input-mask";
import cl from "./add_letter.module.css";
import CreatableSelect from "react-select/creatable";
import MyButton from "../UI/MyButton/MyButton";
import Select from "react-select";
import useStore from "../Store";
import moment from "moment";
import LettersServerRequests from "../api/LettersServerRequests";

const AddLetter = ({setSelectedSubPage}) => {
    const [letter_date, setLetter_date]=useState(moment().format('DD-MM-YYYY'));
    const [letter_notes, setLetter_notes]=useState('');
    const [letter_from, setLetter_from]=useState(null);
    const [letter_type, setLetter_type]=useState({value:'1', label:'Входящее'});
    const [letter_to, setLetter_to]=useState(null);
    const [letter_companies_option, setLetter_companies_option]=useState([])
    const [isLoading, setIsLoading]=useState(true);
    const [selectedFile,setSelectedFile]=useState();
    const UserId=useStore((state)=>state.User).Id;
    const letter_type_option=[
        {value:1, label:'Входящее'},
        {value:2, label:'Исходящее'}
    ]
    async function get_letter_companies(){
        let temp=[];
        const res = await LettersServerRequests.get_letter_companies(UserId);
        console.log(res)
        res.data.map((el,num)=>{
            temp=[...temp, {value:el.company_id, label:el.company_name}];
        })
        setLetter_companies_option(temp);
    }
    function add_letter_from(value){
         setLetter_from({value:'0', label:value});
    }
    function add_letter_to(value){
          setLetter_to({value:'0', label:value});
    }
    function edit_from_options(value){
        setLetter_from(value);
    }
    function edit_to_options(value){
        setLetter_to(value);
    }
    function edit_letter_type_options(value){
        setLetter_type(value);
    }
    function fileSelect(e){
        setSelectedFile(e.target.files[0])
    }
    async function add_letter(e){
        e.preventDefault();
        let to=letter_to.value, from=letter_from.value;
        if(letter_to.value!==undefined&&letter_from.label!==undefined) {
            const d = letter_date.split('-');
            if(letter_to.value==0) to=letter_to.label;
            if(letter_from.value==0) from=letter_from.label;
            const res = await LettersServerRequests.send_letter_file_to_server(letter_type.value, from, to, d[2] + "-" + d[1] + "-" + d[0], selectedFile, letter_notes, UserId);
            setLetter_from(null);
            setLetter_to(null);
            setLetter_notes("");
            setSelectedFile(null);
            const el=document.getElementById("file");
            el.value="";
            if(letter_type.value==1) setSelectedSubPage("Входящие"); else setSelectedSubPage("Исходящие");
            alert("Письмо добавлено");
        }else alert('Заполните графы "от кого" и "кому"');
    }

    useEffect(()=>{get_letter_companies()},[])

    if (isLoading)
        return (
            <form className={cl.container} onSubmit={add_letter}>
                <InputMask
                    mask='99-99-9999'
                    placeholder='DD-MM-YYYY'
                    value={letter_date}
                    onChange={e=>setLetter_date(e.target.value)}
                    name='doc_date'
                    className={cl.date}
                />
                <Select options={letter_type_option} className={cl.select} value={letter_type} onChange={edit_letter_type_options}/>
                <CreatableSelect onCreateOption={add_letter_from} value={letter_from} className={cl.select}
                                 placeholder="от Кого" options={letter_companies_option} onChange={edit_from_options} />
                <CreatableSelect onCreateOption={add_letter_to} value={letter_to} className={cl.select}
                                 placeholder="Кому" options={letter_companies_option} onChange={edit_to_options} />

                <InputMask
                    value={letter_notes}
                    onChange={e=>setLetter_notes(e.target.value)}
                    placeholder='Примечание'
                    name='notes'
                    className={cl.notes}
                />
                <br/>
                <InputMask
                    id="file"
                    type="file"
                    onChange={fileSelect}
                    className={cl.file}
                />
                <br/>
                <MyButton className={cl.but}>Save</MyButton>
            </form>
        );
};

export default AddLetter;