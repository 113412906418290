import React from 'react';
import cl from "../../../SubPagesPanel/SubPagesPanel.module.css"
const AddDocumentIcon = () => {
    return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" strokeLinejoin="round" strokeWidth="2"
             viewBox="0 0 748 748">

            <g className="layer">
                <title>Layer 1</title>
                <rect height="34" id="svg_2" width="238" x="136" y="612"/>
                <polygon id="svg_3"
                         points="612,612 612,646 680,646 680,0 170,0 170,68 204,68 204,34 646,34 646,612   "/>
                <rect height="34" id="svg_4" width="374" x="136" y="510"/>
                <path
                    d="m68,109.14l0,631.72c0,3.94 3.13,7.14 7,7.14l495.96,0c3.91,0 7.04,-3.16 7.04,-7.14l0,-631.72c0,-3.94 -3.13,-7.14 -7,-7.14l-496,0c-3.87,0 -7,3.16 -7,7.14zm34,26.86l442,0l0,578l-442,0l0,-578z"
                    id="svg_5"/>
                <rect height="34" id="svg_6" width="374" x="136" y="204"/>
                <rect height="34" id="svg_7" width="374" x="136" y="306"/>
                <rect height="34" id="svg_8" width="374" x="136" y="408"/>
            </g>
            <g className="layer">
                <g fill="red"  id="svg_1">
                    <path strokeWidth="30"
                        d="m393.5,167.00001a213.5,211.49999 0 1 0 213.5,211.49999a213.73352,211.73132 0 0 0 -213.5,-211.49999zm0,403.17186a193.48437,191.67187 0 1 1 193.48437,-191.67187a193.71789,191.9032 0 0 1 -193.48437,191.67187z"
                        id="svg_9"/>
                    <path
                        d="m480.23438,368.58594l-76.72656,0l0,-76.00781a10.00781,9.91406 0 0 0 -20.01562,0l0,76.00781l-76.72656,0a10.00781,9.91406 0 0 0 0,19.82812l76.72656,0l0,76.00781a10.00781,9.91406 0 0 0 20.01562,0l0,-76.00781l76.72656,0a10.00781,9.91406 0 0 0 0,-19.82812z"
                        id="svg_10"/>
                </g>
            </g>

        </svg>
    );
};

export default AddDocumentIcon;