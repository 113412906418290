import axios from "axios";
import server_constants from "./server_constants";
import global_functions from "../global_functions";
export default class executive_documentation_server_requests {
    static url=this.checkUrl();
    static login = server_constants.login;
    static psw = server_constants.psw;

    static checkUrl(){
        let LocalUrl="http://127.0.0.1:8000/executiveDocumentation_api.php"
        let Url = server_constants.global_url+'executiveDocumentation_api.php';
        if(window.location.href.indexOf('localhost')!==-1) return LocalUrl;
        return Url;
    }

    static async getExecutiveDocumentation(HouseId, UserId){
        return await axios.get(this.url, {
            params: {
                action: 'getExecutiveDocumentation',
                HouseId: HouseId,
                UserId:UserId,
                login: this.login,
                password: this.psw
            }
        });
    }
    static async addExecutiveDocumentation(HouseId, Notes, file, WorkId, WorkName, ContractorId, ContractorName){
        const formData=new FormData();
        formData.append('Action','addExecutiveDocumentation')
        formData.append('HouseId',HouseId)
        formData.append('WorkId',WorkId)
        formData.append('WorkName',WorkName)
        formData.append('ContractorId',ContractorId)
        formData.append('ContractorName',ContractorName)
        formData.append('Notes',Notes)
        formData.append('file', file);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        return axios.post(this.url, formData);
    }
}