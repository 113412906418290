import React from 'react';
import {useState, useEffect} from "react";
import CreatableSelect from "react-select/creatable";
import cl from "./AddArea.module.css";
import MyButton from "../../UI/MyButton/MyButton";
import AdministrationAPI from "../../api/AdministrationAPI";
import global_functions from "../../global_functions";
import Loading from "../../UI/Loading/loading";
import useStore from "../../Store";

const AddArea = () => {
    const [AreasOption,setAreasOption]=useState([]);
    const [Area, setArea]=useState();
    const [IsLoading, setIsLoading]=useState(false);
    const UserId=useStore((state)=>state.User).Id;

    function EditAreasOption(value){
        setArea(value);
    }
    function AddAreaName(value){
        setArea({value:0, label:value});
    }
    async function AddNewArea(){
        if(Area.value===0){
            const res=await AdministrationAPI.AddArea(Area.label, UserId);
            if(res.data==="OK") alert("Район успешно добавлен!")
            setArea(null)
        }else{
            alert("Такой район уже существует!")
        }
    }
    useEffect(()=>{
        global_functions.GetArea(setAreasOption, setArea, UserId)
            .then(_=>setIsLoading(true));
        },[])

    if (IsLoading)
        return (
            <div className={cl.container}>
                <CreatableSelect
                    onCreateOption={AddAreaName}
                    value={Area}
                    className={cl.area}
                    placeholder="Район"
                    options={AreasOption}
                    onChange={EditAreasOption}
                />
                <MyButton className={cl.button} onClick={AddNewArea}>Добавить район</MyButton>
            </div>
        );
    else <Loading/>
};

export default AddArea;