import React, {useRef} from 'react';
import {useEffect, useState} from "react";
import ServerRequests from "../../api/ServerRequests";
import cl from "./executive_documetntation_add.module.css";
import Select from "react-select";
import InputMask from "react-input-mask";
import MyButton from "../../UI/MyButton/MyButton";
import global_functions from "../../global_functions";
import mapServerRequests from "../../api/mapServerRequests";
import executive_documentation_server_requests from "../../api/executive_documentation_requests";
import useStore from "../../Store";


const ExecutiveDocumentationAdd = () => {
    const [house_option,setHouse_option]=useState([]);
    const [house,setHouse]=useState(null);
    const [isLoading, setIsLoading]=useState(false);
    const [notes,setNotes]=useState('');
    const [selectedFile, setSelectedFile]=useState();
    const [QueuesOption,setQueuesOption]=useState([]);
    const [contractors,setContractors]=useState([]);
    const [contractor,setContractor]=useState([]);
    const [Queue, setQueue]=useState();
    const [works, setWorks]=useState();
    const [work, setWork]=useState();
    const [fileLoadingStatus, setFileLoadingStatus]=useState("");
    const UserId=useStore((state)=>state.User).Id;
    const AreaId=useStore((state)=>state.AreaId)
    async function addExecutiveDocumentation(){
        if(house) {
            setFileLoadingStatus("Отправляю файл на сервер.")
            executive_documentation_server_requests.addExecutiveDocumentation(house.value, notes, selectedFile, work.value, work.label, contractor.value, contractor.label, UserId)
                .then(res=> {
                    console.log(res)
                    if(res.data==='OK') {
                        const abc=()=>setFileLoadingStatus("");
                        setFileLoadingStatus("Файл успешно загружен!")
                        setTimeout(abc,2000);
                    }
                });
            setIsLoading(false);
            setSelectedFile(null);
            const el=document.getElementById("file");
            el.value="";
            setNotes('');
            setIsLoading(true);
        }
    }
    function EditQueuesOption(value){
        setQueue(value);
        setHouse(null);
    }
    function edit_house_options(value){
        setHouse(value);
    }
    function editWorkOptions(value){
        setWork(value);
    }
    function editContractorOptions(value){
        setContractor(value);
    }
    function fileSelect(e){
        setSelectedFile(e.target.files[0])
    }

    useEffect(()=>{
        global_functions.GetQueues(AreaId, UserId)
            .then(res=>
                setQueuesOption(res)
            );},[AreaId])
    useEffect(()=>{
        if(Queue)
            global_functions.GetHouses(AreaId, Queue.value, UserId)
                .then(res=>
                    setHouse_option(res))
    },[AreaId, Queue]);
    useEffect(()=>{
        mapServerRequests.getMapWorks(UserId)
            .then(res=>{
                let temp=[];
                res.data.map((el)=>{
                    temp=[...temp, {value:el.map_work_id, label:el.map_work_name}];
                })
                setWorks(temp)
            })
    },[]);
    useEffect(()=>{
        console.log(UserId)
        global_functions.GetContractors(UserId)
            .then(res=>
                setContractors(res))
    },[]);


    if(isLoading) {
        return (
            <div>
                <div className={cl.mainDiv}>
                    <Select
                        value={Queue}
                        className={cl.queue}
                        placeholder="Очередь"
                        options={QueuesOption}
                        onChange={EditQueuesOption}
                    />
                    <Select
                        value={house}
                        className={cl.house}
                        options={house_option}
                        onChange={edit_house_options}
                        placeholder={"Дом"}
                    />
                    <Select
                        value={contractor}
                        className={cl.work}
                        options={contractors}
                        onChange={editContractorOptions}
                        placeholder={"Подрядчик"}
                    />
                    <Select
                        value={work}
                        className={cl.work}
                        options={works}
                        onChange={editWorkOptions}
                        placeholder={"Работы"}
                    />
                </div>
                <div className={cl.mainDiv}>
                    <InputMask
                        value={notes}
                        onChange={e=>setNotes(e.target.value)}
                        placeholder='краткое описание'
                        name='notes'
                        className={cl.notes}
                    />
                    <InputMask
                        id="file"
                        name="file"
                        type="file"
                        className={cl.file}
                        onChange={fileSelect}
                    />
                    <MyButton  onClick={addExecutiveDocumentation} className={cl.button}>Добавить</MyButton>
                </div>
                {fileLoadingStatus}
            </div>
        );
    }
};

export default ExecutiveDocumentationAdd;