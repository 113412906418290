import React, {useEffect, useState} from 'react';
import cl from './PagePanel.module.css'
import useStore from "../Store";
import LettersIcon from "../Icons/Pages/LettersIcon";
import DocumentsIcon from "../Icons/Pages/DocumentsIcon";
import MaterialsIcon from "../Icons/Pages/MaterialsIcon";
import GprIcon from "../Icons/Pages/GprIcon";
import RequestsIcon from "../Icons/Pages/RequestsIcon";
import ProjectsIcon from "../Icons/Pages/ProjectsIcon";
import ExecutiveDocumentationIcon from "../Icons/Pages/ExecutiveDocumentationIcon";
import PrescriptionsIcon from "../Icons/Pages/PrescriptionsIcon";
import WindowsIcon from "../Icons/Pages/WindowsIcon";
import SettingsIcon from "../Icons/Pages/SettingsIcon";
import MapIcon from "../Icons/Pages/MapIcon";
import server_constants from "../api/server_constants";
import AdministrationAPI from "../api/AdministrationAPI";
import Loading from "../UI/Loading/loading";
import global_functions from "../global_functions";
import Select from "react-select";
import Letters from "../Letters/letters";
import EconomicsIcon from "../Icons/Pages/EconomicsIcon";

const PagePanel = ({setPage,setSubPages, setSubPage}) => {
    const setIsAuth=useStore((state)=>state.setAuth)
    const User=useStore((state)=>state.User)
    const [AreasOption,setAreasOption]=useState([]);
    const [Area,setArea]=useState([]);
    const AreaId=useStore((state)=>state.AreaId)
    const setAreaId=useStore((state)=>state.AreaId)
    const [Pages, setPages]=useState([]);
    const [IsLoading, setIsLoading]=useState(false)
    const [SelectedPage, setSelectedPage]=useState(0)

    const Icons={
        "Письма":{Icon:<LettersIcon/>, Page:Letters.get()},
        "Документы":{Icon:<DocumentsIcon/>, Page:""},
        "Материалы":{Icon:<MaterialsIcon/>, Page:""},
        "ГПР":{Icon:<GprIcon/>, Page:""},
        "Заявки":{Icon:<RequestsIcon/>, Page:""},
        "Проекты":{Icon:<ProjectsIcon/>, Page:""},
        "Исполнительная":{Icon:<ExecutiveDocumentationIcon/>, Page:""},
        "Предписания":{Icon:<PrescriptionsIcon/>, Page:""},
        "Окна":{Icon:<WindowsIcon/>, Page:""},
        "Доп.соглашения":{Icon:"", Page:""},
        "Testing":{Icon:"", Page:""},
        "Экономика":{Icon:<EconomicsIcon/>, Page:""},
        "Настройки":{Icon:<SettingsIcon/>, Page:""},
        "Карта":{Icon:<MapIcon/>, Page:""},
        "Подрядчики":{Icon:"", Page:""}
    }

 function exit(){
     localStorage.removeItem('SubPages');
     setIsAuth(false);
 }

    function Group(data){
        let result_array=[];
        let result = data.reduce((acc=[], item) => {
            let date = item.page_name;
            if (acc[date]) {
                acc[date].push(item)
            } else {
                acc[date] = [item]
            }
            return acc
        }, {})
        result_array=Object.entries(result)
        return result_array;
    }
    function GetSubPagesFromArray(arr, name){
        let result=[];
        arr.map(el=>{
            if(el[0]==name){
                result=[...el[1]]
            }
        })
        return result;
    }
    async function GetSubPages(SubPages){
        const res=await server_constants.get_sub_pages(SubPages)
        setPages(Group(res.data));

        const result=await AdministrationAPI.getPages();
        setIsLoading(true)
    }
    const onPageChange = (id) => {
        setSelectedPage(id)
        setSubPages(GetSubPagesFromArray(Pages, id));
        setSubPage(0);
    }


    function EditAreasOption(value){
        setArea(value);
        setAreaId(value.value)
    }

    useEffect(()=>{
        global_functions.GetArea(setAreasOption, setArea, User.Id);
        GetSubPages(User.SubPages)
    },[]);

    if (IsLoading)
        return (
            <>
                <div className={cl.RoofContainer}>
                    <Select
                        value={Area}
                        className={cl.area}
                        options={AreasOption}
                        onChange={EditAreasOption}
                    />
                    <div className={cl.Fio}>{User.Fio}</div>
                    <div key={123} className={cl.exit} onClick={exit} id="PagePanel">X</div>
                </div>
                <div className={cl.main}>
                {Pages.map(element => {
                            const rootClasses=[cl.navigation_panel];
                            if (element[0] == SelectedPage) {rootClasses.push(cl.selected);}
                            return(
                                <div
                                    id={element[0]}
                                    key={element.id}
                                    className={rootClasses.join(' ')}
                                    onClick={event => {
                                        onPageChange(event.currentTarget.id);
                                    }}
                                >
                                    <div className={cl.container}  key={element[0]}>
                                        {Icons[element[0]].Icon}
                                        {element[0]}
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>
            </>

        );
    else <Loading/>
};

export default PagePanel;