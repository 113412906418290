import React from 'react';

const RequestsSupplyDepartment = () => {
    async function get_requests(){

    }
    return (
        <>
            
        </>
    );
};

export default RequestsSupplyDepartment;