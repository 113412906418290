import axios from "axios";
import server_constants from "./server_constants";
import global_functions from "../global_functions";
export default class mapServerRequests {
    static url = server_constants.global_url+'map_api.php';
    static login = server_constants.login;
    static psw = server_constants.psw;

    static async getAllSections(areaId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getAllSections',
                areaId:areaId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async getAllSectionsDate(areaId, date, UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getAllSectionsDate',
                areaId:areaId,
                UserId:UserId,
                date:date,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async getMapWorks(UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getMapWorks',
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async getSectionPercent(sectionId, UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getSectionPercent',
                sectionId:sectionId,
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async getHousePercent(houseId, UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getHousePercent',
                houseId:houseId,
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async getDates(UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getDates',
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async addSectionPercent(sectionId, sectionPercent, workId, date, UserId){
        const formData=new FormData();
        formData.append('sectionId',sectionId);
        formData.append('sectionPercent',sectionPercent);
        formData.append('workId',workId);
        formData.append('date',date);
        formData.append('UserId',UserId);
        formData.append('action','addSectionPercent')
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=axios.post(this.url, formData);
        return responce;
    }
}