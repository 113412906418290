import React from 'react';
import useStore from "../../Store";
import {useEffect, useState} from "react";
import cl from "./pto_documents.module.css";
import InputMask from "react-input-mask";
import Loading from "../../UI/Loading/loading";
import DocumentsServerRequests from "../../api/DocumentsServerRequests";

const ShowDocuments = () => {

    const add_but=useStore((state)=>state.addDoc_button)
    const setAdd_but=useStore((state)=>state.setAddDoc_button)
    const addDoc_loading=useStore((state)=>state.addDoc_loading)
    const [isLoad,setIsLoad]=useState(false);
    const [file, setFile]=useState();
    const [documents, setDocuments]=useState([]);
    const UserId=useStore((state)=>state.User).Id;
    const AreaId=useStore((state)=>state.AreaId)

    function format_amount(amount){
       return (amount)
        let str=amount.replace(',','.').replaceAll(" ","");
        let len=str.length-str.indexOf("."), str_formated="";
        if (str.indexOf(".")==-1) len=3;
        while(str.length>len){
            let a=str.slice(str.length-len, str.length);
            if (str_formated!="") str_formated=a+" "+str_formated; else str_formated=a;
            str=str.slice(0,str.length-len);
            len=3;
        }
        if(str_formated!="") str_formated=str+" "+str_formated; else str_formated=str;
        return(str_formated);
    }

    async function getDocumentsPto(UserId){
        const res=await DocumentsServerRequests.getDocumentsPto(UserId);
 //       console.log(res);
        setDocuments(res.data);
        setIsLoad(true);
        setAdd_but(false);
    }
    function select_file(e){
        setFile(e.target.files[0])
    }
    function show_file(e){
        window.open(e.currentTarget.id)
    }
    async function add_document_file(e){
        setIsLoad(false);
        if(file) {
            const document_id = e.currentTarget.id;
            const res = DocumentsServerRequests.add_document_file(document_id, file, UserId);
            setFile(null)
            e.currentTarget.value='';
            getDocumentsPto(UserId);
        }else alert("Необходимо выбрать файл.")

    }
    async function transmit_doc(e){
        if(window.confirm("Are YOU sure?")) {
            const cur_doc = documents.filter(doc => doc[0] == e.currentTarget.id)
            //       console.log(cur_doc);
            const route = cur_doc[0].route.split(':');
            //       console.log(route);
            const now_status = route.indexOf(cur_doc[0].status)
            const a=now_status+1<cur_doc.length;

            if(now_status+1<route.length) {
                const res = await DocumentsServerRequests.insert_status(cur_doc[0][0], route[now_status + 1], UserId)
                setAdd_but(true);
            }
        }
    }
  //  console.log(manager);
    useEffect(()=>{
        getDocumentsPto(UserId)
    },[add_but])
    if(isLoad && !addDoc_loading)
        return (
            <div className={cl.mainDiv}>
                <table className={cl.table}>
                    <th>Тип документа</th>
                    <th>Дата</th>
                    <th>Сумма</th>
                    <th>Подрядчик</th>
                    <th>Описание</th>
                    <th>Ссылка на файл</th>
                    <th>Передать документы</th>
                    <th>История</th>
                    {
                        documents[0]&&documents.map((doc)=>{

                            let d=doc.document_date.split("-");
                            let storage_status=cl.storage_highlight_bad;
                            let s=doc.files.split(';');
                            d=d[2]+"."+d[1]+"."+d[0];
                            if (!(doc.st_conf_id!==null)){storage_status=cl.storage_highlight_good}
                            let storage_confirmation=[];
                            if(doc.storage_confirmation_history) storage_confirmation=doc.storage_confirmation_history.split('$');
                            return(<tr className={storage_status}>
                                <td>{doc.name}</td>
                                <td>{d}</td>
                                <td>{format_amount(doc.amount)}</td>
                                <td>{doc.contractor_name}</td>
                                <td>{doc.notes}</td>
                                <td>
                                {
                                 s.map((file, index)=>{
                                        return( <div id={file} onClick={show_file} className={cl.highlight}>Download File {index+1}</div>);
                                })
                                }
                                <InputMask
                                        id="file"
                                        name="file"
                                        type="file"
                                        onChange={select_file}
                                />
                                    <div id={doc.id} className={cl.highlight} onClick={add_document_file}>загрузить</div>
                                </td>
                                <td id={doc.id} className={cl.highlight} onClick={transmit_doc}>Передать документы </td>
                                <td>
                                    {storage_confirmation[0]?
                                        <details id={doc.id}>
                                            <summary>Склад</summary>
                                            {storage_confirmation[0]&&storage_confirmation.map(el=>{
                                                let data=el.split('#')
                                                return(
                                                    <div>
                                                        {data[0]}  &nbsp;
                                                        {el.storage_confirmation_notes} &nbsp; &nbsp;
                                                        <a target="_blank" href={data[1]}>файл</a>
                                                        <br/>
                                                        <br/>
                                                    </div>
                                                );
                                            })}
                                        </details>
                                        :""}
                                </td>
                            </tr>)
                        })
                    }
                </table>
            </div>
        );else return <Loading/>;
};

export default ShowDocuments;