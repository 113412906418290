import React from 'react';
import cl from "./BudgetRows.module.css";
import MyButton from "../../../UI/MyButton/MyButton";
import global_functions from "../../../global_functions";

const BudgetRows = ({BudgetRowsData, setBudgetRowsData, setOnClickSaveButton}) => {
    function onClickSaveButton(){
        setOnClickSaveButton(true);
    }
    function addBudgetRow(){
        setBudgetRowsData(
            [...BudgetRowsData, {BudgetRowId:BudgetRowsData.length+2, BudgetRowName:"", BudgetRowAmount:0, New:1}]
        );
    }
    function deleteBudgetRow(event){
        let temp=[]
        BudgetRowsData.map(
            element=>{
                if(element.BudgetRowId == event.currentTarget.id)
                    {
                        if(!element.hasOwnProperty('New')) temp=[...temp, {...element, Deleted:1}];
                    }
                else
                    temp=[...temp,element];
                console.log(temp)
            });
        setBudgetRowsData(temp);
    }
    function changeBudgetRowName(event){
        setBudgetRowsData(BudgetRowsData.map(
            element=>{
                if(element.BudgetRowId == event.currentTarget.id) return {...element, BudgetRowName:event.currentTarget.value.replace(/_/gi,""), Edited:1};
                return element;
            }));
    }
    function changeBudgetRowAmount(event){
        if("1234567890.".indexOf(event.currentTarget.value[event.currentTarget.value.length-1])!=-1 || event.currentTarget.value === "")
        setBudgetRowsData(BudgetRowsData.map(
            element=>{
                if(element.BudgetRowId == event.currentTarget.id) return {...element, BudgetRowAmount:global_functions.convertAmountString(event.currentTarget.value.replace(/_/gi,"").replace(',','.').replaceAll(" ","")), Edited:1};
                return element;
            }));
    }

    console.log(BudgetRowsData)

    return (
        <>
            <div>
                <div className={cl.PlusMinusContainer}>
                    <div>
                        <p>Добавить строку</p>
                        <div className={cl.RowsActions} onClick={addBudgetRow}>+</div>
                    </div>
                </div>
            </div>
            { BudgetRowsData.length>0?
                <>
                    <table>
                        <th>Строка бюджета</th>
                        <th>Сумма</th>
                        <th></th>
                        {
                            BudgetRowsData[0] && BudgetRowsData.map((element) => {
                                if(element.Deleted!=1)
                                return (
                                    <tr key={element.BudgetRowId}>
                                        <td>
                                            <input
                                                value={element.BudgetRowName}
                                                id={element.BudgetRowId}
                                                onChange={changeBudgetRowName}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                value={element.BudgetRowAmount}
                                                id={element.BudgetRowId}
                                                onChange={changeBudgetRowAmount}
                                            />
                                        </td>
                                        <td>
                                            <div id={element.BudgetRowId} className={cl.DeleteRow}
                                                 onClick={deleteBudgetRow}>удалить
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                    <MyButton onClick={onClickSaveButton}>Сохранить</MyButton>
                </>:""
            }
        </>
    );
};

export default BudgetRows;