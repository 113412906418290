import React, {useState} from 'react';
import RequestsContractor from "./requests_contractor/requests_contractor";
import RequestsSupplyDepartment from "./requests_supply_department/requests_supply_department";

const Requests = () => {
    return(
        <>
         <RequestsContractor/>
         <RequestsSupplyDepartment/>
        </>
    );
};

export default Requests;