import React from 'react';
import {useEffect, useState} from "react";
import cl from "../windows.module.css";
import MyButton from "../../UI/MyButton/MyButton";
import windows_server_requests from "../../api/windows_server_requests";
import AddWindowsLocation from "./AddWindowsLocation";
import useStore from "../../Store";

const EditWindows = () => {
    var startPosX,startPosY, newDiv, selected_div;
    var corrections=[];
    const [main_div_coord, setMain_div_coord]=useState();
    const [id,setId]=useState(1);
    const [divs, setDivs]=useState([]);
    const [obl, setObl]=useState();
    const [location_id, setLocation_id]=useState();
    const [location, setLocation]=useState();
    const [loading, setLoading]=useState(false);
    const [house_id, setHouse_id]=useState();
    const UserId=useStore((state)=>state.User).Id;

    function mouse_click(event) {
        //если попали в див
        let in_div=false;
        let selection=false;
        divs.map(el=>{
            let div_x=Number(el.x)+Number(main_div_coord.x);
            let div_width=Number(el.width.slice(0,-2));
            let div_y=Number(main_div_coord.y)+Number(el.y);
            let div_height=Number(el.height.slice(0,-2));
            if(
                ((div_x < event.clientX) && ((div_x+div_width) > event.clientX))&&
                ((div_y < event.pageY) && ((div_y+div_height) > event.pageY))
            )
            {
                obl.addEventListener("mousemove", moveDiv);
                in_div = true;
                if(el.selected==1) selection=true;
                else {
                    //для каждого дива счтаем разницу координат для перемещения
                    corrections = [{
                        id: el.id,
                        corr_x: event.clientX - (div_x),
                        corr_y: event.clientY - (div_y)
                    }]
                    selected_div = el.id;
                }
            }
        })
        if(selection){
            corrections=[];
            divs.map(el=>{
                let div_x=Number(el.x);
                let div_y=Number(el.y);
                if(el.selected==1){
                    corrections=[...corrections,{
                        id: el.id,
                        corr_x: event.clientX - (div_x+main_div_coord.x),
                        corr_y: event.clientY - (div_y+main_div_coord.y)
                    }]
                }
            })
        }
        // если не попапали рисуем
        if (!in_div){
            newDiv = document.createElement("div");
            newDiv.style.position = "absolute";
            newDiv.style.backgroundColor = "red";
            newDiv.style.pointerEvents = "none";
            newDiv.style.opacity = "0.5";
            // Проверяем, что клик был совершен левой кнопкой мыши
            if (event.buttons !== 1) return;
            // Устанавливаем начальные координаты элемента div
           // console.log(window.pageYOffset);
            let a=event.clientY+window.pageYOffset;
            newDiv.style.left = (startPosX=event.clientX) + "px";
            newDiv.style.top = (startPosY=a)  + "px";
            newDiv.style.width = newDiv.style.height = "0px";
            // Добавляем элемент div на страницу
            obl.appendChild(newDiv);
            // Добавляем обработчик движения мыши с зажатой левой кнопкой
            obl.addEventListener("mousemove", drawDiv);
        }
    }
// Function for div movement
    function moveDiv(event) {
            //передвигаем дивы
            if (event.buttons === 1) {
                corrections.map(el => {
                    const moved_div = document.getElementById(el.id);
                    moved_div.style.top = event.clientY - Number(el.corr_y) + "px";
                    moved_div.style.left = event.offsetX - Number(el.corr_x) + "px";
                });
            } else {
                obl.removeEventListener("mousemove", moveDiv);
                let temp=divs.map(el=>{
                        const moved_div = document.getElementById(el.id);
                    //Check the element movement
                    if(Math.round(el.x)==Math.round(moved_div.style.left.slice(0,-2)-main_div_coord.x)&&Math.round(el.y)==Math.round(moved_div.style.top.slice(0,-2)-main_div_coord.y)) return el
                    else
                        return {...el,  x:(moved_div.style.left.slice(0,-2)-main_div_coord.x), y: (moved_div.style.top.slice(0,-2)-main_div_coord.y)}
                });
                setDivs(temp);
            }
    }
// Функция для рисования элемента div
    function drawDiv(event) {
        const obl = document.getElementById("100");

        if (event.shiftKey){
            newDiv.style.backgroundColor = "lightgreen";
            newDiv.style.opacity = "0.5";
        }
        // Проверяем, что левая кнопка мыши все еще зажата
        if (event.buttons === 1) {
            // Изменяем размеры элемента div в соответствии с положением мыши
            if (event.offsetX > startPosX) {
                newDiv.style.width = event.offsetX - startPosX + "px";
            } else {
                newDiv.style.left = event.offsetX + "px";
                newDiv.style.width = startPosX-event.offsetX + "px";
            }
            if (event.y + window.pageYOffset > startPosY) {
                newDiv.style.height = event.y + window.pageYOffset - startPosY + "px";
            } else {
                newDiv.style.top = event.clientY + window.pageYOffset + "px";
                newDiv.style.height = startPosY + window.pageYOffset - event.clientY + "px";
            }
        } else {
            // Если левая кнопка мыши отпущена, удаляем обработчик движения мыши
            obl.removeEventListener("mousemove", drawDiv);
            if(event.shiftKey) {
                obl.removeChild(newDiv);
                let temp=divs.map(el=>{
                    if (Number(el.x)+main_div_coord.x > startPosX && Number(el.x)+main_div_coord.x < (startPosX)+Number(newDiv.style.width.slice(0,-2)) &&
                        (Number(el.y)+main_div_coord.y > startPosY && Number(el.y)+main_div_coord.y < (startPosY)+Number(newDiv.style.height.slice(0,-2)))
                        )
                        return  {...el, selected:1}
                    else return {...el, selected:0}
                });
                setDivs(temp);
            }
            else
            if(newDiv.style.height!='0px') {
                setDivs([...divs, {id:'new_window'+id,selected:0, x:startPosX-main_div_coord.x, y:startPosY-main_div_coord.y, height:newDiv.style.height, width:newDiv.style.width}]);
                setId(id+1)
            }
        }
    }
    function add_divs(event){
        let copy=[]
        let temp_id=id;
        let ddd=divs.map(el=>{
            if (el.selected==1){
                copy=[...copy, {...el,  id:'new_window'+temp_id, selected:1}]
                temp_id++;
                return {...el, selected:0}
            } return el;
        })
        setId(temp_id);
       setDivs([...ddd, ...copy])
    }
    function delete_div(event){
        let temp=[];
        if (event.key=='Delete'){
            divs.map(el=>{
                if(el.selected==0) temp=[...temp,el];
            });
  //          console.log(temp)
            setDivs(temp);
        }
    }
    async function save_to_server(){
        const res=await windows_server_requests.add_windows(house_id, location_id, divs, UserId);
        console.log(res);
        if(res.data=='OK') alert("Окна добавлены")
        get_location();
        get_windows(location_id);
    }
    async function get_windows(location_i, UserId){
        let temp=[];
        const res=await windows_server_requests.get_windows(location_id, UserId);
        temp=res.data.map(el=>{
            return {id:'window'+el.win_id,selected:0, x:el.x, y:el.y, height:el.height, width:el.width};
        });
        setDivs(temp);
    }
    async function get_location(location_id, UserId){
        const res=await windows_server_requests.get_location(location_id, UserId);
        setLocation(res.data);
        setLoading(true);
    }
    useEffect(()=>{
        if(location_id)
            get_location(location_id, UserId)
    }, [location_id])
    useEffect(()=>{
        setObl(document.getElementById("100"));
        },[])
    useEffect(()=>{
        if(obl&&location[0]) {
            obl.style.backgroundImage = "url('" + location[0].windows_location_file_path + location[0].windows_location_file + "')";
            setMain_div_coord({x:obl.getBoundingClientRect().x, y:obl.getBoundingClientRect().y})
            get_windows(location[0].windows_location_id, UserId);
        }
    },[location])
    useEffect(()=>{
        if (obl){
            obl.replaceChildren();
            divs[0]&&divs.map(el=>{
                let div=document.createElement('div');
                div.id=el.id;
                div.style.position = "absolute";
                if(el.selected)
                    div.style.backgroundColor ="yellow" ;
                else
                    div.style.backgroundColor = "red";
                div.style.pointerEvents = "none";
                div.style.opacity = "0.5";
                div.style.left=Number(el.x)+main_div_coord.x+"px";
                div.style.top=Number(el.y)+main_div_coord.y+"px";
                div.style.height=el.height;
                div.style.width=el.width;
                obl.appendChild(div)
            })}
    },[divs])
    useEffect(()=>{
        document.addEventListener('copy',add_divs);
        return _=>{
            document.removeEventListener('copy',add_divs)
        }
    },[divs])
    useEffect(()=>{
        document.addEventListener('keydown',delete_div);
        return _=>{
            document.removeEventListener('keydown',delete_div)
        }
    },[divs])

    return (
        <>
            <AddWindowsLocation
             setLocation={setLocation_id}
             setHouse_id={setHouse_id}
            />
            <h1>{}</h1>
            <MyButton onClick={save_to_server}>Сохранить</MyButton>
            <div className={cl.mainWindow} id="100" onMouseDown={mouse_click}>
            </div>
        </>
    );
};

export default EditWindows;