import React from 'react';

const Payments = () => {
    return (
        <div>
            Payments
        </div>
    );
};

export default Payments;