import React from 'react';
import cl from './MyButton.module.css'

const MyButton = (props) => {
    return (
        <button {...props} className={cl.MyButton+" "+props.className} >
            
        </button>
    );
};

export default MyButton;