import React, {useEffect} from 'react';
import {useState} from "react";
import ReadQr from "../../QR/read QR/readQR";
import projects_server_requests from "../../api/projects_server_requests";
import cl from "./ReadProjectQR.module.css"
import {Document, Page} from "react-pdf";
import InputMask from "react-input-mask";

const ReadProjectQr = () => {
    const [QRMessage, setQRMessage]=useState();
    const [ProjectFilePath, setProjectFilePath]=useState(null);
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale]=useState(1);

    function ChangeScale(e){
        setScale(e.target.value)
    }
    function ChangeScaleByWheel(e){
        if(e.altKey)
            if(e.deltaY>0) setScale((scale)=>scale-0.1)
            else setScale((scale)=>scale+0.1)
    }
    function OnKeyDown(e){
        if(e.key=="ArrowRight") {
            setPageNumber((page)=>
            {
                if (page+1>numPages) return numPages
                else return page+1
            });
            return
        }
        if(e.key=="ArrowLeft") setPageNumber((page)=>
        {
            if(page-1==0) return 1
            else return page-1
        })
    }
    function PrevPage(){
        if(pageNumber-1==0) {setPageNumber(1);return;}
        setPageNumber((page)=>page-1)
    }
    function NextPage(){
        if (pageNumber+1>numPages) {setPageNumber(numPages); return;}
        setPageNumber((page)=>page+1)
    }
    useEffect(()=>{
        document.addEventListener('keydown', OnKeyDown);
        return _=>{document.removeEventListener("keydown", OnKeyDown)}
    },[])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(()=>{
        let a;
        if(QRMessage){
          a=QRMessage.split('-');
          projects_server_requests.GetProjectInfo(a[1]).then(res=>{console.log(res); setProjectFilePath(res.data[0].project_file_path+res.data[0].project_file)});
        }
    }, [QRMessage])
    return (
        <>
            <ReadQr
                setQRMessage={setQRMessage}
            />
            {ProjectFilePath?
                <div onWheel={ChangeScaleByWheel}>
                    <div className={cl.NavigationPanel}>
                        <div onClick={PrevPage} className={cl.Pages}>пред</div>
                        Страница: &nbsp; <input className={cl.input} value={pageNumber} onChange={e=>setPageNumber(Number(e.target.value))}/> &nbsp; из {numPages}
                        <div className={cl.Pages} onClick={NextPage}>след</div>
                        scale: <input  className={cl.input} value={scale} onChange={ChangeScale}/> &nbsp;&nbsp;&nbsp;
                    </div>
                    <Document file={ProjectFilePath} className={cl.document} onLoadSuccess={onDocumentLoadSuccess} >
                        <Page
                            pageNumber={pageNumber}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            scale={scale}
                        >
                        </Page>
                    </Document>
                </div>
                :""
            }
        </>
    );
};

export default ReadProjectQr;