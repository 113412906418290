import React from 'react';
import {useEffect, useState} from "react";
import AdministrationAPI from "../../api/AdministrationAPI";
import Select from "react-select";
import cl from "./AddQueue.module.css";
import CreatableSelect from "react-select/creatable";
import MyButton from "../../UI/MyButton/MyButton";
import global_functions from "../../global_functions";
import Loading from "../../UI/Loading/loading";
import useStore from "../../Store";

const AddQueue = () => {
    const AreaId=useStore((state)=>state.AreaId)
    const UserId=useStore((state)=>state.User).Id;
    const [QueuesOption,setQueuesOption]=useState([]);
    const [Queue, setQueue]=useState();
    const [IsLoading, setIsLoading]=useState(false);

    function EditQueuesOption(value){
        setQueue(value);
    }
    function AddQueueName(value){
        setQueue({value:0, label:value});
    }
    async function AddNewQueue(){
        if(Queue.value===0){
            const res=await AdministrationAPI.AddQueue(AreaId, Queue.label, UserId);
            if(res.data==="OK") alert("Очередь успешно добавлена!")
            setQueue(null)
        }else{
            alert("Такая очередь уже существует!")
        }
    }

    useEffect(()=>{
        global_functions.GetQueues(AreaId, UserId)
            .then(res=>{
                setQueuesOption(res)
                setIsLoading(true)
            })
    },[AreaId])

    if(IsLoading)
        return (
            <div className={cl.container}>
                <CreatableSelect
                    onCreateOption={AddQueueName}
                    value={Queue}
                    className={cl.queue}
                    placeholder="Очередь"
                    options={QueuesOption}
                    onChange={EditQueuesOption}
                />
                <MyButton className={cl.button} onClick={AddNewQueue}>Добавить очередь</MyButton>
            </div>
        );
    else <Loading/>
};

export default AddQueue;