import React from 'react';
import cl from "./loading.module.css"
const Loading = () => {
    return (
        <>
            <svg className={cl.svg} viewBox="25 25 50 50">
                <circle className={cl.circle} r="20" cy="50" cx="50"></circle>
            </svg>
        </>
    );
};

export default Loading;