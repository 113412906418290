import React from 'react';
import {useEffect, useState} from "react";
import ServerRequests from "../../api/ServerRequests";
import cl from "./add_request.module.css";
import Select from "react-select";
import MyButton from "../../UI/MyButton/MyButton";
import InputMask from "react-input-mask";
import useStore from "../../Store";


const AddRequest = ({setRequest_id, setFloor_id}) => {
    const [house_option,setHouse_option]=useState([]);
    const [house,setHouse]=useState({});
    const [area_option,setArea_option]=useState([]);
    const [area, setArea]=useState();
    const [section_option,setSection_option]=useState([]);
    const [section, setSection]=useState([]);
    const [floor_option,setFloor_option]=useState([]);
    const [floor,setFloor]=useState([]);
    const [isLoading, setIsLoading]=useState(false);
    const [date_for, setDate_for]=useState();
    const manager=useStore((state)=>state.manager);
    async function get_houses(area_id) {
        let temp=[];
        if (area_id!=undefined) {
            const res = await ServerRequests.get_houses(area_id);
            //          console.log(res);
            res.data.map(el => {
                temp = [...temp, {value: el.house_id, label: el.house_num}];
            });
            setHouse_option(temp);
        }
    }
    async function get_area() {
        let temp=[];
        const res = await ServerRequests.get_area();
        //      console.log(res);
        res.data.map((el)=>{
            temp=[...temp, {value:el.area_id, label:el.area_name}];
        })
        setArea_option(temp);
        setArea(temp[0].value);
        setIsLoading(true);
    }
    async function get_sections(house_id) {
        let temp=[];
        const res = await ServerRequests.get_sections(house_id);
 //        console.log(res);
        res&&res.data.map((el)=>{
            temp=[...temp, {value:el.section_id, label:"сек. "+el.section_name}];
        })
        setSection_option(temp);
        setIsLoading(true);
    }
    async function get_floors(section_id) {
        let temp=[];
        const res = await ServerRequests.get_floors(section_id);
        //    console.log(res);
        res&&res.data.map((el)=>{
            temp=[...temp, {value:el.floor_id, label:el.floor_name}];
        })
        setFloor_option(temp);
        setIsLoading(true);
    }
    function edit_area_options(value){
        setArea(value.value);
    }
    function edit_house_options(value){
        setHouse(value);
        setSection({});
        setFloor({})
    }
    function edit_section_options(value){
        setSection(value);
        setFloor({});
    }
    function edit_floor_options(value){
        setFloor(value);
    }
    async function add_row(e){
        e.preventDefault();
        const d = date_for.split('-');
        if (section!={} && floor!={}) {
            const res=await ServerRequests.add_request(manager.contractor, floor.value, d[2] + "-" + d[1] + "-" + d[0]);
 //           console.log(res);
            setRequest_id(res.data.request_id);
            setFloor_id(floor.value);
            setSection(null);
            setFloor(null);
        }
    }
    useEffect(()=>{get_area();},[])
    useEffect(()=>{if(area)get_houses(area)},[area]);
    useEffect(()=>{if(house){ get_sections(house.value);}},[house]);
    useEffect(()=>{if(section){get_floors(section.value)}},[section])

 //   console.log(section);
    if(isLoading)
    return (
        <>
            <h1 className={cl.text}>Добавление заявки на материал</h1>
            <form className={cl.mainDiv} onSubmit={add_row}>
                <Select defaultValue={area_option[0]} className={cl.area} options={area_option}
                        onChange={edit_area_options}/>
                <Select className={cl.house} options={house_option}
                        onChange={edit_house_options} placeholder="Дом"/>
                <Select value={section} className={cl.section} options={section_option}
                        onChange={edit_section_options} placeholder="Секция"/>
                <Select value={floor} className={cl.floor} options={floor_option}
                        onChange={edit_floor_options} placeholder="Этаж"/>
                <InputMask
                    mask='99-99-9999'
                    placeholder='дата поставки'
                    value={date_for}
                    onChange={e=>setDate_for(e.target.value)}
                    name='doc_date'
                    className={cl.date}
                />
                <MyButton className={cl.button}>Добавить заявку</MyButton>
            </form>
        </>
    ); else return 'loading...';
};

export default AddRequest;