import React, {useEffect} from 'react';
import {useState} from "react";
import Add_request from "./add_request";
import RequestEdit from "./request_edit/request_edit";
import useStore from "../../Store";
import RequestsShow from "./requests_show";

const RequestsContractor = ({contractor_id}) => {
    const [request_id, setRequest_id]=useState(0);
    const [floor_id, setFloor_id]=useState();
    const manager=useStore((state)=>state.manager);
  //  console.log(request_id);
    return(
        <>
            <Add_request
                setRequest_id={setRequest_id}
                setFloor_id={setFloor_id}
            />
            __________________________________________________________________________________________________________
            <RequestsShow contractor_id={manager.contractor} setRequest_id={setRequest_id} request_id={request_id}/>
            __________________________________________________________________________________________________________
            {request_id!=0?
                <RequestEdit
                    request_id={request_id}
                    setRequest_id={setRequest_id}
                />:""
            }
        </>
    );
};

export default RequestsContractor;