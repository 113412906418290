import React, {useEffect, useState} from 'react';
import prescription_server_requests from "../../api/prescription_requests";
import cl from "./add_prescription.module.css";
import Select from "react-select";
import InputMask from "react-input-mask";
import MyButton from "../../UI/MyButton/MyButton";
import global_functions from "../../global_functions";
import Loading from "../../UI/Loading/loading";
import moment from "moment";
import useStore from "../../Store";

const AddPrescription = ({setSubpage}) => {
    const [file, setFile]=useState();
    const [contractors, setContractors]=useState();
    const [contractor, setContractor]=useState();
    const [sections, setSections]=useState([]);
    const [section, setSection]=useState();
    const [house, setHouse]=useState(null)
    const [houses, setHouses]=useState([]);
    const [notes, setNotes]=useState('');
    const [description, setDescription]=useState("");
    const [loading, setLoading]=useState(false);
    const [prescriptionDate, setPrescriptionDate]=useState(moment().format('DD.MM.YYYY'));
    const [contractorWork, setContractorWork]=useState(null)
    const [contractorWorks, setContractorWorks]=useState([]);
    const [fixingDate, setFixingDate]=useState(moment().add('days', 10).format('DD.MM.YYYY'));
    const User=useStore((state)=>state.User);

    async function get_contractor_sections(contractor_id, UserId){
        let tempSections=[];
        let tempContractorWorks=[];
        let tempHouses=[];

        const res=await prescription_server_requests.get_contractor_sections(contractor_id, UserId);
        res.data[0].section_id&&res.data.map(element=>{
            tempSections=[...tempSections, {value:element.section_id, label:element.section_name}];
            if(tempContractorWorks.filter(el=>el.value===element.contractor_work_name_id).length==0) tempContractorWorks.push({value:element.contractor_work_name_id, label:element.contractor_work_name});
            if(tempHouses.filter(el=>el.value===element.house_id).length==0) tempHouses.push({value:element.house_id, label:element.house_num});
        });

        setSections(tempSections);
        setContractorWorks(tempContractorWorks);
        setHouses(tempHouses);

        if(tempHouses.length==1) setHouse(tempHouses[0])
        if(tempContractorWorks.length==1) setContractorWork(tempContractorWorks[0])
    }
    function fileSelect(e){
        setFile(e.target.files[0])
    }
    function contractor_change(value){
        setContractor(value);
        setContractorWork(null)
        setHouse(null)
        setSection(null)
    }
    function changeHouse(value){
        setHouse(value);
    }
    function section_change(value){
        setSection(value);
    }
    function contractorWorkChange(value){
        setContractorWork(value)
    }
    async function add_prescription(){
        if(contractor&&contractorWork&&house&&section) {
            let sectionsString;
            section.map(el=>{
                if(sectionsString.length===1) sectionsString+=el.label; else sectionsString+=","+el.label;
            })

            prescription_server_requests.add_prescription(contractor.value, notes, prescriptionDate, User.Id, description, fixingDate,sectionsString, file, house.label)
                .then(res=>console.log(res));
            setContractor(null);
            setSections([]);
            setSection(null);
            setNotes("");
            const el = document.getElementById("file");
            el.value = "";
            alert("Предписание успешно добавлено в базу!")
            setSubpage("Показать предписания");
        }else alert("Необходимо заполнить обязательные поля!");
    }
    useEffect(()=>{
        global_functions.GetContractors(User.Id)
            .then(res=>{
                setContractors(res);
                setLoading(true)})
    },[]);
    useEffect(()=>{
        if(contractor)
            get_contractor_sections(contractor.value, User.Id)
    },[contractor]);

    if(loading)
        return (
            <>
                <div className={cl.text}>Добавление предписания:</div>
                <div className={cl.container1}>
                    <div className={cl.dateText}>Дата предписания:</div>
                    <InputMask
                        mask='99.99.9999'
                        placeholder='DD-MM-YYYY'
                        value={prescriptionDate}
                        onChange={e=>setPrescriptionDate(e.target.value)}
                        className={cl.date}
                    />
                    <div className={cl.dateText}>Срок устранения предписания:</div>
                    <InputMask
                        mask='99.99.9999'
                        placeholder='DD-MM-YYYY'
                        value={fixingDate}
                        onChange={e=>setFixingDate(e.target.value)}
                        className={cl.date}
                    />
                </div>
                <div className={cl.container2}>
                    <Select
                        options={contractors}
                        className={cl.contractor}
                        value={contractor}
                        onChange={contractor_change}
                        placeholder={"Подрядчик"}
                    />
                    <Select
                        options={contractorWorks}
                        className={cl.contractorWork}
                        value={contractorWork}
                        onChange={contractorWorkChange}
                        placeholder={"Вид работ"}
                    />
                    <Select
                        options={houses}
                        className={cl.house}
                        value={house}
                        onChange={changeHouse}
                        placeholder={"Дом"}
                    />
                    <Select
                        options={sections}
                        className={cl.sections}
                        isMulti
                        value={section}
                        onChange={section_change}
                        placeholder={"Секции"}
                    />
                </div>
                <div className={cl.container3}>
                    <InputMask
                        value={description}
                        onChange={e=>setDescription(e.target.value)}
                        placeholder='Краткое описание'
                        className={cl.description}
                    />
                    <InputMask
                        id="file"
                        type="file"
                        className={cl.file}
                        onChange={fileSelect}
                    />
                    <InputMask
                        value={notes}
                        onChange={e=>setNotes(e.target.value)}
                        placeholder='Примечания'
                        className={cl.notes}
                    />
                </div>
                    <MyButton className={cl.button} onClick={add_prescription}>Добавить</MyButton>

            </>
        );
    else return <Loading/>
};

export default AddPrescription;