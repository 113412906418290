import React, {useEffect} from 'react';
import {useState} from "react";
import ServerRequests from "../../../api/ServerRequests";
import Select from "react-select";
import InputMask from "react-input-mask";
import cl from "./add_row.module.css"
import MyButton from "../../../UI/MyButton/MyButton";

const AddRow = ({floor_id, request_id, setAdd_button_click}) => {
    const [material_name, setMaterial_name]=useState({});
    const [material_count, setMaterial_count]=useState();
    const [material_name_option, setMaterial_name_option]=useState([]);

    async function  get_materials_floor(floor_id){
        let temp=[];
        const res=await ServerRequests.get_materials_floor(floor_id);
  //      console.log(res);
        res.data.map((el)=>{
            temp=[...temp, {value:el.material_name_id, label:el.material_name, material_measure:el.material_measure_name, material_count:el.material_count}];
        })
        setMaterial_name_option(temp);
    }
    function edit_material_name_option(value){
        setMaterial_name(value)
    }
    async function add_row(){
        if(material_name.material_count>material_count) {
            const res = await ServerRequests.add_request_row(request_id, material_name.value, material_count);
            setMaterial_name(null);
            setMaterial_count("");
            setAdd_button_click(true);
        }else alert("Превышение по количеству материала");
    }
    useEffect(()=>{get_materials_floor(floor_id)}, [floor_id])
  //  console.log(floor_id);
    return (
        <div className={cl.mainDiv}>
            <Select className={cl.material} value={material_name} options={material_name_option} onChange={edit_material_name_option} placeholder="Наименование"/>
            <InputMask
                maskChar={' '}
                value={material_count}
                onChange={e=>setMaterial_count(e.target.value.replace(',','.'))}
                placeholder='количество'
                name='material_count'
                className={cl.material_count}
            />
            <p className={cl.material_measure}>{material_name&&material_name.material_measure}</p>
            <MyButton className={cl.button} onClick={add_row}>Добавить материал</MyButton>
        </div>
    );
};

export default AddRow;