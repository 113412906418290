import axios from "axios";
import server_constants from "./server_constants";

export default class gpr_requests {
    static url = server_constants.global_url + 'gpr_api.php';
    static login = server_constants.login;
    static psw = server_constants.psw;

    static async getHouseWorks(houseId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getHouseWorks',
                houseId: houseId,
                login: this.login,
                password: this.psw
            }
        });
        return responce;
    }
    static async getGPRWorks() {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getGPRWorks',
                login: this.login,
                password: this.psw
            }
        });
        return responce;
    }
    static async editGPRWorks(houseId, works) {
        const formData = new FormData();
        formData.append('action', 'editGPRWorks');
        formData.append('houseId', houseId);
        formData.append('works', JSON.stringify(works));
        formData.append('login', this.login);
        formData.append('password', this.psw);
        const responce = await axios.post(this.url, formData);
        return responce;
    }
}