import React, {useEffect, useRef, useState} from 'react';
import cl from "./MatchingProjects.module.css";
import { Document, Page} from 'react-pdf';
import { pdfjs } from 'react-pdf';
import InputMask from "react-input-mask";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const MatchingProjects = () => {
    const canvasRef=useRef();
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale]=useState(1);
    const [LeftFile, setLeftFile]=useState(null)
    const [RightFile, setRightFile]=useState(null)

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    function ChangeScale(e){
        setScale(e.target.value)
    }
    function ChangeScaleByWheel(e){
        if(e.altKey)
            if(e.deltaY>0) setScale((scale)=>scale-0.1)
            else setScale((scale)=>scale+0.1)
    }
    function OnKeyDown(e){
        if(e.key=="ArrowRight") {
            setPageNumber((page)=>
            {
                if (page+1>numPages) return numPages
                else return page+1
            });
            return
        }
        if(e.key=="ArrowLeft") setPageNumber((page)=>
        {
            if(page-1==0) return 1
            else return page-1
        })
    }
    function PrevPage(){
        if(pageNumber-1==0) {setPageNumber(1);return;}
        setPageNumber((page)=>page-1)
    }
    function NextPage(){
        if (pageNumber+1>numPages) {setPageNumber(numPages); return;}
        setPageNumber((page)=>page+1)
    }
    function LeftFileSelect(e){
        setLeftFile(e.target.files[0])
    }
    function RightFileSelect(e){
        setRightFile(e.target.files[0])
    }
    useEffect(()=>{
        document.addEventListener('keydown', OnKeyDown);
        return _=>{document.removeEventListener("keydown", OnKeyDown)}
    },[])

    return (
        <div onWheel={ChangeScaleByWheel}>
            <div className={cl.NavigationPanel}>
                <InputMask
                    className={cl.file}
                    id="LeftFile"
                    type="file"
                    onChange={LeftFileSelect}
                />
                <div onClick={PrevPage} className={cl.Pages}>пред</div>
                Страница: &nbsp; <input className={cl.input} value={pageNumber} onChange={e=>setPageNumber(Number(e.target.value))}/> &nbsp; из {numPages}
                <div className={cl.Pages} onClick={NextPage}>след</div>
                scale: <input  className={cl.input} value={scale} onChange={ChangeScale}/> &nbsp;&nbsp;&nbsp;
                <InputMask
                    className={cl.file}
                    id="RightFile"
                    type="file"
                    onChange={RightFileSelect}
                />
            </div>
            <div  className={cl.container}>
                <Document file={LeftFile} className={cl.document} onLoadSuccess={onDocumentLoadSuccess} >
                    <Page
                        pageNumber={pageNumber}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        canvasRef={canvasRef}
                        scale={scale}
                    >
                    </Page>
                </Document>
                <Document file={RightFile} onLoadSuccess={onDocumentLoadSuccess} >
                    <Page
                        pageNumber={pageNumber}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        className={cl.frame}
                        scale={scale}
                        onScroll={()=>{console.log(123)}}
                    >
                    </Page>
                </Document>
            </div>
        </div>
    );
};

export default MatchingProjects;