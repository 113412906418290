import React, {useEffect, useState} from 'react';
import MyInput from "./UI/MyInput/MyInput";
import MyButton from "./UI/MyButton/MyButton";
import useStore from "./Store";
import server_constants from "./api/server_constants";


const Login = () => {
    const [login, setLogin] = useState('');
    const [pass, setPass]=useState('');
    const [SubPages, setSubPages]=useState(null);
    const isAuth=useStore((state)=>state.auth);
    const setIsAuth=useStore((state)=>state.setAuth)
    const setUser=useStore((state)=>state.setUser);
    const User=useStore((state)=>state.User);
    async function enter(e){
        e.preventDefault();
        let res, resSubPages;
        if(pass.length<11&&login.length<26)
            res = await server_constants.login_to_server(login,pass);
        else res={"data":"NO"};

        if(res.data!='NO') {
            resSubPages=await server_constants.getUserSubPages(res.data.UserId,1)
            setUser(res.data.UserName+" "+res.data.UserSurName+" "+res.data.UserFamilyName, res.data.UserId, resSubPages.data[0].DepartmentId, 1, resSubPages.data[0].UserSubPagesEditable, 10);
            setIsAuth(true);
        }
        else alert("Check your login and password!");
    }

    return (
        <div style={{width:"50%", marginLeft:"auto", marginRight:"auto"}}>
            <h1>login page</h1>
            <form onSubmit={enter}>
                <MyInput value={login} onChange={e=>setLogin(e.target.value)} type='text' placeholder='enter login'/>
                <MyInput value={pass} onChange={e=>setPass(e.target.value)} type="password" placeholder='enter pwd'/>
                <br/>
                <br/><br/>
                <MyButton>enter</MyButton>
            </form>
        </div>
    );
};

export default Login;