import React from 'react';
import useStore from "../Store";
import {useState} from "react";
import materials_server_requests from "../api/materials_server_requests";
import {useEffect} from "react";
import cl from "./add_material_section.module.css";
import InputMask from "react-input-mask";
import MyButton from "../UI/MyButton/MyButton";
import Select from "react-select";

const AddMaterialSection = () => {
    const manager=useStore((state)=>state.manager);
    const setAdd_but=useStore((state)=>state.setAddDoc_button)
    const [material_types_option, setMaterial_types_option]=useState([]);
    const [material_type, setMaterial_type]=useState();
    const [material_name, setMaterial_name]=useState("");
    const [material_name_option, setMaterial_name_option]=useState("");
    const [material_sections, setMaterial_sections]=useState([]);
    const [material_count,setMaterial_count]=useState([]);
    const [houses,setHouses]=useState([]);
    const [house,setHouse]=useState();
    const [floors,setFloors]=useState([]);
    const [section_options,setSection_options]=useState([]);
    const [sections,setSections]=useState([]);
    const [areas,setAreas]=useState([]);
    const [area,setArea]=useState();
    const [isLoading,setIsLoading]=useState(false);
    const [isLoad,setIsLoad]=useState(false);
    let [unique_floors, setUnique_floors]=useState([]);


    async function get_material_types() {
        const res = await materials_server_requests.get_material_types();
//        console.log(res);
        let temp=[];
        res.data.map(el=>{
            temp=[...temp, {value:el.material_type_id, label:el.material_type_name}];
        });
        setMaterial_types_option(temp);
    }
    async function get_materials_names(material_type_id) {
        const res = await materials_server_requests.get_materials_names(material_type_id);
 //       console.log(res);
        let temp=[];
        res.data.map(el=>{
            temp=[...temp, {value:el.material_name_id, label:el.material_name}];
        });
        setMaterial_name_option(temp);
    }
    async function get_houses(area_id) {
        const res = await materials_server_requests.get_houses(area_id);
        //  console.log(res);
        setHouses(res.data)
        setHouse(res.data[0].house_id);
        setIsLoading(true);
    }
    async function get_sections(house_id) {
        let temp=[];
        const res = await materials_server_requests.get_sections(house_id);
    //    console.log(res);
        res.data.map(el=>{
            temp=[...temp, {value:el.section_id, label:el.section_name}];
        });
        setSection_options(temp);
   //     setSections(res.data)
    }

    async function get_floors(house_id) {
        const uf=new Set();
        const res = await materials_server_requests.get_house_floors(house_id);
     //    console.log(res);

         res.data[0]&&res.data.map((el=>{
             uf.add(el.floor_name);
         }))
        setFloors(res.data)
        setUnique_floors(Array.from(uf));
         setIsLoading(true);
    }
    async function get_area() {
        const res = await materials_server_requests.get_area();
//        console.log(res);
        setAreas(res.data);
        setArea(res.data[0].area_id);
    }
    async function get_materials_multi_section(sections){
        if(sections[0]) {
            let sec = sections.map(el => el.section_id);
            const res = await materials_server_requests.get_materials_multi_sections(sec);
 //           console.log(res);
            setMaterial_sections(res.data);
        }
    }
    function edit_area_option(area_id){
        setArea(area_id);
    }
    function edit_material_types_option(value){
        setMaterial_type(value);
        setMaterial_name_option({});
        setMaterial_name({});
    }
    function edit_houses_option(house_id){
        setIsLoading(false);
        setHouse(house_id);
        setSections([]);
        setSection_options([]);
    }
    function edit_material_name_option(value){
        let temp=material_sections.filter(el=>el.material_name_id==value.value);
        console.log(temp);
        setMaterial_name(value);
        setMaterial_count(temp.map((el)=>{return({"floor_id":el.floor_id, "count":el.material_count});}))
        setIsLoad(true);
    }
    function edit_material_count(e){
        //{floor_id, count}
        const count=e.target.value.replace(',','.');
        const floor=e.currentTarget.getAttribute('data-floor');
        if(material_count.findIndex(el=>el.floor_id==floor) == -1)
            setMaterial_count([...material_count, {floor_id:floor, count:count}])
        else {
            setMaterial_count(material_count.map(el =>
                (el.floor_id == floor) ? {...el, count: count} : el
            ))
        }
    }
    async function add_material_section(e){
        e.preventDefault();
        if(material_name.id!=""&&material_count.length>0){
            setIsLoad(false);
            const res=await materials_server_requests.add_material_section(material_name.value,material_count);
 //           console.log(res);
            setMaterial_count([]);
            setMaterial_name(null);
            setAdd_but(true);
            setIsLoad(true);
        }else alert("Заполните обязательные поля!");
    }
    function edit_section_options(newValue){
        //      console.log(newValue)
        let temp_sections=[];
        newValue.map(v=>{temp_sections=[...temp_sections, {section_name:v.label, section_id:v.value}]})
        setSections(temp_sections);
        setMaterial_count([]);
        setMaterial_type(null)
        setMaterial_name(null)
    }
    useEffect(()=>{get_area();},[]);
    useEffect(()=>{get_material_types();},[]);
    useEffect(()=>{if(area){setIsLoading(false); get_houses(area);}},[area]);
    useEffect(()=>{if(house){get_sections(house);get_floors(house);}},[house]);
    useEffect(()=>{if(material_type)get_materials_names(material_type.value);},[material_type])
    useEffect(()=>{if(sections)get_materials_multi_section(sections);},[sections,isLoad])
  //  console.log(sections);
  //  console.log(material_sections);
    if(isLoading)
    return (
        <form className={cl.form} onSubmit={add_material_section}>
            <div className={cl.div}>
                <select className={cl.area} name='area' value={area} onChange={(e)=>edit_area_option(e.target.value)}>
                    {/*<option>Choose a document type</option>*/}
                    {areas.map((ar,index)=>{
                        return (<option key={ar.area_id} value={ar.area_id}>{ar.area_name}</option>);
                    })
                    }
                </select>
                <select className={cl.house} name='house' value={house} onChange={(e)=>edit_houses_option(e.target.value)}>
                    {/*<option>Choose a document type</option>*/}
                    {houses.map((h,index)=>{
                        return (<option key={h.house_id} value={h.house_id}>{h.house_num}</option>);
                    })
                    }
                </select>
                <Select placeholder="секция" className={cl.section} isMulti options={section_options} onChange={edit_section_options}/>
                <Select placeholder="Тип материала" className={cl.material_type} value={material_type} options={material_types_option} onChange={edit_material_types_option}/>
                <Select placeholder="Материал" className={cl.material_name} value={material_name} options={material_name_option} onChange={edit_material_name_option}/>
            </div>
            {isLoad?<>
            <table>
                <th></th>
                {
                    sections[0]&&sections.map((sec)=>{
                        return (
                            <th>
                               секция {sec.section_name}
                            </th>
                        )
                    })
                }
                {
                    sections[0]&&unique_floors.map((floor_name)=>{
                        return(
                            <tr>
                                <td>{floor_name}</td>
                                { sections[0]&&sections.map((sec)=>{

                                    const floor_id=floors.filter((el=>el.floor_name==floor_name && el.section_id==sec.section_id))[0]&&floors.filter((el=>el.floor_name==floor_name && el.section_id==sec.section_id))[0].floor_id;

                                    const count=material_count.filter(el=>el.floor_id==floor_id);
                              //       console.log(count);
                                    if (floor_id)
                                    return (
                                        <td>
                                            <InputMask
                                                value={(material_count.length>0)?
                                                    count[0]&&count[0].count:
                                                    ""}
                                                onChange={edit_material_count}
                                                data-floor={floor_id}
                                             //   placeholder={floor_id}
                                                className={cl.material_name}
                                            />
                                        </td>
                                    )
                                })}
                            </tr>
                        );
                    })
                }
            </table>
            <MyButton>Добавить материал</MyButton></>
                :"Необходимо выбрать параметры"}
        </form>
    ); else return 'loading...';
};

export default AddMaterialSection;