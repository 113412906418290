import axios from "axios";
import server_constants from "./server_constants";
export default class materials_server_requests{
    static url = server_constants.global_url+'api_sales.php';
    static login = server_constants.login;
    static psw = server_constants.psw;

    static async get_material_types(){
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_material_types',
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    // static async get_area(UserId){
    //     return server_constants.get_area();
    // }
    static async get_houses(area_id) {
        return server_constants.get_houses(area_id);
    }
    static async get_sections(house_id){
        if(!!house_id) {
            const responce = await axios.get(this.url, {
                params: {
                    action: 'get_sections',
                    house_id: house_id,
                    login: this.login,
                    password: this.psw
                }
            });
            return responce;
        }
    }
    static async get_floors(section_id){
        if(!!section_id) {
            const responce = await axios.get(this.url, {
                params: {
                    action: 'get_floors',
                    section_id: section_id,
                    login: this.login,
                    password: this.psw
                }
            });
            return responce;
        }
    }
    static async get_house_floors(house_id){
        if(!!house_id) {
            const responce = await axios.get(this.url, {
                params: {
                    action: 'get_house_floors',
                    house_id: house_id,
                    login: this.login,
                    password: this.psw
                }
            });
            return responce;
        }
    }
    static async get_material_measures(){
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_material_measures',
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async add_material_name(material_name, material_measure_id, material_type){
        const responce = await axios.get(this.url, {
            params: {
                action: 'add_material_name',
                material_name:material_name,
                material_measure_id:material_measure_id,
                material_type_id:material_type,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async add_material(floor, material_type, material_name, material_measure,material_count){
        const responce = await axios.get(this.url, {
            params: {
                action: 'add_material',
                floor_id:floor,
                material_type_id:material_type,
                material_name:material_name,
                material_measure_id:material_measure,
                material_count:material_count,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async get_materials_names(material_type_id){
        if(!!material_type_id) {
            const responce = await axios.get(this.url, {
                params: {
                    action: 'get_materials_names',
                    material_type_id: material_type_id,
                    login: this.login,
                    password: this.psw
                }
            });
            return responce;
        }
    }
    static async add_material_section( material_name_id, json_string){
        const formData=new FormData();
        formData.append('action','add_material_section' );
        formData.append('material_name_id', material_name_id);
        formData.append('material_count',JSON.stringify(json_string));
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=axios.post(this.url, formData);
        return responce;
    }
    static async add_material_from_excel(json_string){
        const formData=new FormData();
        formData.append('action','add_material_from_excel' );
        formData.append('material_count',json_string);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=axios.post(this.url, formData);
        return responce;
    }
    static async get_materials_multi_sections(sections){
        const params={
            action: 'get_materials_multi_sections',
            sections:sections.toString(),
            login:this.login,
            password:this.psw
        };
        if(!!sections) {
            const responce = await axios.get(this.url, {
                params
            });
            return responce;
        }
    }
    static async send_gpr_file_to_server(file, house_id){
        const formData=new FormData();
        formData.append('house_id',house_id);
        formData.append('file', file);
        formData.append('action','add_gpr_from_file')
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=axios.post(this.url, formData);
        return responce;
    }
    static async get_materials_floor(floor_id){
        const params={
            action: 'get_materials_floor',
            floor_id:floor_id,
            login:this.login,
            password:this.psw
        };
        if(!!floor_id) {
            const responce = await axios.get(this.url, {
                params
            });
            return responce;
        }
    }

}