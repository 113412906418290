import axios from "axios";
export default class ServerRequests{
    static url = 'https://crm.yourprivelege.ru/server/api_sales.php';
    static url1='https://crm.sibusadba.ru/file_server.php'
    static login='07QxBvVNkB';
    static psw='kR8PvE7pmh';
    static async get_booking(house_id) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_booking',
                house_id: house_id,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async get_houses(area_id) {
        if(!!area_id) {
            const responce = await axios.get(this.url, {
                params: {
                    action: 'get_houses',
                    area_id: area_id,
                    login: this.login,
                    password: this.psw
                }
            });
            return responce;
        }
    }
    static async save_booking(flat_id, manager_id, notes) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'add_booking',
                flat_id:flat_id,
                manager_id:manager_id,
                notes:notes,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async delete_booking(booking_id, manager_id) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'delete_booking',
                booking_id:booking_id,
                manager_id:manager_id,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async send_file_to_server(file, filename, action='send_file',setUpload){
        const config = {
            onUploadProgress: progressEvent => console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        }
        const formData=new FormData();
  //      console.log(action);
        formData.append('filename',filename);
        formData.append('file', file);
        formData.append('action',action)
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=axios.post(this.url, formData, config);
        return responce;
    }

    static async doc_for_print(date, user_id){
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_doc_for_print',
                print_date:date,
                user_id:user_id,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }

    static async get_area(){
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_area',
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async get_sections(house_id){
        if(!!house_id) {
            const responce = await axios.get(this.url, {
                params: {
                    action: 'get_sections',
                    house_id: house_id,
                    login: this.login,
                    password: this.psw
                }
            });
            return responce;
        }
    }
    static async get_floors(section_id){
        if(!!section_id) {
            const responce = await axios.get(this.url, {
                params: {
                    action: 'get_floors',
                    section_id: section_id,
                    login: this.login,
                    password: this.psw
                }
            });
            return responce;
        }
    }
    static async get_materials_floor(floor_id){
        const params={
            action: 'get_materials_floor',
            floor_id:floor_id,
            login:this.login,
            password:this.psw
        };
        if(!!floor_id) {
            const responce = await axios.get(this.url, {
                params
            });
            return responce;
        }
    }
    static async get_request_rows(request_id){
        const params={
            action: 'get_request_rows',
            request_id:request_id,
            login:this.login,
            password:this.psw
        };
        if(!!request_id) {
            const responce = await axios.get(this.url, {
                params
            });
            return responce;
        }
    }
    static async get_requests_contractor(contractor_id){
        const params={
            action: 'get_requests_contractor',
            contractor_id:contractor_id,
            login:this.login,
            password:this.psw
        };
        if(!!contractor_id) {
            const responce = await axios.get(this.url, {
                params
            });
            return responce;
        }
    }
    static async add_request(contractor_id, floor_id, date_for){
        const params={
            action: 'add_request',
            contractor_id:contractor_id,
            floor_id:floor_id,
            date_for:date_for,
            login:this.login,
            password:this.psw
        };
        if(!!contractor_id) {
            const responce = await axios.get(this.url, {
                params
            });
            return responce;
        }
    }
    static async add_request_row(request_id, material_name_id, material_count){
        const params={
            action: 'add_request_row',
            request_id:request_id,
            material_name_id:material_name_id,
            material_count:material_count,
            login:this.login,
            password:this.psw
        };

        const responce = await axios.get(this.url, {
            params
        });
        return responce;
    }
    static async send_request(request_id){
        const params={
            action: 'send_request',
            request_id:request_id,
            login:this.login,
            password:this.psw
        };

        const responce = await axios.get(this.url, {
            params
        });
        return responce;
    }
    static async delete_request_row(request_row_id){
        const params={
            action: 'delete_request_row',
            request_row_id:request_row_id,
            login:this.login,
            password:this.psw
        };

        const responce = await axios.get(this.url, {
            params
        });
        return responce;
    }






}