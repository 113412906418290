import React from 'react';

const WindowsEditIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
             stroke="currentColor" strokeWidth="2" strokeLinecap="round"
             strokeLinejoin="round" fill="#3EBBC4" viewBox="0 0 512 512">
            <path
                d="m424,440l0,-392l-336,0l0,392l-40,0l0,32l416,0l0,-32l-40,0zm-304,-360l120,0l0,120l-120,0l0,-120zm0,152l120,0l0,144l-120,0l0,-144zm272,208l-272,0l0,-32l272,0l0,32zm0,-64l-120,0l0,-144l120,0l0,144zm0,-176l-120,0l0,-120l120,0l0,120z"
                id="svg_1"/>
            <path
                d="m468.063,207.96876c-10.64952,0 -21.2904,4.77613 -29.56194,14.35243l-158.61731,183.63744l-2.22509,2.57608l-0.63574,3.68011l-11.12546,64.76992l-3.1787,17.29651l14.93991,-3.68011l55.94518,-12.88038l3.1787,-0.73602l2.22509,-2.57608l158.61731,-183.63744c16.54309,-19.15258 16.54309,-49.29745 0,-68.45003c-8.27155,-9.57629 -18.91242,-14.35243 -29.56194,-14.35243zm0,22.44867c5.11685,0 10.30592,2.73129 15.25778,8.46425c9.90372,11.46592 9.90372,23.86312 0,35.32905l-7.31102,8.09624l-30.19768,-34.96104l6.99315,-8.46425c4.95186,-5.73296 10.14093,-8.46425 15.25778,-8.46425zm-36.55509,33.48899l30.19768,34.96104l-123.01583,142.42022c-6.65605,-15.08067 -17.17171,-27.25506 -30.19768,-34.96104l123.01583,-142.42022zm-136.04851,161.55679c12.19686,5.72201 21.75871,16.79213 26.70111,30.91292l-33.37639,7.72823l6.67528,-38.64115z"
                fill="red" id="svg_2"/>
        </svg>
    );
};

export default WindowsEditIcon;