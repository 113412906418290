import axios from "axios";
import server_constants from "./server_constants";
import global_functions from "../global_functions";
export default class DocumentsServerRequests{
    static url=this.checkUrl();
    static login = server_constants.login;
    static psw = server_constants.psw;

    static checkUrl(){
        let LocalUrl="http://127.0.0.1:8000/documents_api.php"
        let Url = server_constants.global_url+'documents_api.php';
        if(window.location.href.indexOf('localhost')!==-1) return LocalUrl;
        return Url;
    }
    static async getDocumentsTypes(UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'GetDocumentsTypes',
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        let temp=[];
        responce.data.map(el => {
            temp = [...temp, {value: el.document_type_id, label: el.name}];
        });
        return temp;
    }
    static async getContractors(UserId) {
        return global_functions.GetContractors(UserId)
    }
    static async AddDocument(ContractorId, DocumentType, DocumentDate, Amount=0, Notes, UserId,File) {
        const formData=new FormData();
        formData.append('file', File);
        formData.append('action','AddDocument');
        formData.append('contractor_id',ContractorId);
        formData.append('doc_type',DocumentType);
        formData.append('doc_date',DocumentDate);
        formData.append('amount',Amount);
        formData.append('notes',Notes);
        formData.append('UserId',UserId)
        formData.append('login', this.login);
        formData.append('password',this.psw);
        return axios.post(this.url, formData);
    }
    static async add_document_file(document_id,file, UserId) {
        const formData=new FormData();
        formData.append('file', file);
        formData.append('action','add_document_file');
        formData.append('document_id',document_id);
        formData.append('UserId',UserId)
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=axios.post(this.url, formData);
        return responce;
    }
    static async getDocumentsPto( UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getDocumentsPto',
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async GetDocumentsForPTO() {
        const responce = await axios.get(this.url, {
            params: {
                action: 'GetDocumentsForPTO',
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async insert_status(document_id,new_status, UserId){
        const responce = await axios.get(this.url, {
            params: {
                action: 'insert_status',
                document_id:document_id,
                new_status: new_status,
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async doc_for_print(date, user_id){
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_doc_for_print',
                print_date:date,
                user_id:user_id,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async set_storage_confirmation(document_id,user_id,confirmation, notes, file, UserId){
        const formData=new FormData();
        formData.append('file', file);
        formData.append('action','set_storage_confirmation');
        formData.append('document_id',document_id);
        formData.append('confirmation',confirmation);
        formData.append('notes',notes);
        formData.append('UserId',UserId);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=axios.post(this.url, formData);
        return responce;
    }
    static async get_storage_confirmation(document_id, UserId){
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_storage_confirmation',
                document_id:document_id,
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async set_building_control_confirmation(document_id,confirmation, notes, UserId){
        const responce = await axios.get(this.url, {
            params: {
                action: 'set_building_control_confirmation',
                document_id:document_id,
                confirmation:confirmation,
                notes:notes,
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
}