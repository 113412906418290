import React from 'react';
import cl from "./house1.1.module.css";

const House11 = () => {
    return (
        <div className={cl.container11}>
            <div id={"section 1"} className={cl.section1}>1</div>
            <div id={"section 2"} className={cl.section2}>2</div>
            <div id={"section 3"} className={cl.section3}>3</div>
            <div id={"section 4"} className={cl.section4}>4</div>
            <div id={"section 5"} className={cl.section5}>5</div>
            <div id={"section 6"} className={cl.section6}>6</div>
            <div id={"section 7"} className={cl.section7}>7</div>
            <div id={"section 8"} className={cl.section8}>8</div>
            <div id={"section 9"} className={cl.section9}>9</div>
        </div>
    );
};

export default House11;