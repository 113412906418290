import React from 'react';
import Select from "react-select";
import cl from "./windows_change_status.module.css";
import {useState} from "react";
import windows_server_requests from "../api/windows_server_requests";
import {useEffect} from "react";
import WindowsStatistic from "./windows_statistic";
import useStore from "../Store";

const WindowsChangeStatus = () => {
    const [area_option,setArea_option]=useState([]);
    const [area, setArea]=useState();
    const [house_option,setHouse_option]=useState([]);
    const [house,setHouse]=useState(null);
    const [window_location, setWindow_location]=useState(null)
    const [window_location_options, setWindow_location_options]=useState([]);
    const [windows_statuses_options, setWindows_statuses_options]=useState([]);
    const [windows_status, setWindows_status]=useState(null);
    const [location, setLocation]=useState();
    const [obl_change_satus, setObl_change_status]=useState();
    const [divs, setDivs]=useState([]);
    const [main_div_coord, setMain_div_coord]=useState();
    const UserId=useStore((state)=>state.User).Id;
    const AreaId=useStore((state)=>state.AreaId)

    function mouse_click(event) {
        //checking for div clicking
        let temp=[];
        temp=divs.map(el=>{
            let div_x=Number(el.x);
            let div_width=Number(el.width.slice(0,-2));
            let div_y=Number(el.y);
            let div_height=Number(el.height.slice(0,-2));
            if(
                ((div_x < event.nativeEvent.layerX) && ((div_x+div_width) > event.nativeEvent.layerX))&&
                ((div_y < event.nativeEvent.offsetY) && ((div_y+div_height) > event.nativeEvent.offsetY))
            )
                if(el.selected==0)
                    return {...el, selected:1};
                else return {...el, selected:0}
            else return el
        })
        setDivs(temp);
    }

    async function get_houses(area_id, UserId) {
        let temp=[];
        if (area_id!=undefined) {
            const res = await windows_server_requests.get_houses(area_id, UserId);
            res.data.map(el => {
                temp = [...temp, {value:el.house_id, label: el.house_num}];
            });
            setHouse_option(temp);
        }
    }
    async function get_location(location_id, UserId){
        const res=await windows_server_requests.get_location(location_id, UserId);
        setLocation(res.data);
    }
    async function get_locations(house_id, UserId) {
        let temp=[];
        const res = await windows_server_requests.get_locations(house_id, UserId);
        res.data.map(el => {
            temp = [...temp, {value:el.windows_location_id, label: el.windows_location_name}];
        });
        setWindow_location_options(temp);
    }
    async function get_windows_statuses(UserId) {
        let temp=[];
        const res = await windows_server_requests.get_windows_statuses(UserId);
        res.data.map(el => {
            temp = [...temp, {value:el.windows_status_id, label: el.windows_status_name}];
        });
        setWindows_statuses_options(temp);
    }
    async function get_windows(location_id){
        let temp=[];
        const res=await windows_server_requests.get_windows(location_id);
        temp=res.data.map(el=>{
            return {id:'window'+el.win_id,selected:0, x:el.x, y:el.y, height:el.height, width:el.width, windows_status_id:el.windows_status_id};
        });
        setDivs(temp);
    }
    function edit_area_options(value){
        setArea(value);
    }
    function edit_house_options(value){
        setHouse(value);
        setLocation([])
        setWindow_location(null)
    }
    function edit_windows_location_option(value){
        setWindow_location(value);
        get_location(value.value);
        get_windows(value.value);
    }
    async function edit_status_option(value){
        let temp;
        temp=divs.map(el=>{
           if(el.selected==1)
               return {...el, status:value.value}
           else return {...el, status:-1}
        });
        const res=await windows_server_requests.add_windows_statuses(temp, UserId);
        get_windows(window_location.value, UserId);
      //  setWindows_status(value);
    }
    useEffect(()=>{
        get_windows_statuses(UserId)
    },[])
    useEffect(()=>{
        get_houses(AreaId, UserId)
    },[AreaId])
    useEffect(()=>{
        if(house)get_locations(house.value, UserId)
    },[house])
    useEffect(()=>{
        setObl_change_status(document.getElementById("location_area"));
    },[])
    useEffect(()=>{
        if(obl_change_satus&&location[0]){
            obl_change_satus.style.backgroundImage = "url('"+location[0].windows_location_file_path+location[0].windows_location_file+"')";
            setMain_div_coord({x:obl_change_satus.offsetLeft, y:obl_change_satus.offsetTop})
        }
    },[location])
    useEffect(()=>{
        if (obl_change_satus && main_div_coord){
            obl_change_satus.replaceChildren();
            divs[0]&&divs.map(el=>{
                let div=document.createElement('div');
                div.id=el.id;
                div.style.position = "absolute";
                switch (el.windows_status_id){
                    case '1': div.style.backgroundColor ="red";break;
                    case '2': div.style.backgroundColor ="#FF8C00";break;
                    case '3': div.style.backgroundColor ="yellow";break;
                    case '4': div.style.backgroundColor ="green";break;
                    default:div.style.backgroundColor ="#800000";break;
                }
                if(el.selected)
                    div.style.backgroundColor ="blue" ;
                div.style.pointerEvents = "none";
                div.style.opacity = "0.5";
                div.style.left=Number(el.x)+"px";
                div.style.top=Number(el.y)+"px";
                div.style.height=el.height;
                div.style.width=el.width;
                obl_change_satus.appendChild(div)
            })}
    },[divs, main_div_coord])



    return (
        <>

            <div className={cl.container}>
                <Select
                    className={cl.house}
                    value={house}
                    options={house_option}
                    onChange={edit_house_options}
                    placeholder="Дом"
                />
                <Select
                    value={window_location}
                    className={cl.location}
                    placeholder="Расположение"
                    options={window_location_options}
                    onChange={edit_windows_location_option}
                />
                <Select
                    value={windows_status}
                    className={cl.location}
                    placeholder="Статус"
                    options={windows_statuses_options}
                    onChange={edit_status_option}
                />
            </div>
            {
                house&&
                    <WindowsStatistic
                    house_id={house.value}
                    />

            }
            <div className={cl.legend_container}>
                <div className={cl.legend_0}/> - нет информации
                <div className={cl.legend_1}/> - замеры сделаны, окно в производстве
                <div className={cl.legend_2}/> - проем подготовлен под монтаж
                <div className={cl.legend_3}/> - окно привезено на объект
                <div className={cl.legend_4}/> - окно установлено
            </div>
            <div className={cl.mainWindow} id="location_area" onMouseDown={mouse_click}/>
        </>
    );
};

export default WindowsChangeStatus;