import React, {useEffect, useState} from 'react';
import Show_post from "./show_post";
import Loading from "../UI/Loading/loading";
import LettersServerRequests from "../api/LettersServerRequests";
import ChangeYear from "./ChangeYear";
import useStore from "../Store";

const Input_letters = () => {
    const [input_letters, setInput_letters]=useState([]);
    const [isLoading, setIsLoading]=useState(false);
    const [reload, setReload]=useState(1);
    const [year, setYear]=useState({value:'2', label:'2024'});
    const UserId=useStore((state)=>state.User).Id

    async function get_letters(year) {
        const res = await LettersServerRequests.get_letters(1, year.label, UserId);
        if(res.data==="No data") res.data=setInput_letters([])
        else
            setInput_letters(res.data)
        setIsLoading(true)
    }
    useEffect(()=>{get_letters(year)},[reload, year])
    if(isLoading)
     return (
         <>
            <ChangeYear
                ChangeYear={setYear}
            />
            <Show_post
                setReload={setReload}
                post={input_letters}
                direction={"Входящая"}
            />
         </>
     )
    else return <Loading/>
};

export default Input_letters;