import React from 'react';
import cl from "../windows.module.css";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {useEffect, useState} from "react";
import windows_server_requests from "../../api/windows_server_requests";
import InputMask from "react-input-mask";
import MyButton from "../../UI/MyButton/MyButton";
import useStore from "../../Store";

const AddWindowsLocation = ({setLocation, setHouse_id}) => {

    const [area_option,setArea_option]=useState([]);
    const AreaId=useStore((state)=>state.AreaId)
    const [isLoading, setIsLoading]=useState(false);
    const [house_option,setHouse_option]=useState([]);
    const [house,setHouse]=useState(null);
    const [window_location, setWindow_location]=useState(null)
    const [window_location_options, setWindow_location_options]=useState([]);
    const [selectedFile, setSelectedFile]=useState();
    const UserId=useStore((state)=>state.User).Id;

    async function get_houses(area_id, UserId) {
        let temp=[];
        if (area_id!=undefined) {
            const res = await windows_server_requests.get_houses(area_id, UserId);
            //          console.log(res);
            res.data.map(el => {
                temp = [...temp, {value:el.house_id, label: el.house_num}];
            });
            setHouse_option(temp);
        }
    }
    async function get_location(house_id, UserId) {
        let temp=[];
        const res = await windows_server_requests.get_locations(house_id, UserId);
        //          console.log(res);
        res.data.map(el => {
            temp = [...temp, {value:el.windows_location_id, label: el.windows_location_name}];
        });
        setWindow_location_options(temp);
    }
    function edit_house_options(value){
        setHouse(value);
        setHouse_id(value.value);
        setWindow_location(null)
    }
    function edit_windows_location_option(value){
        setWindow_location(value);
        setLocation(value.value);
    }
    function fileSelect(e){
        setSelectedFile(e.target.files[0])
    }
   async function add_windows_location_name(location_name){
      const res= await windows_server_requests.add_location_name(house.value, location_name, UserId);
      get_location(house.value);
    }
    async function add_windows_location_file(){
        const res=await windows_server_requests.add_location_file(window_location.value, selectedFile, UserId);
        setHouse(null);
        setWindow_location(null);
        const el=document.getElementById("file");
        el.value="";
    }

    useEffect(()=>{
        get_houses(AreaId, UserId)
    },[AreaId])
    useEffect(()=>{
        if(house)get_location(house.value, UserId)
    },[house])
    return (
        <div className={cl.container}>
            <Select
                className={cl.house}
                value={house}
                options={house_option}
                onChange={edit_house_options}
                placeholder="Дом"
            />
            <CreatableSelect
                onCreateOption={add_windows_location_name}
                value={window_location} className={cl.location}
                placeholder="Расположение"
                options={window_location_options}
                onChange={edit_windows_location_option}
            />
            <InputMask
                className={cl.file}
                id="file"
                type="file"
                onChange={fileSelect}
            />
            <MyButton className={cl.button} onClick={add_windows_location_file}>Добавить</MyButton>
        </div>
    );
};

export default AddWindowsLocation;