
import './App.css';
import AppRouter from "./components/app_router";
import {BrowserRouter} from "react-router-dom";

function App() {
  return (
    <div className="App">

        <BrowserRouter>
            <AppRouter/>
        </BrowserRouter>
    </div>
  );
}

export default App;
