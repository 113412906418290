import server_constants from "./api/server_constants";
import AdministrationAPI from "./api/AdministrationAPI";

export default class global_functions{

    static convert_data(date) {
        let d = date.split("-");
       return(d[2] + "." + d[1] + "." + d[0]);
    }
    static convertDateToBaseFormat(date) {
        let d = date.split(".");
        return(d[2] + "-" + d[1] + "-" + d[0]);
    }

    static showFormData(formData){
        for (let key of formData.keys()) {
            console.log(`${key}: ${formData.get(key)}`);
        }
    }
    static convertAmountString(AmountString){
        if(AmountString==="") return 0;
        if(AmountString[0]==0) AmountString=AmountString.slice(1, AmountString.length)
        let str=AmountString;
        let len;
        let str_formated="";

        if (str.indexOf(".") === -1)
            len=3
        else
            len=str.length-str.indexOf(".");

        while(str.length>len){
            let a=str.slice(str.length-len, str.length);

            if (str_formated!="")
                if(str_formated[0]==".")
                    str_formated=a+str_formated
                else
                    str_formated=a+" "+str_formated
            else
                str_formated=a;

            str=str.slice(0,str.length-len);
            len=3;
        }

        if(str_formated!="") str_formated=str+" "+str_formated; else str_formated=str;

        return str_formated;
    }

    static async GetArea(setAreasOption, setArea, UserId) {
        let temp=[];
        const res = await server_constants.getArea(UserId);
        res.data.map((el)=>{
            temp=[...temp, {value:el.area_id, label:el.area_name}];
        })
        setAreasOption(temp);
        setArea({value:temp[0].value, label:temp[0].label});
    }

    static async GetQueues(AreaId, UserId) {
        let temp=[];
        const res = await server_constants.get_queues(AreaId, UserId);
        res.data.map((el)=>{
            temp=[...temp, {value:el.queue_id, label:el.queue_name}];
        })
        temp=[...temp, {value:0, label:"Без очереди"}];
        return temp;
    }
    static async GetHouses(AreaId, QueueId="", UserId) {
        let temp=[];
        if (AreaId!==undefined) {
            const res = await server_constants.get_houses(AreaId, QueueId, UserId);
            res.data.map(el => {
                temp = [...temp, {value: el.house_id, label: el.house_num, floors:el.floor_count}];
            });
            return temp;
        }
    }
    static async GetSections(house_id, UserId){
        let temp=[];
        const res = await server_constants.get_sections(house_id, UserId);
        if(res.data!="No data")
            res.data.map(el=>{
                temp=[...temp, {value:el.section_id, label:el.section_name}];
            });
       return temp;
    }
     static async GetDepartments(UserId){
        const res=await AdministrationAPI.getDepartments(UserId);
        let temp=[];
        res.data.map((el)=>{
            temp=[...temp, {value:el.department_id, label:el.name}];
        })
        return temp;
    }
    static async getContractors(UserId){
        let temp=[];
        const res = await server_constants.get_contractors(UserId);
        res.data.map(el=>{
            temp=[...temp, {value:el.ContractorId, label:el.ContractorName, ContractorPerson:el.ContractorPerson, ContractorPhone:el.ContractorPhone}];
        });
       return temp;
    }
}
