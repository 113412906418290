import {create} from "zustand";
const useStore = create((set) => ({
    url:'http://crm.sibusadba.ru/',
    auth:false,
    AreaId:1,
    setAreaId: (Id) =>
        set((state) => ({
            AreaId: Id
        })),
    setAuth: (id) =>
        set((state) => ({
            auth: id
        })),
    User:{
        Fio:'',
        Id:'',
        Department:'',
        Contractor:'',
        SubPages:''
    },
    setUser:(Fio, Id, Department, Contractor, SubPages)=>
        set((state) => ({
        User:{Fio: Fio, Id:Id, Department:Department, Contractor: Contractor, SubPages:SubPages}
    })),
    addDoc_button:false,
    setAddDoc_button:(st)=>set((state)=>({addDoc_button:st})),
    addDoc_loading:false,
    setDoc_loading:(st)=>set((state)=>({addDoc_loading:st})),
    material_properties:{
        area_id:0,
        house_id:0,
        section_id: 0,
        floor_id:0,
    },
    setMaterial_properties:(area_id,house_id,section_id, floor_id)=>(
        set((state)=>({material_properties:{
            area_id:area_id,
            house_id:house_id,
            section_id:section_id,
            floor_id:floor_id
            }})))
}));
export default useStore;