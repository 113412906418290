import React, {useEffect} from 'react';
import {useState} from 'react';
import cl from "./main.module.css"
import SubPagesPanel from "./SubPagesPanel/SubPagesPanel";
import PagePanel from "./PagePanel/PagePanel";

const Main = () => {
    const [SubPage, setSubPage]=useState(0);
    const [SubPages, setSubPages]=useState([]);


    useEffect(() => {
        setSubPage(0)
    }, [SubPages]);

    return (
        <>
            <PagePanel
                setSubPages={setSubPages}
                setSubPage={setSubPage}
            />
            <div className={cl.constructor}>
                <div className={cl.left}>
                    <SubPagesPanel
                        SubPages={SubPages}
                        setSubPage={setSubPage}
                        SubPage={SubPage}
                    />
                </div>
                <div className={cl.window} id="main_container">
                    {
                       SubPage?SubPage:""
                    }
                </div>
            </div>

        </>
    );
};

export default Main;