import React from 'react';
import materials_server_requests from "../api/materials_server_requests";
import {useEffect, useState} from "react";
import useStore from "../Store";
import cl from "./PtoMaterial.module.css";
import Select from "react-select";

const PtoMaterials = () => {
    const add_but=useStore((state)=>state.addDoc_button);
    const setAdd_but=useStore((state)=>state.setAddDoc_button)
    const [houses,setHouses]=useState([]);
    const [house,setHouse]=useState();
    const [sections,setSections]=useState([]);
    const [areas,setAreas]=useState([]);
    const [area,setArea]=useState();
    const [sectionOptions,setSectionOptions]=useState([]);
    const [isLoading,setIsLoading]=useState(false);
    const [materials, setMaterials]=useState([]);
    const [unique_materials, setUnique_materials]=useState([]);

    async function get_houses(area_id) {
        const res = await materials_server_requests.get_houses(area_id);
        //  console.log(res);
        setHouses(res.data)
        setHouse(res.data[0].house_id);
    }
    async function get_sections(house_id) {
        let a=[];
        const res = await materials_server_requests.get_sections(house_id);
   //      console.log(res);
        res.data.map(el=>{
            a=[...a, {value:el.section_id, label:el.section_name}];
        });
        setSectionOptions(a);
  //      console.log(a);
        setIsLoading(true);
    }

    async function get_area() {
        const res = await materials_server_requests.get_area();
//        console.log(res);
        setAreas(res.data);
        setArea(res.data[0].area_id);
        setAdd_but(false);
    }
    async function get_materials_multi_section(sections){
        if(sections[0]) {
            let material = new Set();
            let temp_materials = [];
            let sec = sections.map(el => el.section_id);

 //           console.log(sec.toString());
            const res = await materials_server_requests.get_materials_multi_sections(sec);
  //           console.log(res);
            res.data.map((el) => {
                material.add(el.material_name)
            });
            material.forEach((mat_name) => {
                res.data.map((el) => {
                    if (mat_name == el.material_name) temp_materials = [...temp_materials, {
                        material_name: mat_name,
                        material_measure: el.material_measure_name,
                        section_name: el.section_name,
                        count: el.material_count,
                        floor_name:el.floor_name,
                        start_date:el.start_date,
                        end_date:el.end_date
                    }]
                })
            });
            //  console.log(temp_materials)
            setUnique_materials(Array.from(material));
            setMaterials(temp_materials);
        }
    }
    function edit_area_option(area_id){
        setArea(area_id);
        setMaterials([]);
        setUnique_materials([]);
        setSections([]);
    }
    function edit_houses_option(house_id){
        setIsLoading(false);
        setHouse(house_id);
        setMaterials([]);
        setUnique_materials([]);
        setSections([]);
    }
    function edit_section_options(newValue){
 //      console.log(newValue)
        let temp_sections=[];
        newValue.map(v=>{temp_sections=[...temp_sections, {section_name:v.label, section_id:v.value}]})
        setSections(temp_sections);
        setMaterials([]);
        setUnique_materials([]);
    }
    useEffect(()=>{get_area();},[add_but]);
    useEffect(()=>{if(area)get_houses(area);},[area]);
    useEffect(()=>{if(house)get_sections(house);},[house]);
    useEffect(()=>{if(sections)get_materials_multi_section(sections);},[house, sections]);
//    console.log(sections);
    if(isLoading)
        return (
            <>
                <div className={cl.mainDiv}>
                    <select className={cl.area} name='area' value={area} onChange={(e)=>edit_area_option(e.target.value)}>
                        {/*<option>Choose a document type</option>*/}
                        {areas.map((ar,index)=>{
                            return (<option key={ar.area_id} value={ar.area_id}>{ar.area_name}</option>);
                        })
                        }
                    </select>
                    <select className={cl.house} name='house' value={house} onChange={(e)=>edit_houses_option(e.target.value)}>
                        {/*<option>Choose a document type</option>*/}
                        {houses.map((h,index)=>{
                            return (<option key={h.house_id} value={h.house_id}>{h.house_num}</option>);
                        })
                        }
                    </select>
                    <Select className={cl.sec} isMulti  options={sectionOptions} onChange={edit_section_options}/>
                </div>
                <div className={cl.divTable}>
                <table className={cl.table}>
                    <th>Наименование материала</th>
                   {sections[0]&&sections.map((sec)=>(<th>{"Секция "+sec.section_name}</th>))}
                    <th>Итого</th>
                    {unique_materials[0]&&unique_materials.map((un_mat)=>{
                        let result=[];
                        let sections_sum=0;
                        let measure;
                        return (
                            <tr>
                                <td>{un_mat}</td>
                                {sections.map((sec)=>{
                                    let exist=false;
                                    let sum=0;
                                    materials.map((mat)=>{
                                        if (un_mat==mat.material_name && sec.section_name==mat.section_name){
                                            sum=sum+Number(mat.count);
                                            sections_sum=sections_sum+Number(mat.count);
                                            measure=mat.material_measure;
                                            exist=true;
                                        }
                                    })
                                    if(exist)result.push(<td>{sum+" "+measure}</td>); else result.push(<td></td>)
                                })}
                                {result}
                                <td>{sections_sum+" "+measure}</td>
                            </tr>);
                    })}
                </table>
                </div>
                {sections[0]&&sections.map((sec)=>{
                    let floor=new Set();
                    let unq_materials=new Set();
                    materials.map((mat)=>{
                        floor.add(mat.floor_name)
                        if(sec.section_name==mat.section_name) unq_materials.add(mat.material_name)
                    })
                    return(
                        <div className={cl.floor_section}>
                            <h3>секция {sec.section_name}</h3>
                            <table className={cl.table}>
                                <th>Наименование материала</th>
                                {Array.from(floor).map((fl)=>(<th>{fl}</th>))}
                                {Array.from(unq_materials).map((u_mat)=>{
                                    let result=[]
                                    return(
                                        <tr>
                                            <td>{u_mat}</td>
                                            {Array.from(floor).map((fl)=>{
                                                let count=0;
                                                let measure="";
                                                let exist=false;

                                                let sec_mat=materials.filter(el=>el.section_name==sec.section_name);
                                                sec_mat.map(mat=>{
                                                    if(mat.floor_name==fl && mat.material_name==u_mat)
                                                    {count=mat.count; measure=mat.material_measure; exist=true}
                                                })
                                                if(exist)result.push(<td>{count+" "+measure}</td>); else result.push(<td></td>)
                                            })}
                                            {result}
                                        </tr>)
                                })}
                            </table>
                        </div>
                    )
                })}
            </>
        );else return 'loading...';
};

export default PtoMaterials;