import React from 'react';
import cl from "../../../SubPagesPanel/SubPagesPanel.module.css"

const AddLetterIcon = () => {
    return (
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" strokeLinejoin="round" strokeWidth="2"
             viewBox="0 0 140 120">
            <path
                d="m123.98,37.83a3,3 0 0 0 -4.2,-0.61l-48.39,36.12a1,1 0 0 1 -1.2,0l-47.44,-36.28a3,3 0 1 0 -3.64,4.77l37.3,28.53l-36.89,27.8a3,3 0 1 0 3.61,4.84l38.23,-28.86l5.18,4a7,7 0 0 0 8.43,0.06l5.44,-4.06l38.68,28.86a3,3 0 1 0 3.59,-4.81l-37.26,-27.84l37.95,-28.35a3,3 0 0 0 0.61,-4.17z"/>
            <path
                d="m120,24.5l-98,0a15,15 0 0 0 -15,15l0,60a15,15 0 0 0 15,15l98,0a15,15 0 0 0 15,-15l0,-60a15,15 0 0 0 -15,-15zm9,75a9,9 0 0 1 -9,9l-98,0a9,9 0 0 1 -9,-9l0,-60a9,9 0 0 1 9,-9l98,0a9,9 0 0 1 9,9l0,60z"/>
            <rect id={cl["ToFill"]} fill="#ffffff" height="42.5" width="59" x="42.77" y="6.09"/>
            <path
                d="m90.39,23.66l-15.03,0l0,-12.94c0,-1.36 -1.29,-2.47 -2.86,-2.47s-2.86,1.11 -2.86,2.47l0,12.94l-15.03,0c-1.57,0 -2.86,1.11 -2.86,2.47s1.29,2.47 2.86,2.47l15.03,0l0,12.94c0,1.36 1.29,2.47 2.86,2.47s2.86,-1.11 2.86,-2.47l0,-12.94l15.03,0c1.57,0 2.86,-1.11 2.86,-2.47s-1.29,-2.47 -2.86,-2.47z"/>
        </svg>
    );
};

export default AddLetterIcon;