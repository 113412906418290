import React, {useEffect, useState} from 'react';
import prescription_server_requests from "../../api/prescription_requests";
import cl from "./show_prescription_building_control.module.css"
import Show_prescriptions from "../show_prescriptions";

const ShowPrescriptionBuildingControl = () => {
    const [prescriptions, setPrescriptions]=useState([]);

    async function get_prescriptions(){
        const res=await prescription_server_requests.get_prescriptions();
        setPrescriptions(res.data);
        console.log(res)
    }

    useEffect(()=>{get_prescriptions()},[])

    return (
        <Show_prescriptions
            prescriptions={prescriptions}
        />
    );
};

export default ShowPrescriptionBuildingControl;