import React, {useEffect, useState} from 'react';
import Select from "react-select";
import materials_server_requests from "../api/materials_server_requests";
import cl from "./add_material_from_excel.module.css";
import InputMask from "react-input-mask";
import MyButton from "../UI/MyButton/MyButton";
import * as XLSX from 'xlsx'


const AddMaterialFromExcel = () => {
    const [areas,setAreas]=useState([]);
    const [area, setArea]=useState();
    const [houses, setHouses]=useState([]);
    const [house, setHouse]=useState();
    const [sections, setSections]=useState([]);
    const [section, setSection]=useState();
    const [file,setFile]=useState();
    const [status, setStatus]=useState('');

   async function callback_to_parse_excel(data){
      // console.log(data)
        const res=JSON.stringify(data)
        setStatus("Идет добавление...");
        const result=await materials_server_requests.add_material_from_excel(res);
        console.log(result)
        if(result) setStatus("Добавлено "+result.data+" строк");
    }
    function parce_excel_file(file, house_id, section_id,  callback_to_parse_excel){
        document.getElementById("file").value="";
        let result={house_id:house_id, section_id:section_id, materials:[]};
        const reader=new FileReader();
        reader.readAsBinaryString(file);
        reader.onload=(e)=>{
            var materials=[];
            const data = e.target.result;
            let a=["","A", "B", "C", "D", "E", "F", "G","H", "I", "J", "K", "L", "M","N", "O", "P", "Q", "R", "S","T", "U", "V", "W", "X", "Y","Z"];
            const workbook=XLSX.read(data,{type:"binary", cellStyles:true});
            const sheetName=workbook.SheetNames[0];
            const sheet=workbook.Sheets[sheetName];
            let row=2;
            while (sheet["A"+row]!==undefined){
                let material_name=sheet["A"+row].v;
                let material_measure=sheet["B"+row].v;
                let material_type=sheet["C"+row].v;
                let material=[];
                let i=4;
                let column=a[i];
                while (sheet[column+row]!==undefined){
                    material=[...material, {floor : sheet[column+"1"].v, count : sheet[column+row].v.toFixed(2)}]
                   i++;
                   column=a[i];
                  }
                row=String(Number(row)+1);
                materials=[...materials, {material_name:material_name, material_measure:material_measure, material_type:material_type, material_count_floor:material}]
            }
            result.materials=materials;
            callback_to_parse_excel(result)
        }
    }
    async function get_area() {
        let temp=[];
        const res = await materials_server_requests.get_area();
     //   console.log(res);
        res.data.map((el,num)=>{
            temp=[...temp, {value:el.area_id, label:el.area_name}];
        })
   //     console.log(temp)
        setAreas(temp);
        setArea(temp[0]);
    }
    async function get_houses(area) {
 //       console.log(area)
        let temp=[];
        const res = await materials_server_requests.get_houses(area.value);
        res.data.map((el,num)=>{
            temp=[...temp, {value:el.house_id, label:el.house_num}];
        })
        setHouses(temp)
        setHouse(houses[0]);
    }
    async function get_sections(house) {
        let temp=[];
        const res = await materials_server_requests.get_sections(house.value);
    //    console.log(res);
        res.data.map(el=>{
            temp=[...temp, {value:el.section_id, label:el.section_name}];
        });
        setSections(temp);
        if(temp[0]===undefined)setSection(null); else setSection(temp[0])
    }
    function fileSelect(e){
        setFile(e.target.files[0])
    }
    function house_change(value){
        setHouse(value);
    }
    function area_change(value){
        setArea(value);
    }
    function section_change(value){
        setSection(value);
    }
    async function add_material_from_excel(){
        const data=parce_excel_file(file,house.value, section.value, callback_to_parse_excel);
  ///      const res = await materials_server_requests.add_material_from_excel(data);
    }

    useEffect(()=>{get_area()},[])
    useEffect(()=>{if(area)get_houses(area)},[area])
    useEffect(()=>{if(house)get_sections(house); setSection(null)},[house])
  //  console.log(section)
    return (
        <>
        <div className={cl.mainDiv}>
            <a target="_blank" href="http://crm.yourprivelege.ru/add_material_example.xlsx">Excel file example</a>
            <Select options={areas} className={cl.area} value={area} onChange={area_change}/>
            <Select options={houses} className={cl.house} value={house} onChange={house_change}/>
            <Select options={sections} className={cl.section} value={section} onChange={section_change}/>
            <InputMask
                id="file"
                type="file"
                className={cl.file}
                onChange={fileSelect}
            />
            <MyButton className={cl.button} onClick={add_material_from_excel}>Добавить</MyButton>
        </div>
            <div>
                {status}
            </div>
        </>
    );
};

export default AddMaterialFromExcel;