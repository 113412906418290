import React, {useEffect, useState} from 'react';
import cl from "./mapLegend.module.css"
import mapServerRequests from "../../api/mapServerRequests";
import useStore from "../../Store";
const MapLegend = () => {
    const [mapWorks, setMapWorks]=useState([]);
    const[isLoading, setIsLoading]=useState(false)
    const [minimize, setMinimize]=useState(false)
    const UserId=useStore((state)=>state.User).Id;

    function minimizeLegend(){
        setMinimize(minimize=>!minimize);
    }

    useEffect(() => {
        mapServerRequests.getMapWorks(UserId)
            .then(({data})=>{
                setMapWorks(data);
                setIsLoading(true)
            });
    }, []);


    if(isLoading)
    return (
        <div className={cl.container}>
            <div className={cl.minimize} onClick={minimizeLegend}>__</div>
            {   !minimize?
                mapWorks.map(element=>{
                    return (
                        <div className={cl.colorContainer}>
                            <div>{element.map_work_name}</div>
                            {/*<div className={cl.color} style={{background: element.c10}}/>*/}
                            {/*<div className={cl.color} style={{background: element.c20}}/>*/}
                            {/*<div className={cl.color} style={{background: element.c30}}/>*/}
                            {/*<div className={cl.color} style={{background: element.c40}}/>*/}
                            {/*<div className={cl.color} style={{background: element.c50}}/>*/}
                            {/*<div className={cl.color} style={{background: element.c60}}/>*/}
                            {/*<div className={cl.color} style={{background: element.c70}}/>*/}
                            {/*<div className={cl.color} style={{background: element.c80}}/>*/}
                            {/*<div className={cl.color} style={{background: element.c90}}/>*/}
                            <div className={cl.color} style={{background: element.c100}}/>
                        </div>
                    );
                }):""
            }
        </div>
    );
};

export default MapLegend;