import React from 'react';
import Select from "react-select";
import cl from "./ShowGprHouse.module.css";
import {useEffect, useState} from "react";
import global_functions from "../../global_functions";


const ShowGprHouse = () => {
    const [HousesOption,setHousesOption]=useState([]);
    const [House,setHouse]=useState(null);
    const [AreasOption,setAreasOption]=useState([]);
    const [Area, setArea]=useState();
    const [QueuesOption,setQueuesOption]=useState([]);
    const [Queue, setQueue]=useState();
    const [isLoading, setIsLoading]=useState(false);

    function EditAreasOption(value){
        setArea(value);
        setQueue(null);
        setHouse(null);
    }
    function EditQueuesOption(value){
        setQueue(value);
        setHouse(null);
    }
    function EditHousesOption(value){
        setHouse(value);
    }

    useEffect(()=>{global_functions.GetArea(setAreasOption, setArea); setIsLoading(true)},[])
    useEffect(()=>{if(Area)global_functions.GetQueues(Area.value).then(res=>setQueuesOption(res));},[Area])
    useEffect(()=>{if(Area&&Queue)global_functions.GetHouses(Area.value, Queue.value).then(res=>setHousesOption(res))},[Area, Queue]);
    return (
        <div className={cl.containerShowGPRHouse}>
            <Select
                value={Area}
                className={cl.area}
                options={AreasOption}
                onChange={EditAreasOption}
            />
            <Select
                value={Queue}
                className={cl.queue}
                placeholder="Очередь"
                options={QueuesOption}
                onChange={EditQueuesOption}
            />
            <Select
                value={House}
                className={cl.house}
                placeholder="Дом"
                options={HousesOption}
                onChange={EditHousesOption}
            />
        </div>
    );
};

export default ShowGprHouse;