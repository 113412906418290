import React, {useEffect} from 'react';
import {QRCodeCanvas} from 'qrcode.react';


const GenerateQr = ({value, setQR_Image, size}) => {
   useEffect(()=>{
       const canvas = document.getElementById('QR_canvas');
       setQR_Image(canvas.toDataURL())
   },[])
    return (
        <div>
            <QRCodeCanvas size={size} id="QR_canvas" value={value} renderAs="canvas"/>
        </div>
    );
};

export default GenerateQr;