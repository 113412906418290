import React, {useState} from 'react';
import ServerRequests from "../../api/ServerRequests";
import {useEffect} from "react";
import moment from "moment";
import cl from "./requests_show.module.css"


const RequestsShow = ({contractor_id, setRequest_id, request_id}) => {
    const [requests, setRequests]=useState([]);
    const [isLoading, setIsloading]=useState(false);
    function convert_date(date){
        const d=date.split("-")
        return d[2]+"."+d[1]+"."+d[0];
    }
    function status(status){
        switch (status.toString()){
            case "0": return "редактирование"; break
            case "1": return "подана"; break
        }
    }
    async function get_requests(contractor_id){
        const res=await ServerRequests.get_requests_contractor(contractor_id);
//        console.log(res);
        const b=res.data.sort((a,b)=> moment(a.request_date, 'YY-MM-DD') - moment(b.request_date, 'YY-MM-DD'))
        setRequests(b);
        setIsloading(true);
    }
    function edit_request(e){
        if(e.target.getAttribute('data-status')==0){
            setRequest_id(e.target.id);
        }
    }
    useEffect(()=>{get_requests(contractor_id)},[request_id])

    if(isLoading)
    return (
        <>
            <h1>Заявки</h1>
            <table>
                <th>Номер дома</th>
                <th>Этаж</th>
                <th>Дата заявки</th>
                <th>Дата поставки</th>
                <th>Статус</th>
                {
                    requests[0]&&requests.map(req=>{
                        return(
                            <tr>
                                <td>{req.house_num}</td>
                                <td>{req.floor_name}</td>
                                <td>{convert_date(req.request_date)}</td>
                                <td>{convert_date(req.date_for)}</td>
                                <td className={req.status==0?cl.td:""} data-status={req.status} id={req.request_id} onClick={edit_request}>{status(req.status)}</td>
                            </tr>
                        );
                    })
                }
            </table>
        </>
    );
};

export default RequestsShow;