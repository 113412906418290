import React from 'react';
import cl from './MyInput.module.css'
import InputMask from "react-input-mask";

const MyInput = (props) => {
    return (
        <InputMask {...props} className={cl.MyInput+" "+props.className}>
            
        </InputMask>
    );
};

export default MyInput;