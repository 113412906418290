import React from 'react';
import House11 from "./house1.1";
import cl from "./statistic.module.css"
import House13 from "./house1.3";
import House14 from "./house1.4";
const Statistic = () => {

    return (
        <div className={cl.mainDiv}>
            <House14/>
            <House11/>
            <House13/>
        </div>
    );
};

export default Statistic;