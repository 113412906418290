import axios from "axios";
import server_constants from "./server_constants";
import global_functions from "../global_functions";

export default class ContractorsAPI {
    static url=this.checkUrl();
    static login = server_constants.login;
    static psw = server_constants.psw;

    static checkUrl(){
        let LocalUrl="http://127.0.0.1:8000/Contractors_api.php"
        let Url = server_constants.global_url+'Contractors_api.php';
        if(window.location.href.indexOf('localhost')!==-1) return LocalUrl;
        return Url;
    }

    static async getContractorSections(UserId, ContractorId, HouseId, WorkId) {
        const responce = await axios.get(this.url, {
            params: {
                Action: 'getContractorSections',
                ContractorId:ContractorId,
                HouseId:HouseId,
                ContractorWorkId:WorkId,
                UserId:UserId,
                login: this.login,
                password: this.psw
            }
        });
        return responce;
    }
    static async getContractorWorks(UserId) {
        const responce = await axios.get(this.url, {
            params: {
                Action: 'getContractorWorks',
                UserId:UserId,
                login: this.login,
                password: this.psw
            }
        });
        return responce;
    }
    static async addContractor(ContractorName, ContractorPerson, ContractorPhone, SectionsData, ContractorWork, UserId) {
        const formData = new FormData();
        formData.append('Action', 'addContractor');
        formData.append('ContractorName', ContractorName);
        formData.append('ContractorWork', ContractorWork);
        formData.append('ContractorPerson', ContractorPerson);
        formData.append('ContractorPhone', ContractorPhone);
        formData.append('SectionsData', JSON.stringify(SectionsData));
        formData.append('UserId', UserId);
        formData.append('login', this.login);
        formData.append('password', this.psw);
        global_functions.showFormData(formData)
        const responce = await axios.post(this.url, formData);
        return responce;

    }
}