import React, {useEffect, useState} from 'react';
import House11 from "./houses/1.1/house1.1";
import cl from "./map.module.css"
import House13 from "./houses/1.3/house1.3";
import House14 from "./houses/1.4/house1.4";
import mapServerRequests from "../api/mapServerRequests";
import MapModal from "./addMapInfo/mapModal/mapModal";
import MapLegend from "./mapLegend/mapLegend";
import Select from "react-select";
import Loading from "../UI/Loading/loading";
import global_functions from "../global_functions";
import CreatableSelect from "react-select/creatable";
import useStore from "../Store";

const Map = () => {
    const [onClickSectionId,  setOnClickSectionId]=useState(null);
    const [sections, setSections]=useState([])
    const [isLoading, setIsloading]=useState(false)
    const [modalVisible, setModalVisible]=useState(false);
    const [dateOption, setDateOption]=useState([]);
    const [date, setDate]=useState(null);
    const [reloading, setReloading]=useState(0);
    const [allPageLoad, setAllPageLoad]=useState(false)
    const UserId=useStore((state)=>state.User).Id;
    const AreaId=useStore((state)=>state.AreaId)

    let house14NotesId=[["house14section1", "house14section2", "house14section3", "house14section4", "house14section5", "house14section6", "house14section7", "house14section8", "house14section9", "house14section10", "house14Name"],
                               ["rotate(180deg)","rotate(-90deg)","rotate(180deg)","rotate(0deg)","rotate(180deg)","rotate(90deg)","rotate(180deg)","rotate(180deg)","rotate(180deg)","rotate(180deg)", "rotate(180deg)"]];
    let house32NotesId=[["house32section1", "house32section2", "house32section3", "house32section4", "house32section5", "house32section6", "house32section7", "house32section8", "house32section9", "house32section10", "house32Name"],
                                ["rotate(180deg)","rotate(-90deg)","rotate(180deg)","rotate(0deg)","rotate(180deg)","rotate(90deg)","rotate(180deg)","rotate(180deg)","rotate(180deg)","rotate(180deg)", "rotate(180deg)"]];
    let house22NotesId=[["house22section1", "house22section2", "house22section3", "house22section4", "house22section5", "house22section6", "house22section7", "house22section8", "house22section9", "house22section10", "house22Name"],
                               ["rotate(0deg)","rotate(90deg)","rotate(0deg)","rotate(180deg)","rotate(0deg)","rotate(-90deg)","rotate(0deg)","rotate(0deg)","rotate(0deg)","rotate(0deg)", "rotate(0deg)"]];

    function rotateHouse14Letters() {
        house14NotesId[0].map((element, index) => {
            const abc = document.getElementById(element).style.transform = house14NotesId[1][index]
        })
    }

    function rotateHouse32Letters(){
        house32NotesId[0].map((element, index)=>{
            const abc = document.getElementById(element).style.transform = house32NotesId[1][index]
        })
    }

    function rotateHouse22Letters(){
        house22NotesId[0].map((element, index)=>{
              const abc = document.getElementById(element).style.transform = house22NotesId[1][index]
        })
    }

    function editDateOption(value){
        setDate(value)
    }
    function AddDate(value){
        setDate({value:global_functions.convertDateToBaseFormat(value), label:value})
    }

    useEffect(() => {
        mapServerRequests.getDates(UserId).then(res=> {
            let temp=[];
            res.data.map((el, index) => {
                temp = [...temp, {value: el.date, label: global_functions.convert_data(el.date)}];
            })
            setDateOption(temp)
            setDate(temp[temp.length-1])
        })

    }, []);

    useEffect(() => {
        if(date)
            mapServerRequests.getAllSectionsDate(1, date.value, UserId)
                .then(({data})=>{
                    setSections(data);
                    setIsloading(true)
                })
    }, [date]);

    useEffect(() => {
        if(onClickSectionId) setModalVisible(true);
    }, [onClickSectionId]);

    useEffect(() => {
        if(allPageLoad){
            rotateHouse14Letters();
            rotateHouse32Letters();
            rotateHouse22Letters();
        }
    }, [allPageLoad]);

    useEffect(() => {
        if(reloading)
            mapServerRequests.getAllSectionsDate(AreaId,date.value, UserId)
                .then(res=>
                    setSections(res.data)
                );
    }, [reloading]);

    if(isLoading)
        return (
            <div>
                <div className={cl.DateSelect}>
                    <CreatableSelect
                        value={date}
                        options={dateOption}
                        onChange={editDateOption}
                        onCreateOption={AddDate}
                    />
                </div>
                <div className={cl.container}>
                    <MapLegend/>
                    <div className={cl.containerMap}>
                        <div className={cl.containerAmundsena}>
                            <House11
                                name={"2.1"}
                                sections={sections.filter(element => element.house_num === "Дом №2.1")}
                                setOnClickSectionId={setOnClickSectionId}
                            />
                            <House11
                                name={"1.2"}
                                sections={sections.filter(element => element.house_num === "Дом №1.2")}
                                setOnClickSectionId={setOnClickSectionId}
                            />
                            <House11
                                name={"1.1"}
                                sections={sections.filter(element => element.house_num === "Дом №1.1")}
                                setOnClickSectionId={setOnClickSectionId}
                            />
                            <div className={cl.container1323}>
                                <House13
                                    name={"1.3"}
                                    sections={sections.filter(element => element.house_num === "Дом №1.3")}
                                    setOnClickSectionId={setOnClickSectionId}
                                />
                                <House13
                                    name={"2.3"}
                                    sections={sections.filter(element => element.house_num === "Дом №2.3")}
                                    setOnClickSectionId={setOnClickSectionId}
                                />
                            </div>
                            <div className={cl.container14}>
                                <House14
                                    name={"1.4"}
                                    notesId={house14NotesId[0]}
                                    sections={sections.filter(element => element.house_num === "Дом №1.4")}
                                    setOnClickSectionId={setOnClickSectionId}
                                />
                            </div>
                            <div className={cl.container32}>
                                <House14
                                    name={"3.2"}
                                    notesId={house32NotesId[0]}
                                    sections={sections.filter(element => element.house_num === "Дом №3.2")}
                                    setOnClickSectionId={setOnClickSectionId}
                                />
                            </div>
                            <div className={cl.container22}>
                                <House14
                                    name={"2.2"}
                                    notesId={house22NotesId[0]}
                                    sections={sections.filter(element => element.house_num === "Дом №2.2")}
                                    setOnClickSectionId={setOnClickSectionId}
                                />
                            </div>
                        </div>

                    </div>
                    <MapModal
                        visible={modalVisible}
                        setVisible={setModalVisible}
                        sectionId={onClickSectionId}
                        setReloading={setReloading}
                        setAllPageLoad={setAllPageLoad}
                        Date={date}
                    />
                </div>
            </div>
        );
    else <Loading/>
};

export default Map;