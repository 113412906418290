import axios from "axios";
import server_constants from "./server_constants";

export default class windows_server_requests{
    static url=this.checkUrl();
    static login = server_constants.login;
    static psw = server_constants.psw;


    static checkUrl(){
        let LocalUrl="http://127.0.0.1:8000/windows_api.php"
        let Url = server_constants.global_url+'windows_api.php';
        // if(window.location.href.indexOf('localhost')!==-1) return LocalUrl;
        return Url;
    }
    static async get_area(UserId){
        return server_constants.getArea(UserId);
    }
    static async get_houses(area_id, UserId) {
            return server_constants.get_houses(area_id, "", UserId);
    }
    static async get_locations(house_id, UserId){
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_locations',
                house_id: house_id,
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async get_windows_statuses(UserId){
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_windows_statuses',
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async get_location(location_id, UserId){
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_location',
                location_id: location_id,
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async get_windows(location_id, UserId){
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_windows',
                location_id:location_id,
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async get_windows_statistic(house_id, day_count, UserId){
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_windows_statistic',
                house_id:house_id,
                day_count:day_count,
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async add_location_name(house_id, location_name, UserId){
        const formData=new FormData();
        formData.append('action','add_location_name');
        formData.append('house_id',house_id);
        formData.append('location_name',location_name);
        formData.append('UserId', UserId);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=await axios.post(this.url, formData);
        return responce;
    }
    static async add_location_file(location_id, file, UserId){
        const formData=new FormData();
        formData.append('action','add_location_file');
        formData.append('location_id',location_id);
        formData.append('file', file);
        formData.append('UserId', UserId);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=await axios.post(this.url, formData);
        return responce;
    }
    static async add_windows(house_id, location_id, windows, UserId){
        const formData=new FormData();
        formData.append('action','add_windows');
        formData.append('house_id',house_id);
        formData.append('location_id',location_id);
        formData.append('windows', JSON.stringify(windows));
        formData.append('UserId', UserId);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=await axios.post(this.url, formData);
        return responce;
    }
    static async add_windows_statuses(windows, UserId){
        console.log(JSON.stringify(windows));
        const formData=new FormData();
        formData.append('action','add_windows_statuses');
        formData.append('windows', JSON.stringify(windows));
        formData.append('UserId', UserId);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=await axios.post(this.url, formData);
        return responce;
    }
}