import React from 'react';

const ExecutiveDocumentationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
             strokeLinejoin="round">
            <rect x="3" y="2" width="18" height="20" rx="2" ry="2"/>
            <path d="M12 18h0M9 15h6M9 12h6"/>
        </svg>

    );
};

export default ExecutiveDocumentationIcon;

