import React, {useState} from 'react';
import Select from "react-select";
import cl from "./ChangeYear.module.css";

const ChangeYear = ({ChangeYear}) => {
    let YearOptions=[
        {value:'1', label:'2023'},
        {value:'2', label:'2024'}
    ]
    const [year, setYear]=useState({value:'2', label:'2024'});


    function EditYearOptions(value){
        setYear(value);
        ChangeYear(value);
    }
    return (
            <Select options={YearOptions} className={cl.select} value={year} onChange={EditYearOptions}/>
    );
};

export default ChangeYear;