import React, {useEffect} from 'react';
import ServerRequests from "../../../api/ServerRequests";
import {useState} from "react";
import Add_row from "./add_row";
import MyButton from "../../../UI/MyButton/MyButton";
import cl from "./request_edit.module.css"


const RequestEdit = ({request_id, setRequest_id}) => {

    const [isLoading, setIsLoading]=useState(false);
    const [house, setHouse]=useState();
    const [section, setSection]=useState();
    const [floor, setFloor]=useState();
    const [date, setDate]=useState();
    const [date_for, setDate_for]=useState();
    const [add_button_click, setAdd_button_click]=useState(false);
    const [request_rows, setRequest_rows]=useState([]);
    function convert_date(date){
        const d=date.split("-")
        return d[2]+"."+d[1]+"."+d[0];
    }
    async function get_request_rows(request_id){
        const res=await ServerRequests.get_request_rows(request_id);
  //      console.log(res);
        setRequest_rows(res.data);
        setHouse(res.data[0].house_num);
        setSection(res.data[0].section_name);
        setFloor({floor_name:res.data[0].floor_name, floor_id:res.data[0].floor_id});
        setDate(res.data[0].request_date);
        setDate_for(res.data[0].date_for);
        setIsLoading(true);
    }
    async function send_request(){
        const res=await ServerRequests.send_request(request_id);
 //       console.log(res.data);
        setRequest_id(0);
    }
    async function delete_row(e){
        const res=await ServerRequests.delete_request_row(e.target.id);
 //       console.log(res);
        setAdd_button_click(true);
    }
    useEffect(()=>{get_request_rows(request_id); setAdd_button_click(false);},[add_button_click, request_id])
  //  console.log(request_rows);
    if(isLoading)
    return (
        <>
            <br></br>
            <br></br>
            <br></br>
        <h3>Заявка на метериалы от {convert_date(date)} </h3> <br></br>
            <h3>{house}, секция: {section}, этаж: {floor.floor_name} дата поставки {convert_date(date_for)}</h3>
            <table className={cl.table}>
                <th>Наимеование материала</th>
                <th>количество</th>
                <th></th>
                {
                    request_rows[0].material_count&&request_rows.map(el=>{
                      return(
                          <tr>
                              <td>{el.material_name}</td>
                              <td>{el.material_count} {el.material_measure_name}</td>
                              <td><div className={cl.delete} id={el.request_row_id} onClick={delete_row}>Удалить</div></td>
                          </tr>
                      );
                    })
                }
            </table>
            <Add_row
                floor_id={floor.floor_id}
                request_id={request_id}
                setAdd_button_click={setAdd_button_click}
            />
            <MyButton className={cl.button} onClick={send_request}>Отправить заявку</MyButton>
        </>
    );
};

export default RequestEdit;