import React, {useEffect, useState} from 'react';
import AdministrationAPI from "../../api/AdministrationAPI";
import cl from "./ChangeUserRights.module.css"
import MyButton from "../../UI/MyButton/MyButton";
import Select from "react-select";
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import Loading from "../../UI/Loading/loading";
import useStore from "../../Store";


const ChangeUserRights = () => {

    const [SubPages,setSubPages]=useState([])
    const [isLoading, setIsLoading]=useState(false)
    const [ManagersRight, setManagersRight]=useState({});
    const [ManagersOption, setManagersOption]=useState([]);
    const [User, setUser]=useState();
    const UserId=useStore((state)=>state.User).Id
    const AreaId=useStore((state)=>state.AreaId)


    async function getManagers(AreaId, UserId){
        let temp=[];
        const res=await AdministrationAPI.getUsers(AreaId, UserId);
        res.data.map((el,num)=>{
            temp=[...temp, {value:el.UserId, label:el.FIO, SubPages:el.manager_sub_pages}];
        })
        setManagersOption(temp);
        setIsLoading(true);
    }
    async function getUserRights(AreaId, ChangingUserId, UserId){
        let temp=[];
        const res=await AdministrationAPI.getUserRights(AreaId, ChangingUserId,UserId)

        temp=res.data[0].UserSubPagesEditable;
        return temp;
    }
    async function get_all_sub_pages(UserId){
        const res=await AdministrationAPI.getAllSubPages(UserId);
        setSubPages(res.data);
        setIsLoading(true)
    }
    async function SendNewRightsToServer(){
        let RightsString="(";
        for (let el in ManagersRight){
            if(ManagersRight[el]===true)
                if(RightsString.length===1)
                    RightsString+=el;
                else
                    RightsString+=","+el;
        }
        RightsString+=")";
        const res=await AdministrationAPI.changeUserRights(AreaId, User.value, RightsString, UserId);
        if(res.data==="OK")
            alert("Изменения успешно внесены")
        let a=await getUserRights(AreaId, User.value, UserId)
        setManagersRight(ManagerRightsToObject(a));
    }
    function ManagerRightsToObject(sub_pages){
        let ManagersRightObject={};
        let ManagerRights=sub_pages.replace("(","").replace(")","").split(",");  //delete () from string and split
        if (ManagerRights[0]==="")
            ManagersRightObject={1:false}
        else
            ManagerRights.map(el=>{
                ManagersRightObject[el]=true;
            })
        return ManagersRightObject;
    }
    function SwitchChange(event){
        setManagersRight({...ManagersRight, [event.target.id]:event.target.checked});
    }

   async function EditUserOption(value){
        setIsLoading(false);
        setUser(value);
        let a=await getUserRights(AreaId, value.value, UserId)
        setManagersRight(ManagerRightsToObject(a));
        setIsLoading(true)
    }

    useEffect(()=>{
        getManagers(AreaId, UserId);
        get_all_sub_pages(UserId);
        },[])

    if(isLoading)
        return (
            <>
                <Select options={ManagersOption} className={cl.select} value={User} onChange={EditUserOption}/>
                {Object.keys(ManagersRight).length!==0?   //checking is the user selected
                    <>
                    <table>
                        {
                            SubPages[0] && SubPages.map(el => {
                                if(el.sub_page_name)
                                    return (
                                        <tr key={el.sub_page_id}>
                                            <td>{el.sub_page_name}</td>
                                            <td>
                                                <Toggle
                                                    checked={ManagersRight[Number(el.sub_page_id)]===true ? true : false}
                                                    id={el.sub_page_id}
                                                    onChange={SwitchChange}
                                                />
                                            </td>
                                        </tr>)
                            })
                        }
                    </table>
                    <MyButton className={cl.button} onClick={SendNewRightsToServer}>Сохранить</MyButton>
                    </>:""
                }
            </>
        );
    else <Loading/>
};

export default ChangeUserRights;