import React from 'react';
import {useEffect, useState} from "react";
import global_functions from "../../global_functions";
import cl from "./addGPRHouseWorks.module.css"
import Select from "react-select";
import gpr_requests from "../../api/gpr_requests";
import Toggle from "react-toggle";
import "react-toggle/style.css"
import MyButton from "../../UI/MyButton/MyButton";

const AddGprHouseWorks = () => {
    const [HousesOption,setHousesOption]=useState([]);
    const [House,setHouse]=useState(null);
    const [AreasOption,setAreasOption]=useState([]);
    const [Area, setArea]=useState();
    const [QueuesOption,setQueuesOption]=useState([]);
    const [Queue, setQueue]=useState();
    const [isLoading, setIsLoading]=useState(false);
    const [GPRWorks, setGPRWorks]=useState([]);
    const [houseWorks, setHouseWorks]=useState({});

    async function getHouseWorks(houseId){
        let tempHouseWorks={};
        const res= await gpr_requests.getHouseWorks(houseId);

        res.data.map(element=>{
            tempHouseWorks[element.gpr_work_id]=true;
        })
        setHouseWorks(tempHouseWorks);
    }

    function EditAreasOption(value){
        setArea(value);
        setQueue(null);
        setHouse(null);
    }
    function EditQueuesOption(value){
        setQueue(value);
        setHouse(null);
    }
    function EditHousesOption(value){
        setHouse(value);
        getHouseWorks(value.value);
    }

    function onSwitchChange(event){
        setHouseWorks({...houseWorks, [event.target.id]:event.target.checked});
    }

    function sendNewGPRWorksToServer(){
        gpr_requests.editGPRWorks(House.value, houseWorks).then(({data})=>{if(data==="OK")alert("Изменения успешно сохранены"); setHouse(null)})
    }

    useEffect(()=>{global_functions.GetArea(setAreasOption, setArea); setIsLoading(true)},[])
    useEffect(()=>{if(Area)global_functions.GetQueues(Area.value).then(res=>setQueuesOption(res));},[Area])
    useEffect(()=>{if(Area&&Queue)global_functions.GetHouses(Area.value, Queue.value).then(res=>setHousesOption(res))},[Area, Queue]);
    useEffect(()=>{gpr_requests.getGPRWorks().then(({data})=>setGPRWorks(data))},[])

    return (
        <>
        <div className={cl.containerAddGPR}>
            <Select
                value={Area}
                className={cl.area}
                options={AreasOption}
                onChange={EditAreasOption}
            />
            <Select
                value={Queue}
                className={cl.queue}
                placeholder="Очередь"
                options={QueuesOption}
                onChange={EditQueuesOption}
            />
            <Select
                value={House}
                className={cl.house}
                placeholder="Дом"
                options={HousesOption}
                onChange={EditHousesOption}
            />
        </div>
            {House?<>
                    <table className={cl.table}>
                        {
                            GPRWorks[0] && GPRWorks.map(el => {
                                return (
                                    <tr>
                                        <td>{el.gpr_work_name}</td>
                                        <td>
                                            <Toggle
                                                checked={houseWorks[Number(el.gpr_work_id)]===true ? true : false}
                                                id={el.gpr_work_id}
                                                onChange={onSwitchChange}
                                            />
                                        </td>
                                    </tr>)
                            })
                        }
                    </table>
                    <MyButton className={cl.button} onClick={sendNewGPRWorksToServer}>Сохранить</MyButton>
                </>
                :""
            }
        </>
    );
};

export default AddGprHouseWorks;