import axios from "axios";
import server_constants from "./server_constants";

export default class projects_server_requests{
    static url=this.checkUrl();
    static login = server_constants.login;
    static psw = server_constants.psw;


    static checkUrl(){
        let LocalUrl="http://127.0.0.1:8000/projects_api.php"
        let Url = server_constants.global_url+'projects_api.php';
        if(window.location.href.indexOf('localhost')!==-1) return LocalUrl;
        return Url;
    }
    static async get_area(UserId){
        return server_constants.getArea(UserId);
    }
    static async get_houses(area_id, QueueId, UserId) {
         return server_constants.get_houses(area_id, QueueId, UserId);
    }
    static async get_queues(area_id, UserId) {
        return server_constants.get_queues(area_id, UserId);
    }
    static async get_project_divisions(UserId){
        const params={
            action: 'get_project_divisions',
            UserId:UserId,
            login:this.login,
            password:this.psw
        };

        const responce = await axios.get(this.url, {
            params
        });
        return responce;
    }
    static async get_project_stages(UserId){
        const params={
            action: 'get_project_stages',
            UserId:UserId,
            login:this.login,
            password:this.psw
        };

        const responce = await axios.get(this.url, {
            params
        });
        return responce;
    }
    static async add_project_division(project_division_name, UserId){
        const formData=new FormData();
        //      console.log(action);
        formData.append( 'action', 'add_project_division',);
        formData.append('project_division_name',project_division_name);
        formData.append('UserId',UserId);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=axios.post(this.url, formData);
        return responce;
    }
    static async add_project_stage(project_stage_name, UserId){
        const formData=new FormData();
        formData.append( 'action', 'add_project_stage',);
        formData.append('project_stage_name',project_stage_name);
        formData.append('UserId',UserId);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=axios.post(this.url, formData);
        return responce;
    }
    static async get_projects(house_id,  project_stage_id, project_division_id, UserId) {
        const params={
            action: 'get_projects',
            project_division_id:project_division_id,
            project_stage_id:project_stage_id,
            house_id: house_id,
            UserId:UserId,
            login: this.login,
            password: this.psw
        };
        const responce = await axios.get(this.url, {
            params
        });
       return responce;
    }
    static async add_project(house_id, house_name, project_division_id,project_division_name, project_stage_id, project_stage_name, notes, file, UserId){
        const formData=new FormData();
        //      console.log(action);
        formData.append('file', file);
        formData.append('action','add_project');
        formData.append('house_id',house_id);
        formData.append('house_name',house_name);
        formData.append('project_division_id',project_division_id);
        formData.append('project_division_name',project_division_name);
        formData.append('project_stage_id',project_stage_id);
        formData.append('project_stage_name',project_stage_name);
        formData.append('notes',notes);
        formData.append('UserId',UserId);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=axios.post(this.url, formData);
        return responce;
    }
    static async add_project_base64(PDFbase64, file_name, project_id, UserId){
        const formData=new FormData();
        formData.append('action','save_project_file');
        formData.append('pdf_based64', PDFbase64)
        formData.append('file_name',file_name);
        formData.append('project_id',project_id);
        formData.append('UserId',UserId);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=axios.post(this.url, formData);
        return responce;
    }
    static async GetProjectInfo(ProjectId, UserId) {
        const params={
            action: 'GetProjectInfo',
            project_id:ProjectId,
            UserId:UserId,
            login: this.login,
            password: this.psw
        };
        const responce = await axios.get(this.url, {
            params
        });
        return responce;
    }
}