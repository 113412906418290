import React, {useEffect, useState} from 'react';
import cl from './show_post.module.css'
import Edit_letter from "./edit_letter";
import Loading from "../UI/Loading/loading";

const ShowPost = ({setReload, post, direction}) => {
    const [letters,setLetters]=useState(post)
    const [letter_id, setLetter_id]=useState(0);
    const [isLoading, setIsLoading]=useState(true);

    function show_letter(e){
        const file=e.currentTarget.id;
        e.stopPropagation();
        if(file)
            window.open('http://crm.yourprivelege.ru/letters/'+file);
        else alert("Файл не найден!!!")
        // console.log('123');
    }
    function show_edit_letter(letter_id){
        if(letter_id!==0)
        return <Edit_letter setReload={setReload} letter_id={letter_id} setLetter_id={setLetter_id}/>
    }
    function edit_letter(e){
        e.stopPropagation();
        setIsLoading(false)
        setLetter_id(e.currentTarget.id);
        setIsLoading(true)
    }
    function from_change(e){
        setLetters(post.filter(el=> el.letter_from_name.toLowerCase().includes(e.target.value.toLowerCase())));
    }
    function to_change(e){
        setLetters(post.filter(el=> el.letter_to_name.toLowerCase().includes(e.target.value.toLowerCase())));
    }
    function notes_change(e){
        setLetters(post.filter(el=> el.letter_notes.toLowerCase().includes(e.target.value.toLowerCase())));
    }
  //  console.log(letters);
    useEffect(()=>{setLetters(post)},[post]);

    if(isLoading)
        return (
            <div className={cl.main_div}>
                {show_edit_letter(letter_id)}
                <h3 className={cl.h3}>{direction} корреспонденция</h3>
                <table>
                    <th>Номер</th>
                    <th>Дата</th>
                    <th className={cl.from}>От кого <br/><input onChange={from_change}/></th>
                    <th className={cl.to}>Кому <br/><input onChange={to_change}/></th>
                    <th className={cl.notes}>Примечание <br/><input onChange={notes_change}/></th>
                    <th>Скан</th>
                    <th></th>
                    {
                        letters[0]&&letters.map(el=>{
                            const d = el.letter_date.split('-');
                            return(
                                <tr>
                                    <td >{el.letter_number}</td>
                                    <td>{d[2]+"."+d[1]+"."+d[0]}</td>
                                    <td>{el.letter_from_name}</td>
                                    <td>{el.letter_to_name}</td>
                                    <td>{el.letter_notes}</td>
                                    <td id={el.letter_link} onClick={show_letter}  className={cl.td}>&ensp;download&ensp;</td>
                                    <td id={el.letter_id} onClick={edit_letter} className={cl.td}>&ensp;edit&ensp;</td>
                                </tr>
                            );
                        })
                    }
                </table>
            </div>
        );
    else <Loading/>
};

export default ShowPost;