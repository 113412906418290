import React from 'react';

const MaterialsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 140 120" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="#3EBBC4">
            <g>
                <g>
                    <path d="M97.9,93.3l-57.7,10.4C38,96.9,31.5,92,24,92c-9.4,0-17,7.6-17,17s7.6,17,17,17s17-7.6,17-17    c0-0.4,0-0.9-0.1-1.3l30.4-5.5l8.9,19c1.3,2.9,4.1,4.6,7.2,4.6c0.2,0,0.5,0,0.7,0c3.4-0.3,6.2-2.6,7-5.9l15.4-59.4H124v-4h-16.5    L97.9,93.3z M37,109c0,7.2-5.8,13-13,13s-13-5.8-13-13s5.8-13,13-13c5.6,0,10.3,3.5,12.2,8.5L22.1,107l0.7,3.9l14.2-2.6    C37,108.6,37,108.8,37,109z M91.3,118.9c-0.5,2-2.2,2.8-3.5,3c-1.4,0.1-3.1-0.4-4-2.3l-8.4-18.1l21.4-3.9L91.3,118.9z" fill="#3EBBC4"/>
                    <path d="M19,48H4v3.7l30.1,38.2c4.2,5.3,10.5,8.4,17.3,8.4c1.3,0,2.5-0.1,3.8-0.3L88,92.2c4.6-0.8,8.4-4.2,9.6-8.8    l8.4-32.2V48H89c-8.1-11.3-21.1-18-35-18S27.1,36.7,19,48z M93.7,82.4c-0.8,3-3.3,5.3-6.4,5.9L54.4,94c-1,0.2-2,0.3-3.1,0.3    c-5.6,0-10.7-2.5-14.1-6.9L9.3,52h92.3L93.7,82.4z M54,34c11.8,0,22.6,5.1,30,14H24C31.4,39.1,42.2,34,54,34z" fill="#3EBBC4"/>
                </g>
            </g>
        </svg>
    );
};

export default MaterialsIcon;


