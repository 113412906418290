import React from 'react';
import useStore from "../Store";
import SupplyDepartmentMaterials from "./SupplyDepartmentMaterials";
import PtoPageMaterials from "./pto_page_materials";

const Materials = () => {
    const manager=useStore((state)=>state.manager);
  //   console.log(manager);
    switch (String(manager.department)){
        case "1":
            return (
                <>
                    <PtoPageMaterials/>
                </>);

        case "8":
            return (
                <>
                    <PtoPageMaterials/>
                </>);

        case "2":
            return (
                <>

                </>);

        case "6":
           return (
                <>
                    <PtoPageMaterials/>
                </>);


 //        case "5":
 //            return (
 //                <>
 //                    <BuildingControlPageDocuments/>
 //                </>);
 //            break;
        case "7":return <SupplyDepartmentMaterials/>;
     }

};

export default Materials;