import React from 'react';
import cl from "./house1.3.module.css";

const House13 = () => {
    return (
        <div className={cl.mainDiv}>
                <div id={"section 1"} className={cl.section1}>1</div>
                <div id={"section 2"} className={cl.section2}>2</div>
                <div id={"section 3"} className={cl.section3}>3</div>
                <div id={"section 4"} className={cl.section4}>4</div>
                <div id={"section 5"} className={cl.section5}>5</div>
                <div id={"section 6"} className={cl.section6}>6</div>
                <div id={"section 7"} className={cl.section7}>7</div>
                <div id={"section 8"} className={cl.section8}>8</div>
                <div id={"section 9"} className={cl.section9}>9</div>
                <div id={"section 10"} className={cl.section10}>10</div>
                <div id={"section 11"} className={cl.section11}>11</div>
                <div id={"section 12"} className={cl.section12}>12</div>
                <div id={"section 13"} className={cl.section13}>13</div>
                <div id={"section 14"} className={cl.section14}>14</div>
                <div id={"section 15"} className={cl.section15}>15</div>
                <div id={"section 16"} className={cl.section16}>16</div>
                <div id={"section 17"} className={cl.section17}>17</div>
                <div id={"section 18"} className={cl.section18}>18</div>
                <div id={"section 19"} className={cl.section19}>19</div>
                <div id={"section 20"} className={cl.section20}>20</div>
                <div id={"section 21"} className={cl.section21}>21</div>
        </div>
    );
};

export default House13;