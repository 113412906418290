import {PDFDocument} from "pdf-lib";

export default class work_with_pdf{
    static async add_qr_to_pdf(QR_Image, size, file){
        const existingPdfBytes = await fetch(URL.createObjectURL(file)).then(res => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const jpgImage = await pdfDoc.embedPng(QR_Image)
        const pages = pdfDoc.getPages()
        let Based64pdf;
        pages.map(el=>{
            const { height } = el.getSize()
            el.drawImage(jpgImage, {
                x:0,
                y:height-size,
                width:50,
                height:50,
            })
        });


        Based64pdf=await pdfDoc.saveAsBase64();
        return Based64pdf;
    }
}

