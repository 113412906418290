import React from 'react';

const DocumentsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="#3EBBC4" viewBox="0 0 748 748" >
            <g>
                <rect x="136" y="612" width="238" height="34"/>
                <polygon points="612,612 612,646 680,646 680,0 170,0 170,68 204,68 204,34 646,34 646,612"/>
                <rect x="136" y="510" width="374" height="34"/>
                <path d="M68,109.14v631.72c0,3.943,3.128,7.14,7.004,7.14h495.958c3.91,0,7.038-3.162,7.038-7.14V109.14c0-3.944-3.128-7.14-7.004-7.14H75.004C71.128,102,68,105.162,68,109.14z M102,136h442v578H102V136z"/>
                <rect x="136" y="204" width="374" height="34"/>
                <rect x="136" y="306" width="374" height="34"/>
                <rect x="136" y="408" width="374" height="34"/>
            </g>
        </svg>
    );
};

export default DocumentsIcon;

