import React from 'react';

const WindowsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
             stroke="currentColor" strokeWidth="2" strokeLinecap="round"
             strokeLinejoin="round" fill="#3EBBC4" viewBox="0 0 512 512">
            <path
                d="M424,440V48H88V440H48v32H464V440ZM120,80H240V200H120Zm0,152H240V376H120ZM392,440H120V408H392Zm0-64H272V232H392Zm0-176H272V80H392Z"
            />
        </svg>

    );
};

export default WindowsIcon;

