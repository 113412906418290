import axios from "axios";
import server_constants from "./server_constants";
import global_functions from "../global_functions";
export default class prescription_server_requests {
    static url = server_constants.global_url+'prescription_api.php';
    static login = server_constants.login;
    static psw = server_constants.psw;

    static async get_contractor_sections(contractor_id, UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_contractor_sections',
                UserId:UserId,
                contractor_id:contractor_id,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async get_prescriptions(contractor_id=null, UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_prescriptions',
                contractor_id:contractor_id,
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async add_prescription(contractor_id, notes, date, UserId, description, fixDate, sections, file, house_num) {
        const formData=new FormData();
        formData.append('action','addPrescription');
        formData.append('contractor_id',contractor_id);
        formData.append('notes',notes);
        formData.append('date',global_functions.convertDateToBaseFormat(date));
        formData.append('description',description);
        formData.append('fixDate',global_functions.convertDateToBaseFormat(fixDate));
        formData.append('sections', sections)
        formData.append('house_num', house_num)
        formData.append('UserId', UserId)
        formData.append('file', file);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=axios.post(this.url, formData);
        return responce;
    }
}