import axios from "axios";
import server_constants from "./server_constants";
import global_functions from "../global_functions";

export default class AdministrationAPI {
    static url=this.checkUrl();
    static login = server_constants.login;
    static psw = server_constants.psw;

    static checkUrl(){
        let LocalUrl="http://127.0.0.1:8000/administration_api.php"
        let Url = server_constants.global_url+'administration_api.php';
        if(window.location.href.indexOf('localhost')!==-1) return LocalUrl;
        return Url;
    }

    static async getDepartments(UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getDepartments',
                UserId:UserId,
                login: this.login,
                password: this.psw
            }
        });
        return responce;
    }

    static async getAllSubPages(UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getAllSubPages',
                UserId:UserId,
                login: this.login,
                password: this.psw
            }
        });
        return responce;
    }
    static async getPages(UserId, pages=null) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getPages',
                pages:pages,
                UserId:UserId,
                login: this.login,
                password: this.psw
            }
        });
        return responce;
    }
    static async getUsers(AreaId, UserId){
        const responce = await axios.get(this.url, {
            params: {
                action: 'getUsers',
                UserId:UserId,
                AreaId:AreaId,
                login: this.login,
                password: this.psw
            }
        });
        return responce;
    }
    static async getUserRights(AreaId, ChangingUserId, UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getUserRights',
                UserId:UserId,
                ChangingUserId:ChangingUserId,
                AreaId:AreaId,
                login: this.login,
                password: this.psw
            }
        });
        return responce;
    }
    static async getContractorSections(UserId, ContractorId, HouseId, WorkId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getContractorSections',
                contractorId:ContractorId,
                houseId:HouseId,
                contractorWorkId:WorkId,
                UserId:UserId,
                login: this.login,
                password: this.psw
            }
        });
        return responce;
    }
    static async getContractorWorks(UserId) {
        const responce = await axios.get(this.url, {
            params: {
                action: 'getContractorWorks',
                UserId:UserId,
                login: this.login,
                password: this.psw
            }
        });
        return responce;
    }

    static async addUser(AreaId, UserFamilyName, UserName, UserSurName, userPhone, UserPosition, userLogin, userPassword, userDepartment, UserId) {
        const formData = new FormData();
        formData.append('action', 'addUser');
        formData.append('UserFamilyName', UserFamilyName);
        formData.append('UserSurName', UserSurName);
        formData.append('UserName', UserName);
        formData.append('UserLogin', userLogin);
        formData.append('UserPassword', userPassword);
        formData.append('UserDepartment', userDepartment);
        formData.append('UserPhone', userPhone);
        formData.append('UserPosition', UserPosition);
        formData.append('UserId', UserId);
        formData.append('AreaId', AreaId);
        formData.append('login', this.login);
        formData.append('password', this.psw);
        const responce = await axios.post(this.url, formData);
        return responce;
    }
    static async changeUserRights(AreaId, ChangingUserId, rights, UserId) {
        const formData = new FormData();
        formData.append('action', 'changeUserRights');
        formData.append('ChangingUserId', ChangingUserId);
        formData.append('UserId', UserId);
        formData.append('AreaId', AreaId);
        formData.append('rights', rights);
        formData.append('login', this.login);
        formData.append('password', this.psw);
        const responce = await axios.post(this.url, formData);
        return responce;
    }
    static async addArea(UserId, AreaName) {
        const formData = new FormData();
        formData.append('action', 'addArea');
        formData.append('AreaName', AreaName);
        formData.append('UserId', UserId);
        formData.append('login', this.login);
        formData.append('password', this.psw);
        const responce = await axios.post(this.url, formData);
        return responce;
    }
    static async addQueue(UserId, AreaId, queueName) {
        const formData = new FormData();
        formData.append('action', 'addQueue');
        formData.append('AreaId', AreaId);
        formData.append('queueName', queueName);
        formData.append('UserId', UserId);
        formData.append('login', this.login);
        formData.append('password', this.psw);
        const responce = await axios.post(this.url, formData);
        return responce;
    }
    static async AddHouse( AreaId, queueId, houseName, sectionsCount, UserId) {
        const formData = new FormData();
        formData.append('action', 'addHouse');
        formData.append('AreaId', AreaId);
        formData.append('queueId', queueId);
        formData.append('houseName', houseName);
        formData.append('sectionsCount', sectionsCount);
        formData.append('UserId', UserId);
        formData.append('login', this.login);
        formData.append('password', this.psw);
        const responce = await axios.post(this.url, formData);
        return responce;
    }
    static async editSections(houseId, sectionsData, UserId) {
        const formData = new FormData();
        formData.append('action', 'editSections');
        formData.append('houseId', houseId);
        formData.append('sectionsData', JSON.stringify(sectionsData));
        formData.append('UserId', UserId);
        formData.append('login', this.login);
        formData.append('password', this.psw);
        const responce = await axios.post(this.url, formData);
        return responce;

    }
    static async addContractor(contractorName, sectionsData, contractorWork, UserId) {
        const formData = new FormData();
        formData.append('action', 'addContractor');
        formData.append('contractorName', contractorName);
        formData.append('contractorWork', contractorWork);
        formData.append('sectionsData', JSON.stringify(sectionsData));
        formData.append('UserId', UserId);
        formData.append('login', this.login);
        formData.append('password', this.psw);
        const responce = await axios.post(this.url, formData);
        return responce;

    }
}