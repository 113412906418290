import React, {useEffect, useState} from 'react';
import cl from "./mapInfo.module.css"
import Select from "react-select";
import global_functions from "../../global_functions";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import mapServerRequests from "../../api/mapServerRequests";
import useStore from "../../Store";
const MapInfo = () => {
    const [HousesOption,setHousesOption]=useState([]);
    const [House,setHouse]=useState(null);
    const [AreasOption,setAreasOption]=useState([]);
    const [Area, setArea]=useState();
    const [QueuesOption,setQueuesOption]=useState([]);
    const [Queue, setQueue]=useState();
    const [isLoading, setIsLoading]=useState(false)
    const [sectionsData, setSectionsData]=useState(null);
    const [dataForLine, setDataForLine]=useState([]);
    const [datesForLine, setDatesForLine]=useState([]);
    const [percentFor4Floor, setPercentFor4Floor]=useState([])
    const [percentFor3Floor, setPercentFor3Floor]=useState([])
    const UserId=useStore((state)=>state.User).Id;

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    function makeGraphicsData(data){
        let sectionDateForLine=[];
        let dataForLine=[];
        let dates=new Set();
        let sectionId={sectionName:data[0].section_name, sectionId:data[0].section_id};

        data.map(element=>{
            dates.add(global_functions.convert_data(element.date));
        });

        setDatesForLine(Array.from(dates));

        let sectionDates=new Set();
         data.map((element, index)=>{
             let temp={}
             if(element.floors_count==4)
                 temp={
                     workId:element.map_work_id,
                     percent:element.percent,
                     per:(percentFor4Floor[Number(element.map_work_id)-1].increasedPercent+percentFor4Floor[Number(element.map_work_id)-1].percent*(element.percent/100))
                 }
             if(element.floors_count==3)
                 temp={
                     workId:element.map_work_id,
                     percent:element.percent,
                     per:(percentFor3Floor.filter(el=> el.workId==element.map_work_id)[0].increasedPercent+percentFor3Floor.filter(el=> el.workId==element.map_work_id)[0].percent*(element.percent/100))
                 }

            if(sectionId.sectionId==element.section_id){
                sectionDateForLine.push(Math.round(temp.per*100))
                sectionDates.add(global_functions.convert_data(element.date));
            }
            else {
                dataForLine.push({sectionName:sectionId.sectionName, data:sectionDateForLine, dates:Array.from(sectionDates)});
                sectionDateForLine=[];
                sectionDates.clear();
                sectionDateForLine.push(Math.round(temp.per*100))
                sectionDates.add(global_functions.convert_data(element.date));
            }
            if (index==data.length-1){
                dataForLine.push({sectionName:sectionId.sectionName, data:sectionDateForLine, dates:Array.from(sectionDates)});
            }
            sectionId={sectionName:element.section_name, sectionId:element.section_id};
        })
        return(dataForLine);
    }

    function mapWorksPercent(works){
        let tempFourFloor=[];
        let tempThreeFloor=[];
        let pre3=0;
        let pre4=0;
        works.map(element=>{
            tempFourFloor=[...tempFourFloor, {workId:element.map_work_id, percent:element.foth_floor_house, increasedPercent:pre4}]
            pre4=pre4+Number(element.foth_floor_house);
            if(element.third_floor_house!=null){
                tempThreeFloor=[...tempThreeFloor, {workId:element.map_work_id, percent:element.third_floor_house, increasedPercent:pre3}]
                pre3=pre3+Number(element.third_floor_house);
            } else {
                tempThreeFloor=[...tempThreeFloor, {workId:element.map_work_id, percent:0, increasedPercent:0}]
                pre3=pre3+Number(element.third_floor_house);
            }
        });
       setPercentFor4Floor(tempFourFloor)
       setPercentFor3Floor(tempThreeFloor)
    }
    function EditAreasOption(value){
        setArea(value);
        setQueue(null);
        setHouse(null);
    }
    function EditQueuesOption(value){
        setQueue(value);
        setHouse(null);
    }
    function EditHousesOption(value){
        setHouse(value);
        mapServerRequests.getHousePercent(value.value, UserId)
            .then(({data})=>{
                setSectionsData(data);
                console.log(data.filter(el=>el.section_id==99))
            })
    }

    useEffect(()=>{
        global_functions.GetArea(setAreasOption, setArea, UserId);
        setIsLoading(true)
        mapServerRequests.getMapWorks(UserId)
            .then(({data})=>
                mapWorksPercent(data)
            );
    },[])

    useEffect(()=>{
        if(Area)
            global_functions.GetQueues(Area.value, UserId)
                .then(res=>
                    setQueuesOption(res)
                );
        },[Area])
    useEffect(()=>{
        if(Area&&Queue)
            global_functions.GetHouses(Area.value, Queue.value, UserId)
                .then(res=>
                    setHousesOption(res)
                )
    },[Area, Queue]);

    useEffect(() => {
        if(sectionsData)
            setDataForLine(makeGraphicsData(sectionsData))
    }, [sectionsData]);

     console.log(dataForLine);

    return (
        <div>
            <div className={cl.container}>
                <Select
                    value={Area}
                    className={cl.area}
                    options={AreasOption}
                    onChange={EditAreasOption}
                />
                <Select
                    value={Queue}
                    className={cl.queue}
                    placeholder="Очередь"
                    options={QueuesOption}
                    onChange={EditQueuesOption}
                />
                <Select
                    value={House}
                    className={cl.house}
                    placeholder="Дом"
                    options={HousesOption}
                    onChange={EditHousesOption}
                />
            </div>
            <div>
                {
                    dataForLine[0]&&dataForLine.map(element=>{
                        let data= {
                            labels: element.dates,
                            datasets: [
                            {
                                data: element.data,
                                label: "Секция "+element.sectionName,
                                borderColor: "#3333ff",
                                lineTension: 0.1
                            }]
                        }
                      return(
                          <>
                              <Line
                                  type="line"
                                  width={160}
                                  height={20}
                                  data={data}
                              />
                              <br/><br/>
                          </>
                      );
                    })
                }
            </div>
        </div>
    );
};

export default MapInfo;