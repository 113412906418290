import React, {useEffect, useState} from 'react';
import executive_documentation_server_requests from "../../api/executive_documentation_requests";
import global_functions from "../../global_functions";
import Select from "react-select";
import cl from "./executiveDocumentationShow.module.css";
import useStore from "../../Store";

const ExecutiveDocumentationShow = () => {
    const [HousesOption,setHousesOption]=useState([]);
    const [House,setHouse]=useState(null);
    const [QueuesOption,setQueuesOption]=useState([]);
    const [Queue, setQueue]=useState();
    const [executive_docs, setExecutive_docs]=useState([]);
    const UserId=useStore((state)=>state.User).Id;
    const AreaId=useStore((state)=>state.AreaId)

    async function get_ExecutiveDocumentation(houseId, UserId){
        const res= await executive_documentation_server_requests.getExecutiveDocumentation(houseId, UserId);
        setExecutive_docs(res.data);
    }
    function show_doc(e){
        const file=e.currentTarget.id;
        e.stopPropagation();
        window.open(file);
    }

    function EditQueuesOption(value){
        setQueue(value);
        setHouse(null);
    }
    function EditHousesOption(value){
        setHouse(value);
    }

    useEffect(()=>{
        if(House)
            get_ExecutiveDocumentation(House.value, UserId)
    },[])

    useEffect(()=>{
        global_functions.GetQueues(AreaId, UserId)
            .then(res=>
                setQueuesOption(res));
    },[AreaId])
    useEffect(()=>{
        if(Queue)
            global_functions.GetHouses(AreaId, Queue.value, UserId)
                .then(res=>
                    setHousesOption(res)
                )
    },[AreaId, Queue]);


    return (
        <>
            <div className={cl.container}>
                <Select
                    value={Queue}
                    className={cl.select}
                    placeholder="Очередь"
                    options={QueuesOption}
                    onChange={EditQueuesOption}
                />
                <Select
                    value={House}
                    className={cl.select}
                    placeholder="Дом"
                    options={HousesOption}
                    onChange={EditHousesOption}
                />
            </div>
            {executive_docs.length>0?
                <table>
                    <th>Дом</th>
                    <th>Описание</th>
                    <th>Файл</th>
                    {
                        executive_docs[0]&&executive_docs.map(el => {
                            return (<tr>
                                <td>{el.house_num}</td>
                                <td>{el.executive_documentation_note}</td>
                                <td id={el.path + el.executive_documentation_file} onClick={show_doc}>download</td>
                            </tr>);
                        })
                    }
                </table>:""
            }
        </>
    );
};

export default ExecutiveDocumentationShow;