import React from 'react';
import {Route, Routes} from "react-router-dom";
import {web_routes} from './routes'
import Login from "./login";
import Main from "./main";
import useStore from "./Store";
const AppRouter = () => {
    const isAuth=useStore((state)=>state.auth);
    return (
        <Routes>
            {web_routes.map(route=>
                    <Route
                        key={route.path}
                        path={route.path}
                        element={route.component}
                        exact={route.exact}
                    />
                )
            }
            {isAuth?<Route path='*' element={<Main/>}/>:<Route path='*' element={<Login/>}/>}
        </Routes>
    );
};

export default AppRouter;