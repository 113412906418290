import React from 'react';

const WindowsShowIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
             stroke="currentColor" strokeWidth="2" strokeLinecap="round"
             strokeLinejoin="round" fill="#3EBBC4" viewBox="0 0 512 512">
            <path
                d="M424,440V48H88V440H48v32H464V440ZM120,80H240V200H120Zm0,152H240V376H120ZM392,440H120V408H392Zm0-64H272V232H392Zm0-176H272V80H392Z"
            />
            <path
                d="m505.42807,482.62415l-49.90229,-46.36552c-1.84823,-1.71724 -1.84823,-3.43448 -0.92412,-5.15172c10.16528,-14.59655 15.70998,-31.76897 15.70998,-50.65862c0,-52.37586 -45.28171,-94.44828 -101.65282,-94.44828s-101.65282,42.07242 -101.65282,94.44828s45.28171,94.44828 101.65282,94.44828c20.33056,0 38.81289,-5.15172 54.52287,-14.59655c1.84823,-0.85862 4.62058,-0.85862 5.5447,0.85862l49.90229,46.36552c3.69647,3.43448 9.24117,3.43448 12.93763,0l12.93763,-12.02069c4.62058,-4.2931 4.62058,-9.44483 0.92412,-12.87931l0.00001,-0.00001zm-135.84513,-42.07242c-36.04054,0 -64.68816,-26.61724 -64.68816,-60.10345s28.64761,-60.10345 64.68816,-60.10345s64.68816,26.61724 64.68816,60.10345s-28.64761,60.10345 -64.68816,60.10345z"
                fill="red" id="svg_2"/>
        </svg>
    );
};

export default WindowsShowIcon;