import React, {useEffect, useState} from 'react';
import projects_server_requests from "../api/projects_server_requests";
import Select from "react-select";
import cl from "./show_projects.module.css";
import global_functions from "../global_functions";
import useStore from "../Store";


const ShowProjects = () => {
    const [houses, setHouses]=useState([]);

    const [project_divisions,setProject_divisions]=useState([]);
    const [project_division,setProject_division]=useState(null);
    const [project_stage,setProject_stage]=useState(null);
    const [project_stage_options,setProject_stage_options]=useState([]);
    const [projects, setProjects]=useState([]);
    const AreaId=useStore((state)=>state.AreaId)
    const UserId=useStore((state)=>state.User).Id;
    const [house, setHouse]=useState();
    const [loading, setLoading]=useState(false);

    async function get_houses(area_id, UserId) {
        let temp=[];

        const res = await projects_server_requests.get_houses(area_id, "", UserId);
        res.data.map(el => {
            temp = [...temp, {value: "h"+el.house_id, label: el.house_num}];
        });

        const res_queue =await projects_server_requests.get_queues(area_id, UserId);
        res_queue.data.map(el => {
            temp = [...temp, {value: "q"+el.queue_id, label: el.queue_name}];
        });
        setHouses(temp);

    }

    async function get_projects(house_id, project_stage_id, project_division_id, UserId){
        const res=await projects_server_requests.get_projects(house_id, project_stage_id, project_division_id,UserId)
        console.log(res.data)
        setProjects(res.data);
        if(res.data.length!=0) setLoading(true);
    }
    async function get_project_stages(UserId) {
        let temp = [];
        const res = await projects_server_requests.get_project_stages(UserId);
        res.data.map(el => {
            temp = [...temp, {value: el.project_stage_id, label: el.project_stage_name}];
        });
        setProject_stage_options(temp);
    }

    async function get_project_divisions(UserId) {
        let temp=[];
        const res = await projects_server_requests.get_project_divisions(UserId);
        res.data.map(el => {
            temp = [...temp, {value: el.project_division_id, label: el.project_division_name}];
        });
        setProject_divisions(temp);
    }

    function edit_house_options(value){
        setHouse(value);
        setLoading(false);
        setProject_stage(null);
        setProject_division(null);
        get_projects(value.value, 'null', 'null', UserId);
    }
    function edit_project_division_option(value){
        if(value===null) value={value:'null'};
        setProject_division(value);
        if(project_stage===null)
            get_projects(house.value, 'null', value.value, UserId);
        else
            get_projects(house.value, project_stage.value, value.value, UserId);
        //setLoading(false);
    }
    function edit_project_stage_option(value){
        if(value===null) value={value:"null"}
        setProject_stage(value);
        if(project_division==null)
            get_projects(house.value, value.value, 'null', UserId);
        else
            get_projects(house.value, value.value, project_division.value, UserId);
    }

     useEffect(()=>{
         get_houses(AreaId, UserId)
     },[AreaId]);
    useEffect(()=>{
        get_project_divisions(UserId);
        get_project_stages(UserId);
    },[]);

    return (
        <>
            <div className={cl.container}>
                <Select className={cl.house} options={houses}
                        onChange={edit_house_options} placeholder="Дом"/>
                <Select
                    value={project_stage}
                    className={cl.stage}
                    placeholder="Стадия ПД"
                    options={project_stage_options}
                    onChange={edit_project_stage_option}
                    isClearable
                />
                <Select
                    value={project_division}
                    className={cl.division}
                    placeholder="Раздел"
                    options={project_divisions}
                    onChange={edit_project_division_option}
                    isClearable
                />
            </div>
            {loading?
                <table>
                    <th>Стадия</th>
                    <th>Раздел ПД</th>
                    <th>Дата обновления</th>
                    <th>Примечания</th>
                    <th>Файл</th>
                    {
                       projects[0]&&projects.map(el=>{
                           return (
                             <tr>
                                 <td>{el.project_stage_name}</td>
                                 <td>{el.project_division_name}</td>
                                 <td>{global_functions.convert_data(el.project_add_date)}</td>
                                 <td>{el.project_notes}</td>
                                 <td><a target="_blank" href={el.project_file_path+el.project_file}>файл</a></td>
                             </tr>
                           );
                       })
                    }
                </table>:"Результатов нет"
            }
        </>
    );
};

export default ShowProjects;