import axios from "axios";
import server_constants from "./server_constants";

export default class additional_agreements_sever_requests{
    static url = server_constants.global_url+'additional_agreements_api.php';
    static login = server_constants.login;
    static psw = server_constants.psw;

    static async get_additional_agreements(UserId){
        const responce = await axios.get(this.url, {
            params: {
                action: 'get_additional_agreements',
                UserId:UserId,
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }
    static async add_additional_agreements_notes(UserId, additional_agreement_id, notes){
       const formData=new FormData();
        formData.append('action','add_additional_agreements_notes');
        formData.append('additional_agreement_id',additional_agreement_id);
        formData.append('notes',notes);
        formData.append('UserId',UserId);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=await axios.post(this.url, formData);
        return responce;
    }
    static async additional_agreements_change_status1(UserId, additional_agreement_id, status){
        const formData=new FormData();
        formData.append('action','additional_agreements_change_status1');
        formData.append('additional_agreement_id',additional_agreement_id);
        formData.append('UserId',UserId);
        formData.append('status',status);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=await axios.post(this.url, formData);
        return responce;
    }
    static async additional_agreements_change_status2(UserId, additional_agreement_id, status){
        const formData=new FormData();
        formData.append('action','additional_agreements_change_status2');
        formData.append('additional_agreement_id',additional_agreement_id);
        formData.append('UserId',UserId);
        formData.append('status',status);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=await axios.post(this.url, formData);
        return responce;
    }
    static async additional_agreements_change_status_refuse1(UserId, additional_agreement_id, status){
        const formData=new FormData();
        formData.append('action','additional_agreements_change_status_refuse1');
        formData.append('additional_agreement_id',additional_agreement_id);
        formData.append('status',status);
        formData.append('UserId',UserId);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=await axios.post(this.url, formData);
        return responce;
    }
    static async additional_agreements_change_status_refuse2(UserId, additional_agreement_id, status){
        const formData=new FormData();
        formData.append('action','additional_agreements_change_status_refuse2');
        formData.append('additional_agreement_id',additional_agreement_id);
        formData.append('status',status);
        formData.append('UserId',UserId);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=await axios.post(this.url, formData);
        return responce;
    }
    static async additional_agreements_additional_agreements_basalaev_control(UserId, additional_agreement_id, status){
        const formData=new FormData();
        formData.append('action','additional_agreements_basalaev_control');
        formData.append('additional_agreement_id',additional_agreement_id);
        formData.append('UserId',UserId);
        formData.append('status',status);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=await axios.post(this.url, formData);
        return responce;
    }
}