import React, {useEffect, useState} from 'react';
import Select from "react-select";
import cl from "./add_material_name.module.css";
import materials_server_requests from "../api/materials_server_requests";
import InputMask from "react-input-mask";
import MyButton from "../UI/MyButton/MyButton";
import CreatableSelect from "react-select/creatable";

const AddMaterialName = () => {
    const [material_name, setMaterial_name]=useState("");
    const [material_measures_option,setMaterial_measures_option]=useState([]);
    const [material_types_option,setMaterial_types_option]=useState([]);
    const [material_measure, setMaterial_measure]=useState();
    const [material_type, setMaterial_type]=useState();

    async function get_material_measures() {
        const res = await materials_server_requests.get_material_measures();
        //   console.log(res);
        let temp=[];
        res.data.map(el=>{
            temp=[...temp, {value:el.material_measure_id, label:el.material_measure_name}];
        });
        setMaterial_measures_option(temp);
    }
    async function get_material_types() {
        const res = await materials_server_requests.get_material_types();
//        console.log(res);
        let temp=[];
        res.data.map(el=>{
            temp=[...temp, {value:el.material_type_id, label:el.material_type_name}];
        });
        setMaterial_types_option(temp);
    }
    function edit_material_measure_option(value){
        setMaterial_measure(value);
    }
    function edit_material_types_option(value){
        setMaterial_type(value);
    }
    async function create_measure(value){
        const res=await materials_server_requests.create_measure(value);
  //      console.log(res);
        get_material_measures();
        setMaterial_measure(material_types_option.filter(el=>el.label==value))
    }
    async function add_material_name(e){
        e.preventDefault();
   //     console.log(material_name);
        if(material_measure && material_type && material_name!=="") {
          const res = await materials_server_requests.add_material_name(material_name, material_measure.id, material_type.id);
          setMaterial_name("");
        }
        else alert("Не введены необходимые данные!!!");

    }
    useEffect(()=>{get_material_measures();},[])
    useEffect(()=>{get_material_types();},[])
    return (

            <form className={cl.maindiv} onSubmit={add_material_name}>
                <Select className={cl.mt_op} placeholder="Тип материала" options={material_types_option} onChange={edit_material_types_option}/>
                <InputMask
                    value={material_name}
                    onChange={e=>setMaterial_name(e.target.value)}
                    placeholder='Наименование материала'
                    className={cl.material_name}
                />
                <CreatableSelect onCreateOption={create_measure} value={material_measure} className={cl.options} placeholder="ед.изм." options={material_measures_option} onChange={edit_material_measure_option}/>
                <MyButton className={cl.button}>Добавить наименование</MyButton>
            </form>

    );
};

export default AddMaterialName;