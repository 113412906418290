import axios from "axios";
import server_constants from "./server_constants";
import useStore from "../Store";
export default class LettersServerRequests{
    static url = this.checkUrl();
    static login = server_constants.login;
    static psw = server_constants.psw;


    static checkUrl(){
        let LocalUrl="http://127.0.0.1:8000/letters_api.php"
        let Url = server_constants.global_url+'letters_api.php';
        if(window.location.href.indexOf('localhost')!==-1) return LocalUrl;
        return Url;
    }

    static async get_letters(letter_type, year=2023, UserId){
        const params={
            action: 'get_letters',
            letter_type:letter_type,
            year:year,
            UserId:UserId,
            login:this.login,
            password:this.psw
        };
        const responce = await axios.get(this.url, {
            params
        });
        return responce;
    }
    static async get_letter(letter_id, UserId){
        const params={
            action: 'get_letter',
            letter_id:letter_id,
            UserId:UserId,
            login:this.login,
            password:this.psw
        };
        const responce = await axios.get(this.url, {
            params
        });
        return responce;
    }
    static async get_letter_companies(UserId){
        const params={
            action: 'get_letter_companies',
            UserId:UserId,
            login:this.login,
            password:this.psw
        };
        const responce = await axios.get(this.url, {
            params
        });
        return responce;
    }
    static async send_letter_file_to_server(letter_type, letter_from, letter_to, letter_date, file, letter_notes, UserId){
        const formData=new FormData();
        formData.append('letter_type',letter_type);
        formData.append('letter_from',letter_from);
        formData.append('letter_to',letter_to);
        formData.append('letter_date',letter_date);
        formData.append('file', file);
        formData.append('notes',letter_notes);
        formData.append('UserId',UserId);
        formData.append('action','add_letter')
        formData.append('login', this.login);
        formData.append('password',this.psw);

        const responce=axios.post(this.url, formData);
        return responce;
    }
    static async edit_letter(letter_id, letter_notes, letter_another_number, file, UserId){
        const formData=new FormData();
        formData.append('letter_id',letter_id);
        formData.append('letter_another_number',letter_another_number);
        formData.append('notes',letter_notes);
        formData.append('file', file);
        formData.append('action','edit_letter')
        formData.append('UserId',UserId);
        formData.append('login', this.login);
        formData.append('password',this.psw);
        const responce=axios.post(this.url, formData);
        return responce;
    }
}